<template>
    <div
        :class="myriadCheckboxClass"
        @keydown.enter.space="checkboxClick"
    >
        <div class="myriad-checkbox-box" @click="checkboxClick">
            <check-green class="myriad-checkbox-check" />
        </div>
        <input
            :id="id"
            ref="myriadCheckbox"
            class="myriad-checkbox-input"
            type="checkbox"
            :disabled="disabled"
            :name="name"
            :checked="modelProperty"
            @change="checkboxClick"
        >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <label :for="id" class="myriad-checkbox-label" v-html="label" />
    </div>
</template>

<script>
import MyriadRadioCheckMixin from "../mixins/MyraidRadioCheckMixin.js";

export default {
    name: "MyriadCheckbox",
    mixins: [MyriadRadioCheckMixin],
    props: {
        modelProperty: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        checkboxSelected() {
            return this.modelProperty;
        }
    },
    methods: {
        checkboxClick() {
            if (!this.disabled) {
                this.$emit("input", !this.modelProperty);
                this.$emit("check-selection", !this.modelProperty);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../styles/MyriadRadioCheck.scss";
</style>
