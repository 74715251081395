export default {
    state: {
        csHours: "",
        csEmail: "",
        csFax: "",
        csPhone: "",
        miHours: "",
        miEmail: "",
        miPhone: ""
    },
    getters: {
        getCsPhone: state => {
            return state.csPhone;
        },
        getCsEmail: state => {
            return state.csEmail;
        },
        getCsHours: state => {
            return state.csHours;
        },
        getCsFax: state => {
            return state.csFax;
        },
        getMiHours: state => {
            return state.miHours;
        },
        getMiEmail: state => {
            return state.miEmail;
        },
        getMiPhone: state => {
            return state.miPhone;
        }
    },
    mutations: {
        setContactInfo(state, data) {
            const csData = data.data.customerService;
            const miData = data.data.medInfo;

            state.csHours = csData.hours;
            state.csEmail = csData.email;
            state.csFax = csData.fax;
            state.csPhone = csData.phone;

            state.miHours = miData.hours;
            state.miEmail = miData.email;
            state.miPhone = miData.phone;
        }
    },
    actions: {
        setContactInfo(context, data) {
            context.commit("setContactInfo", data);
        }
    }
};
