export default {
    state: {
        showFullScreenLoader: false,
        loaderTimeout: null,
        testTitle: "GeneSight Psychotropic",
        defaultTestTitle: "GeneSight Psychotropic",
        showContact: true,
        showHome: false
    },
    getters: {
        getShowFullScreenLoader: state => {
            return state.showFullScreenLoader;
        },
        getTestTitle: state => {
            return state.testTitle;
        },
        getDefaultTestTitle: state => {
            return state.defaultTestTitle;
        },
        getShowContact: state => {
            return state.showContact;
        },
        getShowHome: state => {
            return state.showHome;
        }
    },
    mutations: {
        showFullScreenLoader(state) {
            state.loaderTimeout = window.setTimeout(() => {
                state.showFullScreenLoader = true;
            }, 500);
        },
        showFullScreenLoaderImmediately(state) {
            state.showFullScreenLoader = true;
        },
        hideFullScreenLoader(state) {
            clearTimeout(state.loaderTimeout);
            state.showFullScreenLoader = false;
        },
        setTestTitle(state, value) {
            state.testTitle = value;
        },
        setShowContact(state, value) {
            state.showContact = value;
        },
        setShowHome(state, value) {
            state.showHome = value;
        }
    },
    actions: {
        showFullScreenLoader(context) {
            context.commit("showFullScreenLoader");
        },
        showFullScreenLoaderImmediately(context) {
            context.commit("showFullScreenLoaderImmediately");
        },
        hideFullScreenLoader(context) {
            context.commit("hideFullScreenLoader");
        },
        setTestTitle(context, value) {
            context.commit("setTestTitle", value);
        },
        resetTestTitle(context) {
            context.commit("setTestTitle", context.getters.getDefaultTestTitle);
        },
        setShowContact(context, value) {
            context.commit("setShowContact", value);
        },
        setShowHome(context, value) {
            context.commit("setShowHome", value);
        }
    }
};
