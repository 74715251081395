import { render, staticRenderFns } from "./AuthorizeOrder.vue?vue&type=template&id=48d023c2&scoped=true&"
import script from "./AuthorizeOrder.vue?vue&type=script&lang=js&"
export * from "./AuthorizeOrder.vue?vue&type=script&lang=js&"
import style0 from "./AuthorizeOrder.vue?vue&type=style&index=0&id=48d023c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.registry.npmjs.org/vue-loader/15.9.7_css-loader@3.6.0+webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d023c2",
  null
  
)

export default component.exports