<template>
    <div class="view-report">
        View Report Page
    </div>
</template>

<script>
export default {
    name: "ViewReport"
};
</script>

<style lang="scss" scoped>
    .view-report {
        background: lightblue;
        width: 100%;
        height: 100%;
    }
</style>
