export default {
    state: {
        DefaultDiagnosticCodesDepression: [],
        DefaultDiagnosticCodesAnxiety: [],
        DefaultDiagnosticCodesADHD: [],
        DefaultDiagnosticCodesBipolar: [],
        DefaultDiagnosticCodesOCD: [],
        DefaultDiagnosticCodesPTSD: [],
        DefaultDiagnosticCodesSchizophrenia: [],
        DefaultDiagnosticCodesAll: [],
        UhcDiagnosticCodesDepression: [],
        UhcDiagnosticCodesAnxiety: [],
        UhcDiagnosticCodesBipolar: [],
        UhcDiagnosticCodesOCD: [],
        UhcDiagnosticCodesPTSD: [],
        UhcDiagnosticCodesSchizophrenia: [],
        UhcDiagnosticCodesAll: []
    },
    getters: {
        getDefaultDiagnosticCodesAll: state => {
            return state.DefaultDiagnosticCodesAll;
        },
        getDefaultDiagnosticCodesDepression: state => {
            return state.DefaultDiagnosticCodesDepression;
        },
        getDefaultDiagnosticCodesAnxiety: state => {
            return state.DefaultDiagnosticCodesAnxiety;
        },
        getDefaultDiagnosticCodesADHD: state => {
            return state.DefaultDiagnosticCodesADHD;
        },
        getDefaultDiagnosticCodesBipolar: state => {
            return state.DefaultDiagnosticCodesBipolar;
        },
        getDefaultDiagnosticCodesOCD: state => {
            return state.DefaultDiagnosticCodesOCD;
        },
        getDefaultDiagnosticCodesPTSD: state => {
            return state.DefaultDiagnosticCodesPTSD;
        },
        getDefaultDiagnosticCodesSchizophrenia: state => {
            return state.DefaultDiagnosticCodesSchizophrenia;
        },
        getUhcDiagnosticCodesAll: state => {
            return state.UhcDiagnosticCodesAll;
        },
        getUhcDiagnosticCodesDepression: state => {
            return state.UhcDiagnosticCodesDepression;
        },
        getUhcDiagnosticCodesAnxiety: state => {
            return state.UhcDiagnosticCodesAnxiety;
        },
        getUhcDiagnosticCodesBipolar: state => {
            return state.UhcDiagnosticCodesBipolar;
        },
        getUhcDiagnosticCodesOCD: state => {
            return state.UhcDiagnosticCodesOCD;
        },
        getUhcDiagnosticCodesPTSD: state => {
            return state.UhcDiagnosticCodesPTSD;
        },
        getUhcDiagnosticCodesSchizophrenia: state => {
            return state.UhcDiagnosticCodesSchizophrenia;
        }
    },
    mutations: {
        setDefaultDiagnosticCodes(state, codes) {
            let convertCodes = codes.depressionCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.DefaultDiagnosticCodesDepression = convertCodes;

            convertCodes = codes.anxietyCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.DefaultDiagnosticCodesAnxiety = convertCodes;

            convertCodes = codes.adhdCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.DefaultDiagnosticCodesADHD = convertCodes;

            convertCodes = codes.bipolarCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.DefaultDiagnosticCodesBipolar = convertCodes;

            convertCodes = codes.ocdCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.DefaultDiagnosticCodesOCD = convertCodes;

            convertCodes = codes.ptsdCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.DefaultDiagnosticCodesPTSD = convertCodes;

            convertCodes = codes.schizophreniaCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.DefaultDiagnosticCodesSchizophrenia = convertCodes;
            state.DefaultDiagnosticCodesAll = codes;
        },
        setUhcDiagnosticCodes(state, codes) {
            let convertCodes = codes.anxietyCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.UhcDiagnosticCodesAnxiety = convertCodes;

            convertCodes = codes.bipolarCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.UhcDiagnosticCodesBipolar = convertCodes;

            convertCodes = codes.depressionCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.UhcDiagnosticCodesDepression = convertCodes;

            convertCodes = codes.ocdCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.UhcDiagnosticCodesOCD = convertCodes;

            convertCodes = codes.ptsdCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.UhcDiagnosticCodesPTSD = convertCodes;

            convertCodes = codes.schizophreniaCodes.map((currentCode) => {
                currentCode.value = currentCode.diagnosticCodeValue ? currentCode.diagnosticCodeValue : currentCode.value;
                return currentCode;
            });
            state.UhcDiagnosticCodesSchizophrenia = convertCodes;
            state.UhcDiagnosticCodesAll = codes;
        }
    },
    actions: {
        setDefaultDiagnosticCodes(context, codes) {
            context.commit("setDefaultDiagnosticCodes", codes);
        },
        setUhcDiagnosticCodes(context, codes) {
            context.commit("setUhcDiagnosticCodes", codes);
        }
    }
};
