<template>
    <div class="auth-2020-questions">
        <psychotropic />
        <mthfr />
    </div>
</template>

<script>
import Psychotropic from "./Psychotropic.vue";
import mthfr from "./Mthfr.vue";

export default {
    name: "Auth2020Questions",
    components: {
        Psychotropic,
        mthfr
    }
};
</script>

<style lang="scss" scoped>
</style>
