import { HTTP } from "./http";

const AgreementHttp = {
    GetAgreementData: (data) => {
        const url = config.url + "/GetAgreement";
        return HTTP.post(url, data);
    },
    PostAgreementData: (data) => {
        const url = config.url + "/Agreement";
        return HTTP.post(url, data);
    }
};

export default AgreementHttp;
