<template>
    <div class="mthfr other-test-section">
        <transition-group name="mfade" mode="out-in">
            <div v-show="!$store.getters.getMtfhrSelected" key="mthfr-closed" class="other-tests-closed" @click="mthfrAdded">
                <img class="other-test-plus" src="../../../assets/blue_circle_plus.png">
                <p class="other-test-closed-text">
                    ADD MTHFR
                </p>
                <div class="other-test-chev">
                    <img class="other-test-chev-img" src="../../../assets/gray_down_triangle.png">
                </div>
            </div>
            <div v-show="$store.getters.getMtfhrSelected" key="mthfr-open" class="other-test-open" @click="mthfrRemoved">
                <div class="mthfr-open-text">
                    <p>
                        MTHFR added
                    </p>
                    <p>
                        <b>
                            Select a medical necessity below
                        </b>
                    </p>
                </div>
                <div class="other-test-chev">
                    <img class="other-test-chev-img" src="../../../assets/gray_down_triangle.png">
                </div>
            </div>
        </transition-group>
        <transition name="mfade">
            <div v-if="$store.getters.getMtfhrSelected" class="mthfr-med-necessity-section">
                <div class="mthfr-diag-codes">
                    <diagnostic-codes
                        id="mthfr-diag-codes"
                        key="mthfr-diag-codes"
                        :is-mthfr="true"
                    />
                </div>
                <div>
                    <p class="mthfr-med-necessity-question">
                        <b>Medical Necessity</b> (select all that apply)
                    </p>
                    <error-wrapper
                        :error-exists="!isMedicalNecessitySelected"
                        error-message="Please provide a response."
                        :is-dirty="medicalNecessityIsDirty"
                        :warn-at-start="true"
                    >
                        <myriad-check
                            id="folateSupplement"
                            ref="folateSupplement"
                            v-model="folateSupplment"
                            name="mthfrMedNecessity"
                            label="I am considering folate supplementation for patient."
                            class="section-checkbox"
                            :model-property="folateSupplment"
                            @check-selection="medicalNecessityIsDirty = true"
                        />
                        <myriad-check
                            id="folateLevels"
                            ref="folateLevels"
                            v-model="lowSerumLevels"
                            name="mthfrMedNecessity"
                            label="Patient has low serum folate levels."
                            class="section-checkbox"
                            :model-property="lowSerumLevels"
                            @check-selection="medicalNecessityIsDirty = true"
                        />
                        <myriad-check
                            id="other"
                            ref="other"
                            v-model="other"
                            name="mthfrMedNecessity"
                            label="Other"
                            class="section-checkbox"
                            :model-property="other"
                            @check-selection="medicalNecessityIsDirty = true"
                        />
                    </error-wrapper>
                </div>
                <div v-if="other">
                    <error-wrapper
                        :error-exists="otherResponse === '' && other"
                        error-message="Please provide a reason for ordering."
                        :is-dirty="true"
                    >
                        <div class="other-input-container">
                            <input v-model="otherResponse" class="other-input" type="text">
                        </div>
                    </error-wrapper>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import DiagnosticCodes from "./DiagnosticCodes.vue";
import MyriadCheck from "../../MyriadCheck.vue";
import ErrorWrapper from "../ErrorWrapper.vue";

import Common from "../../../scripts/common.js";

export default {
    name: "Mthfr",
    components: {
        DiagnosticCodes,
        MyriadCheck,
        ErrorWrapper
    },
    data() {
        return {
            medicalNecessityIsDirty: false,
            setMthfrDefaultCodes: true
        };
    },
    computed: {
        getIsMthfrAdded() {
            return this.$store.getters.getMtfhrSelected;
        },
        isMedicalNecessitySelected() {
            return this.folateSupplment || this.lowSerumLevels || this.other;
        },
        folateSupplment: {
            get() {
                return this.$store.getters.getMthfrFolateSupplement;
            },
            set(value) {
                this.$store.dispatch("setMthfrFolateSupplement", value);
            }
        },
        lowSerumLevels: {
            get() {
                return this.$store.getters.getMthfrLowSerumLevels;
            },
            set(value) {
                this.$store.dispatch("setMthfrLowSerumLevels", value);
            }
        },
        other: {
            get() {
                return this.$store.getters.getMthfrOther;
            },
            set(value) {
                this.$store.dispatch("setMthfrOther", value);
            }
        },
        otherResponse: {
            get() {
                return this.$store.getters.getMthfrOtherResponse;
            },
            set(value) {
                this.$store.dispatch("setMthfrOtherResponse", value);
            }
        }
    },
    watch: {
        isMedicalNecessitySelected(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.toggleMthfrError(newValue);
            }
        },
        other(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.toggleMthfrOtherError(newValue && this.otherResponse !== "");
            }
        },
        otherResponse(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.toggleMthfrOtherError(newValue !== "" && this.other);
            }
        },
        getIsMthfrAdded(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    this.toggleMthfrError(this.isMedicalNecessitySelected);
                } else {
                    this.toggleMthfrError(true);
                }
            }
        }
    },
    methods: {
        toggleMthfrError(value) {
            if (value) {
                this.$store.dispatch("removeMthfrError", this.$store.getters.mthfrMedNecessityErrorString);
            } else {
                this.$store.dispatch("addMthfrError", this.$store.getters.mthfrMedNecessityErrorString);
            }
        },
        toggleMthfrOtherError(value) {
            if (value) {
                this.$store.dispatch("removeMthfrError", this.$store.getters.getmthfrOtherErrorString);
            } else {
                this.$store.dispatch("addMthfrError", this.$store.getters.getmthfrOtherErrorString);
            }
        },
        mthfrAdded() {
            if (this.setMthfrDefaultCodes) {
                this.setDefaultDiagnosticCodes();
                this.setMthfrDefaultCodes = false;
            }

            this.$store.dispatch("setMtfhrSelected", true);
        },
        mthfrRemoved() {
            this.$store.dispatch("removeMthfrError", this.$store.getters.mthfrMedNecessityErrorString);
            this.$store.dispatch("setMtfhrSelected", false);
        },
        setDefaultDiagnosticCodes() {
            if (Common.isEmptyOrNull(this.$store.getters.getMthfrDiagCode.diagnosticCodeValue)) {
                this.$store.dispatch("setMthfrDiagCode", this.$store.getters.getDiagCode);
            }
            if (Common.isEmptyOrNull(this.$store.getters.getMthfrSecondaryDiagCode1.diagnosticCodeValue)) {
                this.$store.dispatch("setMthfrSecondaryDiagCode1", this.$store.getters.getSecondaryDiagCode1);
            }
            if (Common.isEmptyOrNull(this.$store.getters.getMthfrSecondaryDiagCode2.diagnosticCodeValue)) {
                this.$store.dispatch("setMthfrSecondaryDiagCode2", this.$store.getters.getSecondaryDiagCode2);
            }
            if (Common.isEmptyOrNull(this.$store.getters.getMthfrSecondaryDiagCode3.diagnosticCodeValue)) {
                this.$store.dispatch("setMthfrSecondaryDiagCode3", this.$store.getters.getSecondaryDiagCode3);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .mthfr-open-text {
        text-align: center;
    }

    ::v-deep .mthfr-diag-codes {

        .section-gray-text {
            padding: 0 $other-test-padding;
        }

        .diagnostic-code-input-row {
            padding: 0 $other-test-padding;
        }

        .diagnostic-code-warn-header {
            margin-top: 5px;
        }
    }

    .mthfr-med-necessity-section {
        padding-bottom: 20px;
    }

    .mthfr-med-necessity-question {
        margin-top: 50px;
        padding-left: $other-test-padding;
        color: $light-gray-text;
    }

    .section-checkbox {
        padding: $other-test-padding 0 0 $other-test-padding;
    }

    .other-input-container {
        width: 100%;
        padding-left: $other-test-padding;
        padding-right: $other-test-padding;
        margin-top: 10px;
    }

    .other-input {
        width: 100%;
        height: 25px;
        font-size: 1em;
    }
</style>
