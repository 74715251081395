<template>
    <div class="auth-2019-questions">
        <clinican-auth-container
            v-model="diagnosisAuthModel"
            :auth-enabled="$store.getters.getDiagnosisErrors <= 0"
        >
            <div class="auth-section-blue-topper">
                If necessary, <b>edit the responses below</b> to ensure their accuracy.
            </div>
            <div class="authorize-section">
                <diagnosis />
                <diagnostic-codes id="psych-diag-codes" key="psych-diag-codes" />
            </div>
        </clinican-auth-container>
        <clinican-auth-container
            v-model="medicalNecessityAuthModel"
            :auth-enabled="$store.getters.getMedicalNecessityErrors.length <= 0"
        >
            <div class="auth-section-blue-topper">
                If necessary, <b>edit the responses below</b> to ensure their accuracy.
            </div>
            <div class="authorize-section">
                <medical-necessity />
            </div>
        </clinican-auth-container>
        <mthfr />
    </div>
</template>

<script>
import ClinicanAuthContainer from "./ClinicianAuthContainer.vue";
import Diagnosis from "./Diagnosis.vue";
import DiagnosticCodes from "./DiagnosticCodes.vue";
import MedicalNecessity from "./MedicalNecessity.vue";
import Mthfr from "./Mthfr.vue";

export default {
    name: "Auth2019Questions",
    components: {
        ClinicanAuthContainer,
        Diagnosis,
        DiagnosticCodes,
        MedicalNecessity,
        Mthfr
    },
    computed: {
        medicalNecessityAuthModel: {
            get() {
                return this.$store.getters.getMedicalNecessityApproved;
            },
            set(value) {
                this.$store.dispatch("setMedicalNecessityApproved", value);
            }
        },
        diagnosisAuthModel: {
            get() {
                return this.$store.getters.getDiagnosisApproved;
            },
            set(value) {
                this.$store.dispatch("setDiagnosisApproved", value);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
