export default {
    state: {
        failedMedsMultiselect: null,
        augmentationMultiselect: null,
        doseageMultiselect: null,
        disableDoseageAdustment: false
    },
    getters: {
        getFailedMedsMultiselect(state) {
            return state.failedMedsMultiselect;
        },
        getDoseageMultiselect(state) {
            return state.doseageMultiselect;
        },
        getAugmentationMultiselect(state) {
            return state.augmentationMultiselect;
        },
        getDisableDoseageAdjustment(state) {
            return state.disableDoseageAdustment;
        }
    },
    mutations: {
        setFailedMedsMultiselect(state, object) {
            state.failedMedsMultiselect = object;
        },
        setDoseageMultiselect(state, object) {
            state.doseageMultiselect = object;
        },
        setAugmentationMultiselect(state, object) {
            state.augmentationMultiselect = object;
        },
        setDisableDoseageAdjustment(state, value) {
            state.disableDoseageAdustment = value;
        }
    },
    actions: {
        setFailedMedsMultiselect(context, object) {
            context.commit("setFailedMedsMultiselect", object);
        },
        setAugmentationMultiselect(context, object) {
            context.commit("setAugmentationMultiselect", object);
        },
        setDoseageMultiselect(context, object) {
            context.commit("setDoseageMultiselect", object);
        },
        setDisableDoseageAdjustment(context, value) {
            context.commit("setDisableDoseageAdjustment", value);
        }
    }
};
