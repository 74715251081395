export default {
    state: {
        showSearchResults: false,
        searchResultsData: [],
        showSearchClear: false
    },
    getters: {
        getShowSearchResults: state => {
            return state.showSearchResults;
        },
        getSearchResultsData: state => {
            return state.searchResultsData;
        },
        getShowSearchClear: state => {
            return state.showSearchClear;
        }
    },
    mutations: {
        setShowSearchResults(state, value) {
            state.showSearchResults = value;
        },
        setSearchResultsData(state, values) {
            state.searchResultsData = values;
        },
        setShowSearchClear(state, value) {
            state.showSearchClear = value;
        }
    },
    actions: {
        setShowSearchResults(context, value) {
            context.commit("setShowSearchResults", value);
        },
        setSearchResultsData(context, values) {
            context.commit("setSearchResultsData", values);
        },
        setShowSearchClear(context, value) {
            context.commit("setShowSearchClear", value);
        }
    }
};
