<template>
    <div class="reset-link-component">
        <div class="reset-link">
            <div class="reset-link-row">
                <img class="reset-link-img" src="../../assets/circle_check.png">
                <h3 class="reset-link-header">
                    Reset Link Sent
                </h3>
            </div>
            <p class="reset-link-pw-content">
                An email has been sent to you containing a link to reset your password.
            </p>
            <neuro-button class="reset-link-btn" text="Sign In" :filled="true" @neuro-btn-click="goToSignIn" />
        </div>
    </div>
</template>

<script>
import NeuroButton from "../NeuroButton.vue";

export default {
    name: "ResetLinkComponent",
    components: {
        NeuroButton
    },
    methods: {
        goToSignIn() {
            this.$emit("sign-in-click");
        }
    }
};
</script>

<style lang="scss" scoped>
.reset-link-component {
    width: 100%;
    padding-top: 25px;
}

.reset-link {
    margin: 0 auto 20px;
    width: $sign-on-box-width;
}

.reset-link-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reset-link-header {
    margin-left: 10px;
    color: $genesight-green;
}

.reset-link-pw-content {
    margin-top: 30px;
    margin-bottom: 42px;
    font-size: $regular-copy-size;
    line-height: 1.66667em;
}

.reset-link-btn {
    width: 115px;
    margin: 0 auto;
}
</style>
