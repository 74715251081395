<template>
    <div class="home-page">
        <div class="home-page-top">
            <div class="search-bar">
                <img class="search-bar-mag-glass" src="../assets/mag_glass.png" @click="showSearchResults">
                <input ref="searchBarInput" type="text" class="search-bar-input" placeholder="First or last name, DOB, or order #" @input="searchBarInput" @keyup.enter="showSearchResults">
                <img v-if="$store.getters.getShowSearchClear" class="search-bar-clear" src="../assets/input_clear.png" @click="clearSearchBar">
            </div>
            <transition name="fade">
                <search-results v-if="$store.getters.getShowSearchResults"
                                :search-results-data="$store.getters.getSearchResultsData"
                />
            </transition>
            <neuro-button
                v-if="$store.getters.getCanOrderTests"
                class="new-order-btn"
                text="New Order"
                :filled="true"
                color="green"
                image-name="plus.png"
                @neuro-btn-click="$router.push('/NewOrder')"
            />
            <div v-if="$store.getters.getIsClinician" class="auth-order">
                <neuro-button
                    class="auth-order-btn"
                    text="Authorize Orders"
                    :filled="true"
                    color="orange"
                    image-name="edit.png"
                    @neuro-btn-click="$router.push('/AuthorizeOrders')"
                />
                <div v-if="$store.getters.getNumberOfOrdersToAuthorize > 0" class="auth-order-num">
                    {{ $store.getters.getNumberOfOrdersToAuthorize }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NeuroButton from "../components/NeuroButton.vue";
import SearchResults from "../components/SearchResults.vue";
import HomeHttp from "../scripts/http/home-http.js";
import AuthorizationHttp from "../scripts/http/authorization-http.js";

export default {
    name: "Home",
    components: {
        NeuroButton,
        SearchResults
    },
    created() {
        this.getOrdersToAuthorize();
    },
    methods: {
        searchBarInput() {
            this.$store.dispatch("setShowSearchClear", this.$refs.searchBarInput.value !== "");
        },
        clearSearchBar() {
            this.$refs.searchBarInput.value = "";
            this.$store.dispatch("setShowSearchResults", false);
            this.$store.dispatch("setShowSearchClear", false);
        },
        showSearchResults() {
            this.$store.dispatch("setShowSearchClear", true);

            var searchResultsData = {
                page: 1,
                rows: 25,
                sidx: "Created",
                sord: "Desc",
                search: this.$refs.searchBarInput.value,
                cancelledStatus: false,
                pendingStatus: false,
                resultsStatus: false,
                incompleteStatus: false,
                pendingCorrectionStatus: false,
                startDate: null,
                endDate: null,
                clinicId: null
            };

            this.$store.dispatch("showFullScreenLoader");
            HomeHttp.getSearchResults(searchResultsData).then((data) => {
                this.$store.dispatch("setSearchResultsData", data.data.rows);
                this.$store.dispatch("setShowSearchResults", true);
                this.$refs.searchBarInput.blur();
            }).catch(() => {
                this.$store.dispatch("showDefaultNotificationRibbon");
            }).finally(() => {
                this.$store.dispatch("hideFullScreenLoader");
            });
        },
        getOrdersToAuthorize() {
            this.$store.dispatch("showFullScreenLoaderImmediately");
            AuthorizationHttp.GetListOfOrdersToAuthorize().then((response) => {
                this.$store.dispatch("setOrdersToAuthorize", response.data);
            }).catch(() => {
                this.$store.dispatch("showDefaultNotificationRibbon");
            }).finally(() => {
                this.$store.dispatch("hideFullScreenLoader");
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .home-page {
        width: 100%;
        min-height: 50vh;
    }

    .home-page-top {
        padding: 20px 20px;
    }

    .search-bar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding: 7px 5px;
        border: 1px black solid;
    }

    .search-bar-input {
        margin-left: 5px;
        flex: 1;
        border: none;

        &:focus {
            outline: none;
        }
    }

    .search-bar-clear {
        position: absolute;
        right: 6px;
    }

    .new-order-btn {
        width: 100%;
        height: 40px;
        margin-top: 30px;
    }

    .auth-order {
        width: 100%;
        position: relative;
        margin-top: 30px;
    }

    .auth-order-btn {
        width: 100%;
        height: 40px;

        &:hover {
            cursor: pointer;
        }
    }

    .auth-order-num {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 25px;
        height: 25px;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        background: #EF4034;
        border-radius: 50%;
        color: white;
    }

    .fade-enter-active, .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
        max-height: 1000px;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
        max-height: 0;
    }

</style>
