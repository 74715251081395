import Common from "../../scripts/common.js";

export default {
    state: {
        fonts: []
    },
    getters: {
        getFonts: state => {
            return state.fonts;
        },
        getFirstFont: state => {
            return state.fonts[0];
        }
    },
    mutations: {
        setFonts(state, fonts) {
            state.fonts = fonts;
        }
    },
    actions: {
        setFonts(context, fonts) {
            for (var i = 0; i < fonts.length; i++) {
                var originalFontName = fonts[i].fontName;
                fonts[i].fontName = Common.getGoogleFontName(originalFontName);
            }
            context.commit("setFonts", fonts);
        }
    }
};
