<template>
    <div class="navigation">
        <div class="navbar">
            <button class="hamburger-menu" @click="hamburgerButtonClick()">
                <div ref="hamburgerImage" class="hamburger-image" :class="{'hamburger-image-close' : showSideBar }">
                    <div class="hamburger-bar hamburger-bar-1" />
                    <div class="hamburger-bar hamburger-bar-2" />
                    <div class="hamburger-bar hamburger-bar-3" />
                </div>
                <transition name="switch" mode="out-in">
                    <p v-if="!showSideBar" key="menu" class="hamburger-text">
                        Menu
                    </p>
                    <p v-else key="close" class="hamburger-text">
                        Close
                    </p>
                </transition>
            </button>
            <genesight-logo class="nav-logo" />
            <router-link to="/Contact" class="mobile-link nav-contact">
                Contact
            </router-link>
        </div>
        <transition name="slide-right" mode="out-in">
            <div v-if="showSideBar" class="nav-sidebar">
                <div class="sidebar-list">
                    <router-link to="/" class="mobile-link sidebar-link">
                        <span class="mobile-link" @click="homeButtonClick()">
                            Home
                        </span>
                    </router-link>
                    <router-link v-if="$store.getters.getCanOrderTests" class="sidebar-link mobile-link" to="/NewOrder">
                        <span class="mobile-link" @click="hamburgerButtonClick()">
                            New Order
                        </span>
                    </router-link>
                    <router-link v-if="$store.getters.getIsClinician" class="sidebar-link mobile-link" to="/AuthorizeOrders">
                        <span class="mobile-link" @click="hamburgerButtonClick()">
                            Authorize Orders{{ numOrdersToAuth }}
                        </span>
                    </router-link>
                    <router-link class="sidebar-link mobile-link" to="/Contact">
                        <span class="mobile-link" @click="hamburgerButtonClick()">
                            Contact
                        </span>
                    </router-link>
                    <router-link class="sidebar-link mobile-link" to="/LogOn">
                        <span class="mobile-link" @click="hamburgerButtonClick()">
                            Sign Out
                        </span>
                    </router-link>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="showSideBar" class="sidebar-gray" @click="showSideBar=false" />
        </transition>
    </div>
</template>

<script>
import GenesightLogo from "../assets/genesight.svg";
import Common from "../scripts/common.js";

export default {
    name: "Nav",
    components: {
        GenesightLogo
    },
    data() {
        return {
            showSideBar: false
        };
    },
    computed: {
        numOrdersToAuth() {
            return Common.countAsStringInParentheses(this.$store.getters.getNumberOfOrdersToAuthorize);
        }
    },
    methods: {
        homeButtonClick() {
            this.$store.dispatch("setShowSearchResults", false);
            this.hamburgerButtonClick();
        },
        hamburgerButtonClick() {
            this.showSideBar = !this.showSideBar;
        }
    }
};
</script>

<style lang="scss" scoped>
.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $navbar-height;
    padding: 0px 5px 0px 5px;
    border-bottom: 1px solid $border-grey;
}

.hamburger-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: $nav-side-item-width;
    height: $navbar-height;
    color: $genesight-blue;
    background: none;
    border: none;
    padding: 0;
    outline: none;
}

.hamburger-image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 23px;
    height: 14px;
}

.hamburger-text {
    margin-left: 5px;
    color: $genesight-blue;
}

.hamburger-bar {
    width: 100%;
    height: 3px;
    position: relative;
    background: $genesight-blue;
    transition: all 0.3s ease-in-out;
}

.hamburger-image-close .hamburger-bar-1 {
    transform: rotate(45deg);
    top: 6px;
}

.hamburger-image-close .hamburger-bar-2 {
    opacity: 0;
}

.hamburger-image-close .hamburger-bar-3 {
    transform: rotate(-45deg);
    bottom: 5px;
}

.nav-logo {
    width: 70px;
    height: auto;
}

.nav-contact {
    width: $nav-side-item-width;
    text-align: right;
}

.nav-sidebar {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 0;
    min-height: calc(100vh - #{$navbar-height});
    z-index: 20;
}

.sidebar-list {
    display: flex;
    flex-direction: column;
    padding: 40px 20px 0 20px;
    background: white;
}

.sidebar-link {
    margin-bottom: 40px;
    text-decoration: none;
}

.sidebar-gray {
    position: absolute;
    top: $navbar-height;
    left: 0;
    width: 100%;
    min-height: calc(100vh - #{$navbar-height});
    background: rgba(0,0,0, 0.5);
    z-index: 15;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-right-enter,
.slide-right-leave-to {
    opacity: 0;
    transform: translate(-4em, 0);
}

.slide-right-enter-to,
.slide-right-leave {
    transform: translate(0em, 0);
}

.switch-enter-active, .switch-leave-active {
    transition: opacity .12s ease-in-out;

}

.switch-enter, .switch-leave-to {
    opacity: 0;
}
</style>
