<template>
    <div ref="signatureBox">
        <div class="authorization-border-box">
            <h6 class="section-header">
                Authorization
            </h6>
            <ul class="authorization-test-list">
                <li v-for="(test, index) in $store.getters.getAllTestsOrdered" :key="index">
                    {{ test.productName }}
                </li>
            </ul>
            <p class="section-gray-text">
                {{ $store.getters.getClinicianFullName }}
            </p>
            <p ref="signatureText" class="clinician-signature" :style="'font-family:' + $store.getters.getClinicianSignatureFont + '; font-size:' + fontSize">
                {{ $store.getters.getClinicianFullName }}
            </p>
        </div>
        <div v-if="$store.getters.getIsSingleGeneTest && $store.getters.getCurrentOrderIsMedicare" class="authorization-single-gene-acceptance">
            <div class="authorization-sg-acceptance-header">
                <exclaim-icon class="authorization-sg-header-icon" />
                This Medicare order will result in a Single Gene Report, not the GeneSight Psychotropic Report.
            </div>
            <div class="authorization-sg-content">
                <div class="auth-single-gene-warning__instructions">
                    Selecting medications that interact with <b>multiple genes</b>, as recognized by CPIC and/or the FDA, will result in a <b>GeneSight Psychotropic Report</b>.
                </div>
                <div class="auth-single-gene-warning__link add-medication-now" @click="scrollToMedicareRequiredHeader">add medication(s) now</div>
                <div class="or-container">
                    <div class="or-circle">or</div>
                    <div class="or-horizontal-line"></div>
                </div>
                <myriad-check
                    id="authorization-single-gene-acceptance-check"
                    v-model="singleGeneAcceptance"
                    name="single-gene-acceptance-check"
                    class="section-checkbox authorization-single-gene-acceptance-check"
                    :model-property="singleGeneAcceptance"
                    :label="singleGeneCheckString"
                />
                <div class="attest-copy">
                    By clicking ‘Sign and Authorize Order’, I attest that my electronic signature above authorizes the placement of this Single Gene test order.
                </div>
                <sign-auth-button :disabled="disableSignAuthButton" @sign-auth-click="sighAuthClick" />
            </div>
        </div>
        <div v-else>
            <p class="section-gray-text section-gray-text--small signature-notice">
                By clicking 'Sign and Authorize Order', I attest that my electronic signature above authorizes the placement of GeneSight test orders.
            </p>
            <p v-if="errorsOnPage" class="section-gray-text section-gray-text--small signature-notice orange-text">
                <slot />
            </p>
            <sign-auth-button :disabled="disableSignAuthButton" @sign-auth-click="sighAuthClick" />
        </div>
    </div>
</template>

<script>
import SignAuthButton from "./SignAuthorizeBtn.vue";
import ExclaimIcon from "../../assets/circle-exclamation.svg";
import MyriadCheck from "../MyriadCheck.vue";

export default {
    name: "SignatureAuthorize",
    components: {
        SignAuthButton,
        ExclaimIcon,
        MyriadCheck
    },
    props: {
        errorsOnPage: {
            default: false,
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            fontSize: "30px",
            fontLoaded: false,
            singleGeneCheckString: "I understand I will receive a Single Gene report, not the GeneSight Psychotropic report."
        };
    },
    computed: {
        clinicianName() {
            return this.$store.getters.getClinicianFullName;
        },
        disableSignAuthButton() {
            const singleGeneNotAccepted = !this.singleGeneAcceptance && this.isSingleGeneTest && this.$store.getters.getCurrentOrderIsMedicare;
            return this.errorsOnPage || singleGeneNotAccepted;
        },
        isSingleGeneTest() {
            return this.$store.getters.getIsSingleGeneTest;
        },
        singleGeneAcceptance: {
            get() {
                return this.$store.getters.getSingleGeneAcceptance;
            },
            set(value) {
                this.$store.dispatch("setSingleGeneAcceptance", value);
            }
        }
    },
    watch: {
        clinicianName(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.sizeSignatureToFit();
            }
        },
        isSingleGeneTest(newValue, oldValue) {
            if (newValue !== oldValue && !newValue) {
                this.singleGeneAcceptance = false;
            }
        }
    },
    mounted() {
        this.sizeSignatureToFit();
    },
    methods: {
        sizeSignatureToFit() {
            this.$nextTick(() => {
                const signature = this.$refs.signatureText;
                const signatureBox = this.$refs.signatureBox;

                if (signature.scrollWidth > signatureBox.offsetWidth) {
                    signature.style.opacity = 0;

                    let currentFontSize = window.getComputedStyle(signature, null).getPropertyValue("font-size");
                    currentFontSize = parseInt(currentFontSize);

                    let newFontSize = currentFontSize - 2;
                    newFontSize = newFontSize + "px";

                    this.fontSize = newFontSize;

                    window.setTimeout(function () {
                        this.sizeSignatureToFit();
                    }.bind(this), 100);
                } else {
                    signature.style.opacity = 1;
                }
            });
        },
        sighAuthClick() {
            this.$emit("authorize-order");
        },
        scrollToSingleGeneWarning() {
            document.getElementById("auth-single-gene-alert").scrollIntoView({ behavior: "smooth", block: "start" });
        },
        scrollToMedicareRequiredHeader() {
            document.getElementById("treatment-plan-header").scrollIntoView({ behavior: "smooth", block: "start" });
            this.$store.dispatch("setAugmentationHighlight", true);
            this.$store.dispatch("setDoseageHighlight", true);
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../../styles/AuthorizeOrders.scss";

    .clinician-signature {
        white-space: nowrap;
        overflow-x: hidden;
    }

    .signature-notice {
        margin-bottom: 20px;
    }

    .orange-text {
        color: $genesight-orange;
    }

    .authorization-border-box {
        padding: 15px 7px;
        border: 2px solid lightgray;
        border-radius: 5px;
    }

    .authorization-test-list {
        margin-bottom: 45px;
        padding-left: 15px;
        list-style: disc;
        font-size: $sub-section-label-size;
    }

    .authorization-single-gene-acceptance {
        border: 2px solid $genesight-orange;
        border-radius: 5px;
        margin-top: 40px;
    }

    .authorization-sg-acceptance-header {
        padding: 20px;
        background: #FFF0E7;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        border-bottom: 2px solid #FF6B0B;
        font-size: 1.125em;
        line-height: 1.5em;
    }

    .authorization-sg-header-icon {
        min-width: 18px;
        max-width: 18px;
        min-height: 18px;
        max-height: 18px;
        margin-right: 3px;
        transform: translateY(2px);
    }

    .authorization-sg-header-text {
        text-align: left;
        color: black;
        margin-bottom: 0;
    }

    .authorization-sg-content {
        padding: 30px 20px 12px 20px;
        font-size: $sub-section-label-size;
        line-height: 1.5em;
    }

    .auth-single-gene-warning__link {
        color: #00A8E9;
        cursor: pointer;
        display: inline-block;
    }

    .or-container {
        width: 150px;
        display: flex;
        justify-content: center;
        position: relative;
        margin: 20px 0;
    }

    .or-circle {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        line-height: 28px;
        background-color: #D3D3D3;
        font-size: 16px;
        font-weight: 600;
        font-family: Arial, Helvetica, "Helvetica Neue", Verdana, Tahoma, Sans-Serif;
        z-index: 1;
        position: relative;
    }

    .or-horizontal-line {
        width: 100%;
        height: 2px;
        background-color: #D3D3D3;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 0;
    }

    .authorization-sg-content .sign-auth-button {
        background: #FF6B0B;
    }

    .authorization-sg-content .sign-auth-button .sign-auth-button--disabled {
    color: #bebed0;
    background: #eeeef0;
    pointer-events: none;
}

    .authorizatio-sg-content-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .authorizatio-sg-content-check {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .authorizatio-sg-content-row-left {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 6px;
    }

    .authorization-single-gene-acceptance-check {
        align-items: flex-start;
    }

    ::v-deep .authorization-single-gene-acceptance-check .myriad-checkbox-label {
        line-height: 1.5em;
    }

    ::v-deep .authorization-single-gene-acceptance .sign-auth-button {
        background: #ff6b0b;
    }

    ::v-deep .authorization-single-gene-acceptance .sign-auth-button.sign-auth-button--disabled{
        background: #eeeef0;
    }

    .authorizatio-sg-content-link {
        margin-top: 30px;

    }

    .authorization-sg-content .section-checkbox  {
        margin-bottom: 35px;
    }

    .attest-copy {
        font-family: Arial, Helvetica, "Helvetica Neue", Verdana, Tahoma, Sans-Serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #757575;
        margin-bottom: 35px;
    }
</style>
