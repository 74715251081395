<template>
    <div class="available-reports">
        <div class="report-details">
            <div class="report-patient-name">
                {{ patientName }}
            </div>
            <div v-if="patientDOB != ''" class="report-details-label">
                DOB: {{ patientDOB }}
            </div>
            <div class="report-details-label">
                {{ clinicianName }}
            </div>
            <div v-if="errorMessage != ''" class="monsenso-message">
                <div>
                    {{ errorMessage }}
                </div>
            </div>
        </div>
        <div v-if="errorMessage == ''" class="report-list-top">
            <div v-for="(item, index) in reportListItems" :key="index">
                <a class="report-name" target="_blank" :href="pdfURL + item.orderLineItemId + pdfUrlLanguage + item.documentLanguageId">
                    <div class="report-list-item">
                        <div class="report-list-item-flex">
                            <img class="pdf-icon-image" src="../assets/pdf-icon.png">
                            <div class="report-label-row">
                                <div class="report-label">
                                    {{ item.testName }}
                                </div>
                                <div class="report-list-item-date">
                                    {{ reportCompletedData(item.completedDate) }}
                                </div>
                            </div>
                            <warning-img v-if="item.isAdhd && hasPsych4OrGreater" class="adhd-warn-img"></warning-img>
                        </div>
                        <div class="report-list-adhd-warning-container" v-if="item.isAdhd && hasPsych4OrGreater">
                            <p class="report-list-adhd-warning-header">
                                A newer version of this information is available.
                            </p>
                            <p class="report-list-adhd-warning-text">
                                Please see the Psychotropic report to view the most up-to-date genetic information related to ADHD medications
                            </p>
                        </div>
                    </div>
                </a>
            </div>
            <div v-if="showPHQ9">
                <a class="report-name" target="_blank" :href="phq9URL + orderId">
                    <div class="report-list-item">
                        <img class="pdf-icon-image" src="../assets/pdf-icon.png">
                        <div class="report-label-row">
                            <div class="report-label">
                                PHQ-9 Rating Scale (PDF)
                            </div>
                            <div class="report-list-item-date">
                                {{ phq9CompletedDate }}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import WarningImg from "../assets/warning.svg";
import ReportListHttp from "../scripts/http/report-list-http.js";
import Moment from "moment";

export default {
    name: "Reports",
    components: {
        WarningImg
    },
    data() {
        return {
            patientName: "",
            patientDOB: "",
            clinicianName: "",
            pdfURL: config.url + "/ViewReport?orderLineItemId=",
            pdfUrlLanguage: "&DocumentLanguageId=",
            phq9URL: config.url + "/ViewPHQ9?orderId=",
            isMonsenso: false,
            errorMessage: "",
            reportListItems: [],
            orderId: "",
            showPHQ9: false,
            phq9CompletedDate: "",
            hasPsych4OrGreater: false
        };
    },
    created() {
        this.$store.dispatch("showFullScreenLoader");
        this.orderId = this.$route.params.orderId;

        ReportListHttp.getReports(this.orderId).then((data) => {
            if (data.data) {
                this.patientName = data.data.patientName;
                this.patientDOB = Moment(data.data.patientDOB).format("MM/DD/YYYY");
                this.clinicianName = data.data.clinicianName;
                this.isMonsenso = data.data.isMonsensoOrder;
                this.reportListItems = data.data.reportListItems;
                this.showPHQ9 = data.data.showPHQ9RatingForm;
                this.phq9CompletedDate = Moment(data.data.phQ9CompletedDate).format("M/D/YYYY");
                this.hasPsych4OrGreater = data.data.reportListItems.find(report => report.isPscyh4OrGreater === true).isPscyh4OrGreater;
            } else {
                this.errorMessage = "There was a problem loading this order. Please try again or view the desktop version to access these results.";
            }

            if (this.isMonsenso) {
                this.errorMessage = "This order is not currently viewable from the mobile website. Please view the desktop version to access these results.";
            }
        }).catch(() => {
            this.$store.dispatch("showDefaultNotificationRibbon");
        }).finally(() => {
            this.$store.dispatch("hideFullScreenLoader");
        });
    },
    methods: {
        reportCompletedData(val) {
            return Moment(val).format("M/D/YYYY");
        }
    }
};
</script>

<style lang="scss" scoped>
    .available-reports {
        width: 100%;
        height: 100%;
    }

    .report-list-item {
        border-bottom: 1px solid $border-gray;
    }

    .report-list-item-flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 60px;
        padding: 20px;
        font-size: small;
    }

    .report-list-item-date {
        font-weight: 100;
        font-size: small;
        color: gray;
    }

    .report-patient-name {
        font-weight: bold;
        font-size: large;
    }

    .report-name {
        vertical-align: middle;
        font-weight: bold;
        text-decoration: none;
        color: black;
    }

    .report-name:visited {
        color: black;
    }

    .report-name:active {
        color: black;
    }

    .report-details-label {
        font-size: small;
        color: gray;
    }

    .report-item-chev {
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #aaa;
        float: right;
        margin-top: 2px;
    }

    .report-details {
        padding: 30px 0 30px 10px;
    }

    .monsenso-message {
        padding: 30px 0;
    }

    .report-list-top {
        border-top: 1px solid $border-gray;
    }

    .pdf-icon-image {
        margin-right: 10px;
    }

    .adhd-warn-img {
        position: absolute;
        right: 20px;
        margin-top: -4px;
    }

    .report-list-adhd-warning-container {
        max-width: calc(100% - 73px);
        padding-left: 53px;
        padding-bottom: 40px;
    }

    .report-list-adhd-warning-header {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: small;
        line-height: 15px;
    }

    .report-list-adhd-warning-text {
        font-size: $small-font-size;
        line-height: 14px;
    }
</style>
