<template>
    <button :disabled="!buttonEnabled" class="auth-btn" @click="emitInput">
        <div class="auth-btn-content">
            <p v-if="!buttonApproveState" class="auth-btn-text auth-btn-text-top">
                {{ topText }}
            </p>
            <p v-if="!buttonApproveState" class="auth-btn-text auth-btn-text-btm">
                TAP TO APPROVE
            </p>
            <p v-if="buttonApproveState" class="auth-btn-text auth-btn-approved-text">
                &#10003; Approved
            </p>
        </div>
        <auth-button-bg :class="authButtonBgClass" />
    </button>
</template>

<script>
import AuthButtonBg from "../../assets/auth-button.svg";

export default {
    name: "AuthBtn",
    components: {
        AuthButtonBg
    },
    props: {
        topText: {
            required: true,
            type: String,
            default: ""
        },
        buttonEnabled: {
            requried: true,
            type: Boolean,
            default: false
        },
        buttonApproveState: {
            required: true,
            type: Boolean,
            default: false
        }
    },
    computed: {
        authButtonBgClass() {
            let className = "auth-btn-bg";

            if (!this.buttonEnabled) {
                className = className + " auth-btn-bg--disabled";
            } else if (this.buttonApproveState) {
                className = className + " auth-btn-bg--green";
            }

            return className;
        }
    },
    methods: {
        emitInput() {
            this.$emit("input");
        }
    }
};
</script>

<style lang="scss" scoped>
.auth-btn {
    display: block;
    position: relative;
    width: 186px;
    height: 85.5px;
    margin: -44px auto 0px auto;
    padding: 0;
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
}

.auth-btn-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    width: 100%;
    height: 100%;
}

.auth-btn-text {
    margin: 0;
    padding: 0;
    color: white;
}

.auth-btn-text-top {
    margin-bottom: 2px;
    font-size: $regular-copy-size;
}

.auth-btn-approved-text {
    font-size: $header-font-size;
}

.auth-btn-text-btm {
    font-weight: 700;
    font-size: 1em;
}

.auth-btn-bg--disabled path {
    fill: $light-gray-text;
}

.auth-btn-bg--green path {
    fill: $genesight-green;
}

</style>
