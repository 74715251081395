<template>
    <div class="order-submitted-page">
        <div class="order-submitted-header">
            <flying-envelope class="order-submitted-header-image" />
            <h1 class="order-submitted-header-text">
                Patient Notified
            </h1>
        </div>
        <h3 class="order-submitted-sm-header">
            What's Next?
        </h3>
        <div class="step-section">
            <div class="step-section-icon">
                1
            </div>
            <p class="step-section-text">
                Your patient will receive a notification with a link to provide information required for GeneSight testing.
            </p>
        </div>
        <div class="step-section">
            <div class="step-section-icon">
                2
            </div>
            <div class="step-section-content">
                <p class="step-section-text">
                    Your patient may submit this information prior to their next appointment.
                </p>
                <p class="step-section-sm-text">
                    <b>
                        Note:  We will send them occasional reminders to complete this information. There's no need for you to follow-up with them.
                    </b>
                </p>
            </div>
        </div>
        <div class="step-section">
            <div class="step-section-icon">
                3
            </div>
            <div class="step-section-content">
                <p class="step-section-text">
                    Once completed, you will be notified to review and authorize the order.
                </p>
                <p class="step-section-sm-text">
                    If your patient does not complete entry before their next appointment, please collect and enter their information as usual.
                </p>
            </div>
        </div>
        <neuro-button
            class="order-submit-btn"
            text="Start Another Order"
            :filled="true"
            @neuro-btn-click="$router.push('/NewOrder')"
        />
        <neuro-button
            class="order-submit-btn"
            text="Home"
            :filled="false"
            @neuro-btn-click="$router.push('/')"
        />
    </div>
</template>

<script>
import NeuroButton from "../components/NeuroButton.vue";
import FlyingEnvelope from "../assets/flying_envelope.svg";

export default {
    name: "OrderSubmitted",
    components: {
        FlyingEnvelope,
        NeuroButton
    }
};
</script>

<style lang="scss" scoped>
.order-submitted-page {
    padding: 35px 10px 0 10px;
}

.order-submitted-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.order-submitted-header-image {
    width: 65px;
    height: auto;
    margin-right: 12px;
}

.order-submitted-header-text {
    font-size: 1.375em;
    color: $genesight-green;
}

.order-submitted-sm-header {
    margin: 20px 0 30px 0;
    font-size: $header-font-size;
}

.step-section {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.step-section-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 25px;
    height: 25px;
    margin-right: 12px;
    font-size: 1em;
    font-weight: 700;
    border: 2px solid $genesight-green;
    border-radius: 25px;
    color: $genesight-blue;
    box-sizing: border-box;
}

.step-section-text {
    font-size: $regular-copy-size;
}

.step-section-sm-text {
    color: $light-gray-text;
    font-size: $small-font-size;
}

.order-submit-btn {
    width: 100%;
    margin-top: 20px;
}
</style>
