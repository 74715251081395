<template>
    <div
        :class="myriadCheckboxClass"
        @keydown.enter.space="checkboxClick"
    >
        <div class="myriad-checkbox-box" @click="checkboxClick">
            <check-green v-show="!showUnselected" class="myriad-checkbox-check" />
        </div>
        <input
            :id="id"
            ref="myriadRadioInput"
            class="myriad-checkbox-input"
            type="radio"
            :disabled="disabled"
            :name="name"
            :checked="checkboxSelected"
            @change="checkboxClick"
        >
        <label :for="id" class="myriad-checkbox-label">
            {{ label }}
        </label>
    </div>
</template>

<script>
import MyriadRadioCheckMixin from "../mixins/MyraidRadioCheckMixin.js";

export default {
    name: "MyriadCheckbox",
    mixins: [MyriadRadioCheckMixin],
    props: {
        modelProperty: {
            type: Boolean,
            required: true
        },
        falseCheckbox: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        checkboxSelected() {
            return this.falseCheckbox ? !this.modelProperty : this.modelProperty;
        }
    },
    methods: {
        checkboxClick() {
            if (this.showUnselected) {
                this.$emit("item-selected");
            }

            if (!this.disabled) {
                if (this.falseCheckbox) {
                    this.$emit("input", false);
                    this.$emit("radio-selection", this.modelProperty);
                } else {
                    this.$emit("input", true);
                    this.$emit("radio-selection", this.modelProperty);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../styles/MyriadRadioCheck.scss";
</style>
