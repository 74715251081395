
import LogonHTTP from "../scripts/http/logon-http.js";
import RecordUserActionHttp from "../scripts/http/record-user-action-http.js";
import Router from "../router/router.js";
import Store from "../store/store.js";
import Enums from "../scripts/enums.js";

const common = {
    isEmptyOrNull: (variable) => {
        if (variable === false || variable === 0) {
            return false;
        }
        if (variable === null || variable === undefined || variable === "") {
            return true;
        } else {
            return false;
        }
    },
    arrayContainsObject(obj, list) {
        for (let i = 0; i < list.length; i++) {
            if (list[i] === obj) {
                return true;
            }
        }

        return false;
    },
    getCookie(cookieName) {
        var cookiestring = RegExp("" + cookieName + "[^;]+").exec(document.cookie);
        return decodeURIComponent(cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
    },
    logOff(redirect = true) {
        LogonHTTP.logout().then(() => {
            Store.dispatch("setLoggedOff");
            Store.dispatch("reset");

            if (redirect) {
                Router.push("/LogOn");
            }
        });
    },
    countAsStringInParentheses(count) {
        if (count > 0) {
            return " (" + count + ")";
        } else {
            return null;
        }
    },
    checkForCodeInDiagCodePrefillList(diagCode) {
        return Store.getters.getDefaultDiagnosticCodesDepression.find((prefillCode) => {
            return prefillCode.diagnosticCodeValue === diagCode.diagnosticCodeValue;
        });
    },
    showNavBar() {
        return Store.getters.getLoggedIn && (Store.getters.getClincianSignaureSet || !Store.getters.getIsClinician) && (Store.getters.getAgreementIds && Store.getters.getAgreementIds.length === 0);
    },
    decodeEntities(encodedString) {
        const textAreaa = document.createElement("textarea");
        textAreaa.innerHTML = encodedString;
        return textAreaa.value;
    },
    getJsonPathValue(path, object) {
        return path.reduce((accumulator, currentVal) => {
            if (accumulator && (accumulator[currentVal] !== null && typeof accumulator[currentVal] !== "undefined")) {
                return accumulator[currentVal];
            } else {
                return null;
            }
        }, object);
    },
    getAllUniqueSubTextsInGeneArray(medArray, allMedOptions) {
        const uniqueSubTexts = [];

        medArray.forEach((item) => {
            const matchedMed = allMedOptions.find(med => med.value === item);
            if (matchedMed) {
                const subText = matchedMed.subText;
                if (uniqueSubTexts.indexOf(subText) === -1 && subText !== "" && subText !== undefined) {
                    uniqueSubTexts.push(matchedMed.subText);
                }
            }
        });

        return uniqueSubTexts;
    },
    getTestType() {
        if (this.combinedMeds <= 0) {
            return false;
        }

        const augMedsAnswered = Store.getters.getAugmentationMedications;
        const doseageMedsAnswered = Store.getters.getDoseageMedications;

        const doseageMedsSelected = Store.getters.getIsConsideringDoseageAdjustment;
        const augMedsSelected = Store.getters.getIsConsideringAugmentingWithNewMedication;

        let allMeds = [];
        if (augMedsSelected) {
            allMeds = allMeds.concat(augMedsAnswered);
        }
        if (doseageMedsSelected) {
            allMeds = allMeds.concat(doseageMedsAnswered);
        }

        const allGeneTypes = this.getAllUniqueSubTextsInGeneArray(allMeds, Store.getters.getCombinedMeds);
        if (allGeneTypes.indexOf("multiple genes") !== -1 || allGeneTypes.length > 1) {
            return Enums.TestTypes.MULTI_GENE;
        } else if (allGeneTypes.length === 1) {
            this.singleGeneName = allGeneTypes[0].replace(" only", "");
            return Enums.TestTypes.SINGLE_GENE;
        } else if (allGeneTypes.length === 0) {
            return Enums.TestTypes.ZERO_GENE;
        } else {
            return false;
        }
    },
    isEitherMultiselectActiveAndEqualsGene(currentGeneTypeState) {
        const doseageMultiselect = Store.getters.getDoseageMultiselect;
        const augmentMultiselect = Store.getters.getAugmentationMultiselect;

        const matchesGeneState = !this.isEmptyOrNull(Store.getters.getPreviousGeneTypeState) && Store.getters.getPreviousGeneTypeState === currentGeneTypeState;
        const multiselectsHaveValues = (doseageMultiselect && doseageMultiselect.state.selectedValues.length > 0) || (augmentMultiselect && augmentMultiselect.state.selectedValues.length > 0);

        return matchesGeneState && multiselectsHaveValues;
    },
    setPreviousGeneTypeState() {
        Store.dispatch("setPreviousGeneTypeState", this.getTestType());
    },
    trackUserBehaviorInResponseToGeneWarnings() {
        let actionType = "";

        const overallGeneState = this.getTestType();
        const orderId = Store.getters.getCurrentOrderId;

        if ((this.isEitherMultiselectActiveAndEqualsGene(Enums.TestTypes.ZERO_GENE)) && overallGeneState === Enums.TestTypes.SINGLE_GENE) {
            actionType = "Changed zero gene to single gene during auth";
        } else if ((this.isEitherMultiselectActiveAndEqualsGene(Enums.TestTypes.ZERO_GENE)) && overallGeneState === Enums.TestTypes.MULTI_GENE) {
            actionType = "Changed zero gene to multi gene during auth";
        } else if ((this.isEitherMultiselectActiveAndEqualsGene(Enums.TestTypes.SINGLE_GENE)) && overallGeneState === Enums.TestTypes.MULTI_GENE) {
            actionType = "Changed single gene to multi gene during auth";
        } else if ((this.isEitherMultiselectActiveAndEqualsGene(Enums.TestTypes.SINGLE_GENE)) && overallGeneState === Enums.TestTypes.ZERO_GENE) {
            actionType = "Changed single gene to zero gene during auth";
        } else if ((this.isEitherMultiselectActiveAndEqualsGene(Enums.TestTypes.MULTI_GENE)) && overallGeneState === Enums.TestTypes.SINGLE_GENE) {
            actionType = "Changed multi gene to single gene during auth";
        } else if ((this.isEitherMultiselectActiveAndEqualsGene(Enums.TestTypes.MULTI_GENE)) && overallGeneState === Enums.TestTypes.ZERO_GENE) {
            actionType = "Changed multi gene to zero gene during auth";
        }

        if (actionType !== "") {
            const data = {};
            data.id = orderId;
            data.actionType = actionType;

            RecordUserActionHttp.CreateUserAction(data);
        }

        this.setPreviousGeneTypeState();
    },
    getCurrentOrderToAuthorize() {
        const orderToAuth = Store.getters.getCurrentOrderToAuthorize;
        orderToAuth.orderLineItemsDomainModel.orderLineItemDomainModels[0] = orderToAuth.psych;
        orderToAuth.orderLineItemsDomainModel.orderLineItemDomainModels[1] = orderToAuth.otherTests[0];
        orderToAuth.orderLineItemsDomainModel.currentProducts[0] = orderToAuth.psych;
        orderToAuth.orderLineItemsDomainModel.currentProducts[1] = orderToAuth.otherTests[0];
        orderToAuth.orderLineItemsDomainModel.allNonChildProducts[0] = orderToAuth.psych;
        orderToAuth.orderLineItemsDomainModel.allNonChildProducts[1] = orderToAuth.otherTests[0];
        return orderToAuth;
    },
    getGoogleFontName(originalFontName) {
        var newFontName = "";

        switch (originalFontName) {
        case "Cedarville-Cursive":
            newFontName = "Cedarville Cursive";
            break;
        case "DancingScript-Regular":
            newFontName = "Dancing Script";
            break;

        case "DawningofaNewDay":
            newFontName = "Dawning of a New Day";
            break;

        case "GreatVibes-Regular":
            newFontName = "Great Vibes";
            break;

        case "HomemadeApple-Regular":
            newFontName = "Homemade Apple";
            break;

        case "Meddon":
            newFontName = "Meddon";
            break;
        }

        return newFontName;
    }
};

export default common;
