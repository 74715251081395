export default {
    state: {
        allMeds: [],
        combinedMeds: [],
        otherMeds: [],
        cpicMeds: [],
        blackList: [],
        greyList: [],
        selectedAugmentationMeds: [],
        selectedDoesageMeds: []
    },
    getters: {
        getAllMeds: state => {
            return state.allMeds;
        },
        getBlackList: state => {
            return state.blackList;
        },
        getGrayList: state => {
            return state.greyList;
        },
        getCombinedMeds: state => {
            return state.combinedMeds;
        },
        getOtherMeds: state => {
            return state.otherMeds;
        },
        getCpicMeds: state => {
            return state.cpicMeds;
        },
        getSelectedAugmentationMeds: state => {
            return state.selectedAugmentationMeds;
        },
        getSelectedDoesageMeds: state => {
            return state.selectedDoesageMeds;
        }
    },
    mutations: {
        setAllMeds(state, meds) {
            state.allMeds = meds;
        },
        setBlackList(state, medBlackList) {
            state.blackList = medBlackList;
        },
        setGrayList(state, medGreyList) {
            state.greyList = medGreyList;
        },
        setCombinedMeds(state, meds) {
            state.combinedMeds = meds;
        },
        setOtherMeds(state, meds) {
            state.otherMeds = meds;
        },
        setCpicMeds(state, meds) {
            state.cpicMeds = meds;
        },
        setSelectedAugmentationMeds(state, meds) {
            state.selectedAugmentationMeds = meds;
        },
        setSelectedDoesageMed(state, meds) {
            state.selectedDoesageMed = meds;
        }
    },
    actions: {
        setAllMeds(context, meds) {
            context.commit("setAllMeds", meds);
        },
        setBlackList(context, medBlackList) {
            context.commit("setBlackList", medBlackList);
        },
        setGrayList(context, medGreyList) {
            context.commit("setGrayList", medGreyList);
        },
        setCombinedMeds(context, meds) {
            context.commit("setCombinedMeds", meds);
        },
        setOtherMeds(context, meds) {
            context.commit("setOtherMeds", meds);
        },
        setCpicMeds(context, meds) {
            context.commit("setCpicMeds", meds);
        },
        setSelectedAugmentationMeds(context, meds) {
            context.commit("setSelectedAugmentationMeds", meds);
        },
        setSelectedDoesageMed(context, meds) {
            context.commit("setSelectedDoesageMed", meds);
        }
    }
};
