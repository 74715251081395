<template>
    <div class="medicare-required">
        <gene-warning v-if="isZeroGeneTest && showMedicareSection" :report-link="singleGeneReportLink" class="single-gene-alert" :show-zero-gene-message="true" :is-zero-gene-test="isZeroGeneTest" />
        <gene-warning v-if="isSingleGeneTest && showMedicareSection" id="auth-single-gene-alert" :report-link="singleGeneReportLink" :single-gene-name="singleGeneName" class="single-gene-alert" :show-zero-gene-message="false" :medication-list="medicationList" :single-gene-subtext="singleGene"/>
        <label class="sub-section-label">
            Have you considered non-genetic factors to make a preliminary drug selection, including a personalized medication decision based on the patient’s diagnosis, the patient’s other medical conditions, other medications the patient is taking, professional judgment, clinical science and basic science pertinent to the drug (e.g. mechanism of action, side effects), the patient’s past medical history, and when pertinent, family history and the patient's preferences and values?
        </label>
        <div class="sub-note"><span>Note:</span> Many insurance providers require clinicians to consider non-genetic factors when ordering this test.</div>
        <error-wrapper
            :error-exists="$store.getters.nonGeneticFactorsError"
            error-message="Please provide a response."
        >
            <div class="section-check-row">
                <myriad-radio
                    id="non-genetic-yes"
                    v-model="considerNonGeneticFactors"
                    name="nonGeneticYes"
                    label="Yes"
                    class="section-radio"
                    :show-unselected="isConsiderNonGeneticFactorNotSelected"
                    :model-property="considerNonGeneticFactors"
                    @item-selected="nonGeneticFactorsDirty = true"
                />
                <myriad-radio
                    id="non-genetic-no"
                    v-model="considerNonGeneticFactors"
                    name="nonGeneticNo"
                    label="No"
                    class="section-radio"
                    :false-checkbox="true"
                    :show-unselected="isConsiderNonGeneticFactorNotSelected"
                    :model-property="considerNonGeneticFactors"
                    @item-selected="nonGeneticFactorsDirty = true"
                />
            </div>
        </error-wrapper>
        <gene-warning v-if="!isConsiderNonGeneticFactorNotSelected && !considerNonGeneticFactors && showMedicareSection"
                      class="non-genetic-warning"
                      :report-link="singleGeneReportLink"
                      :show-zero-gene-message="true"
                      :is-zero-gene-test="isZeroGeneTest"
                      :is-abn-warning="true"
                      :is-multi-gene-test="isMultiGeneTest"
                      :has-considered-non-genetic-factors="considerNonGeneticFactors"
                      :single-gene-subtext="singleGene"
        />
    </div>
</template>

<script>
import MyriadRadio from "../../../MyriadRadio.vue";
import ErrorWrapper from "../../ErrorWrapper.vue";
import GeneWarning from "../Psychotropic/GeneWarning.vue";
import Common from "../../../../scripts/common.js";
import Enums from "../../../../scripts/enums.js";

export default {
    name: "MedicareRequired",
    components: {
        MyriadRadio,
        ErrorWrapper,
        GeneWarning
    },
    data() {
        return {
            nonGeneticFactors: false,
            nonGeneticFactorsDirty: false,
            augmentHeaderString: "You selected that this patient has Medicare. Medicare coverage for PGx tests is limited to when providers are considering use of one or more medications with a gene-drug interaction that is clinically actionable and designated by the FDA or CPIC (category A or B).",
            singleGeneName: "",
            augmentationObjectList: [],
            doseageObjectList: []
        };
    },
    computed: {
        showMedicareSection() {
            const isTreatmentPlanSelected = this.$store.getters.getIsConsideringAugmentingWithNewMedication || this.$store.getters.getIsConsideringDoseageAdjustment;
            const isMedicare = this.$store.getters.getCurrentOrderIsMedicare;

            return isTreatmentPlanSelected && isMedicare;
        },
        considerNonGeneticFactors: {
            get() {
                return this.$store.getters.getConsideredNonGeneticFactors.value;
            },
            set(value) {
                this.$store.dispatch("setConsideredNonGeneticFactors", value);
            }
        },
        isConsiderNonGeneticFactorNotSelected() {
            return !this.nonGeneticFactorsDirty && this.$store.getters.getConsideredNonGeneticFactors.unselected;
        },
        augmentationGene: {
            get() {
                return this.$store.getters.getAugmentationGene;
            }
        },
        doseageGene: {
            get() {
                return this.$store.getters.getDoseageGene;
            }
        },
        augmentationMedications: {
            get() {
                return this.$store.getters.getAugmentationMedications;
            }
        },
        doseageMedications: {
            get() {
                return this.$store.getters.getDoseageMedications;
            }
        },
        singleGeneReportLink() {
            const configUrl = config.url;
            const baseUrl = configUrl.replace("api", "");
            const url = baseUrl + "/Order/SingleGeneSampleReport";
            return url;
        },
        isSingleGeneTest() {
            const { doseageMedsValid, augMedsValid } = this.getMedInputsValid();
            return doseageMedsValid && augMedsValid && Common.getTestType() === Enums.TestTypes.SINGLE_GENE;
        },
        isZeroGeneTest() {
            const { doseageMedsValid, augMedsValid } = this.getMedInputsValid();
            return doseageMedsValid && augMedsValid && Common.getTestType() === Enums.TestTypes.ZERO_GENE;
        },
        isMultiGeneTest() {
            const { doseageMedsValid, augMedsValid } = this.getMedInputsValid();
            return doseageMedsValid && augMedsValid && Common.getTestType() === Enums.TestTypes.MULTI_GENE;
        },
        medicationList() {
            var meds = [];

            if (this.$store.getters.getIsConsideringAugmentingWithNewMedication) {
                meds = [...meds, ...this.augmentationList];
            }
            if (this.$store.getters.getIsConsideringDoseageAdjustment && !this.$store.getters.getDisableDoseageAdjustment) {
                meds = [...meds, ...this.doseageList];
            }

            return meds;
        },
        singleGene() {
            if (this.$store.getters.getIsConsideringAugmentingWithNewMedication) {
                return this.augmentationGene;
            }
            if (this.$store.getters.getIsConsideringDoseageAdjustment && !this.$store.getters.getDisableDoseageAdjustment) {
                return this.doseageGene;
            }

            return "";
        },
        augmentationList: {
            get() {
                return this.$store.getters.getSelectedAugmentationMeds;
            }
        },
        doseageList: {
            get() {
                return this.$store.getters.getSelectedDoesageMeds;
            }
        }
    },
    methods: {
        getMedInputsValid() {
            const doseageMedsSelected = this.$store.getters.getIsConsideringDoseageAdjustment;
            const doseageMedsAnswered = this.doseageMedications.length > 0;

            const augMedsSelected = this.$store.getters.getIsConsideringAugmentingWithNewMedication;
            const augMedsAnswered = this.augmentationMedications.length > 0;

            const doseageMedsValid = doseageMedsSelected ? doseageMedsAnswered : true;
            const augMedsValid = augMedsSelected ? augMedsAnswered : true;

            return {
                doseageMedsValid: doseageMedsValid,
                augMedsValid: augMedsValid
            };
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~@myriad-neuroscience/myriad-neuro-multiselect/neuro-multiselect.css";
@import "../../../../styles/AuthorizeOrders.scss";

.non-genetic-warning {
    margin-top: 30px;
}

.multiselect-single-gene-warning >>> .myriad-neuro-selected-input-container {
    border: 2px solid #ff6b0b !important;
}

.multiselect-add-medication >>> .myriad-neuro-selected-input-container,
.multiselect-add-medication >>> input[type=text].myriad-neuro-multiselect-input {
    background-color: #FFF0E7 !important;
}

.sub-note {
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #757575;
    margin: 15px 0 20px;
}

.sub-note span {
    font-family: Arial;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #757575;
}

</style>
