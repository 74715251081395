<template>
    <div class="labeled-select">
        <label class="labeled-select-label">
            {{ label }}
        </label>
        <select ref="selectBox" class="labeled-select-input" :data-neuro-auto="automationValue" @change="selectionMade">
            <option v-if="startEmpty" class="labeled-select-option" disabled selected />
            <option v-for="(item, index) in options"
                    :key="index"
                    :value="index"
                    class="labeled-select-option"
            >
                {{ item.name }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "LabeledSelect",
    props: {
        options: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        startEmpty: {
            type: Boolean,
            required: false,
            default: false
        },
        automationValue: {
            type: String,
            required: false,
            default: ""
        }
    },
    watch: {
        options: function(newValue, oldValue) {
            var noOldVal = oldValue.length <= 0;
            var hasNewValue = newValue.length > 0;

            if (noOldVal && hasNewValue) {
                this.selectedOption = this.options[0];
            }
        }
    },
    methods: {
        selectionMade() {
            const optionsIndex = this.$refs.selectBox.value;
            const selectedOption = this.options[optionsIndex];
            this.$emit("selection-made", selectedOption);
        }
    }
};
</script>

<style lang="scss" scoped>
.labeled-select {
    position: relative;
}

.labeled-select-label {
    position: absolute;
    top: -7px;
    left: 7px;
    padding-left: 2px;
    padding-right: 5px;
    font-size: 0.7em;
    background: white;
    color: $text-color;
    pointer-events: none;
}

.labeled-select-input {
    width: 100%;
    height: 35px;
    margin-bottom: 15px;
    border: 1px solid $border-grey;
    border-radius: 5px;
    font-size: 1em;
    z-index: 10;

    &::placeholder {
        color: $light-gray-text;
    }
}

.labeled-select-option {
    color: $text-color;
}
</style>
