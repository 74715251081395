<template>
    <div class="phq9-results">
        <p class="section-gray-text section-gray-text--small">
            PHQ-9 Results as of {{ phq9Date }}
        </p>
        <table class="table-flex">
            <th class="table-row">
                <td class="first-column phq9-score-section table-item">
                    <div class="phq-score-label">
                        {{ $store.getters.getPhq9Score }}
                    </div>
                    {{ getPhq9ScoreValueString() }}
                </td>
                <td class="second-column table-item table-item-slim" />
                <td class="third-column table-item">
                    Response
                </td>
            </th>
            <tr class="table-row gray-row">
                <td class="first-column table-item table-item--left">
                    Little interest or pleasure in doing things
                </td>
                <td class="second-column table-item table-item-slim">
                    {{ littleInterest.key }}
                </td>
                <td class="third-column table-item">
                    {{ littleInterest.value }}
                </td>
            </tr>
            <tr class="table-row">
                <td class="first-column table-item table-item--left">
                    Feeling down, depressed or hopeless
                </td>
                <td class="second-column table-item table-item-slim">
                    {{ feelingDepressed.key }}
                </td>
                <td class="third-column table-item">
                    {{ feelingDepressed.value }}
                </td>
            </tr>
            <tr class="table-row gray-row">
                <td class="first-column table-item table-item--left">
                    Trouble falling or staying asleep, or sleeping too much
                </td>
                <td class="second-column table-item table-item-slim">
                    {{ troubleSleeping.key }}
                </td>
                <td class="third-column table-item">
                    {{ troubleSleeping.value }}
                </td>
            </tr>
            <tr class="table-row">
                <td class="first-column table-item table-item--left">
                    Feeling tired or having little energy
                </td>
                <td class="second-column table-item table-item-slim">
                    {{ littleEnergy.key }}
                </td>
                <td class="third-column table-item">
                    {{ littleEnergy.value }}
                </td>
            </tr>
            <tr class="table-row gray-row">
                <td class="first-column table-item table-item--left">
                    Poor appetite or overeating
                </td>
                <td class="second-column table-item table-item-slim">
                    {{ poorAppetite.key }}
                </td>
                <td class="third-column table-item">
                    {{ poorAppetite.value }}
                </td>
            </tr>
            <tr class="table-row">
                <td class="first-column table-item table-item--left">
                    Feeling bad about yourself or that you have let yourself or your family down
                </td>
                <td class="second-column table-item table-item-slim">
                    {{ feelLikeAFailure.key }}
                </td>
                <td class="third-column table-item">
                    {{ feelLikeAFailure.value }}
                </td>
            </tr>
            <tr class="table-row gray-row">
                <td class="first-column table-item table-item--left">
                    Trouble concentrating on things, such as reading the newspaper or watching television
                </td>
                <td class="second-column table-item table-item-slim">
                    {{ troubleConcentrating.key }}
                </td>
                <td class="third-column table-item">
                    {{ troubleConcentrating.value }}
                </td>
            </tr>
            <tr class="table-row">
                <td class="first-column table-item table-item--left">
                    Moving or speaking so slowly that people could have noticed. Or the opposite - being to fidgety or restless that you have been moving around a lot more than usual
                </td>
                <td class="second-column table-item table-item-slim">
                    {{ lethargicOrRestless.key }}
                </td>
                <td class="third-column table-item">
                    {{ lethargicOrRestless.value }}
                </td>
            </tr>
            <tr class="table-row gray-row">
                <td class="first-column table-item table-item--left">
                    Thoughts that you would be better off dead, or of hurting yourself
                </td>
                <td class="second-column table-item table-item-slim">
                    {{ suicidalThoughts.key }}
                </td>
                <td class="third-column table-item">
                    {{ suicidalThoughts.value }}
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import Moment from "moment";
import Enums from "../../scripts/enums.js";
import Common from "../../scripts/common.js";

export default {
    name: "Phq9Results",
    computed: {
        phq9Date() {
            return Moment(this.$store.getters.getPhq9Date).format("M/D/YYYY");
        },
        littleInterest() {
            return this.getPhq9AttributeDate(this.$store.getters.getLittleInterest);
        },
        feelingDepressed() {
            return this.getPhq9AttributeDate(this.$store.getters.getFeelingDepressed);
        },
        troubleSleeping() {
            return this.getPhq9AttributeDate(this.$store.getters.getTroubleSleeping);
        },
        littleEnergy() {
            return this.getPhq9AttributeDate(this.$store.getters.getLittleEnergy);
        },
        poorAppetite() {
            return this.getPhq9AttributeDate(this.$store.getters.getPoorAppetite);
        },
        feelLikeAFailure() {
            return this.getPhq9AttributeDate(this.$store.getters.getFeelLikeAFailure);
        },
        troubleConcentrating() {
            return this.getPhq9AttributeDate(this.$store.getters.getTroubleConcentrating);
        },
        lethargicOrRestless() {
            return this.getPhq9AttributeDate(this.$store.getters.getLethargicOrRestless);
        },
        suicidalThoughts() {
            return this.getPhq9AttributeDate(this.$store.getters.getSuicidalThoughts);
        }
    },
    methods: {
        getPhq9AttributeDate(storeGetter) {
            if (Common.isEmptyOrNull(storeGetter)) {
                return {
                    key: "",
                    value: ""
                };
            } else {
                return {
                    key: Enums.Phq9Map[storeGetter].id,
                    value: Enums.Phq9Map[storeGetter].value
                };
            }
        },
        getPhq9ScoreValueString() {
            const score = this.$store.getters.getPhq9Score;

            let stringValue = "";

            if (score <= 4) {
                stringValue = "Minimal";
            } else if (score >= 5 && score <= 9) {
                stringValue = "Mild";
            } else if (score >= 10 && score <= 19) {
                stringValue = "Moderate";
            } else if (score >= 20) {
                stringValue = "Severe";
            }

            stringValue = stringValue + " Depression";
            return stringValue;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../styles/AuthorizeOrders.scss";

.phq9-results {
    padding: 0 7px;
}

.table-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.table-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top: 1px solid $border-grey;
    border-right: 1px solid $border-grey;
    border-left: 1px solid $border-grey;
}

.table-row:last-of-type {
    border-bottom: 1px solid $border-grey;
}

.gray-row {
    background: #F2F2F2;
}

.first-column {
    display: block;
    width: 68%;
    text-align: left;
}

.second-column {
    display: block;
    width: 5%;
    font-weight: 700;
    border-left: 1px solid $border-grey;
    border-right: 1px solid $border-grey;
}

.third-column {
    display: block;
    width: 27%;
    text-align: center;
}

.table-item {
    @include flex-center-vertical();
    padding: 18px 10px;
    box-sizing: border-box;
}

.table-item--left {
    align-items: flex-start;
}

.table-item-slim {
    padding: 18px 0;
}

.phq9-score-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.phq-score-label {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    line-height: 30px;
    font-size: $regular-copy-size;
    font-weight: 700;
    text-align: center;
    border: 1px solid black;
}
</style>
