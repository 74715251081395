import { HTTP } from "./http";

const ContactHttp = {
    getContactInfo: () => {
        const url = config.url + "/ContactInfo";
        return HTTP.get(url);
    }
};

export default ContactHttp;
