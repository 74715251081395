<template>
    <div>
        <h6 class="section-header">
            Psychotropic Diagnosis
        </h6>
        <div>
            <p class="section-text">
                According to the DSM-5, does the patient suffer from Major Depressive Disorder (MDD)?
            </p>
            <error-wrapper
                :warning-exists="!sufferFromDsmDepression && $store.getters.getCurrentOrderIsMedicare"
                warning-message="The patient must suffer from MDD for this order to meet Medicare coverage"
            >
                <div class="section-check-row major-symptoms-error">
                    <myriad-radio
                        id="mdd-check-yes"
                        v-model="sufferFromDsmDepression"
                        name="mdd-check"
                        label="Yes"
                        class="section-radio"
                        :model-property="sufferFromDsmDepression"
                        @radio-selection.exact="$store.dispatch('setDiagnosisApproved', false);"
                        @radio-selection.once="symptomsDirty = true"
                    />
                    <myriad-radio
                        id="mdd-check-no"
                        v-model="sufferFromDsmDepression"
                        name="mdd-check"
                        label="No"
                        class="section-radio"
                        :false-checkbox="true"
                        :model-property="sufferFromDsmDepression"
                        @radio-selection.exact="$store.dispatch('setDiagnosisApproved', false);"
                        @radio-selection.once="symptomsDirty = false"
                    />
                </div>
            </error-wrapper>
        </div>
        <div v-if="sufferFromDsmDepression">
            <p class="section-gray-text">
                Depressive Symptoms
            </p>
            <p class="section-criteria">
                <b>At least 1</b> of the following:
            </p>
            <error-wrapper
                :error-message="anySymptomsErrorString"
                :error-exists="anySymptomsError"
                :is-dirty="symptomsDirty"
            >
                <myriad-check
                    id="depressiveMoodCheck"
                    ref="depressiveMood"
                    v-model="depressiveMood"
                    name="depressiveMoodCheck"
                    label="Depressive Mood"
                    class="section-checkbox"
                    :model-property="depressiveMood"
                    @check-selection.exact="$store.dispatch('setDiagnosisApproved', false);"
                    @check-selection.once="symptomsDirty = true"
                />
                <myriad-check
                    id="diminshedInterestCheck"
                    ref="diminshedInterest"
                    v-model="diminshedInterestActivities"
                    name="diminshedInterestCheck"
                    label="Diminished interest in activities"
                    class="section-checkbox"
                    :model-property="diminshedInterestActivities"
                    @check-selection.exact="$store.dispatch('setDiagnosisApproved', false);"
                    @check-selection.once="symptomsDirty = true"
                />
                <p class="symptoms-and-split">
                    and
                </p>
                <myriad-check
                    id="significantWeightLoss"
                    v-model="signicantWeightLoss"
                    name="significantWeightLoss"
                    label="Significant weight loss"
                    class="section-checkbox"
                    :model-property="signicantWeightLoss"
                    @check-selection.exact="$store.dispatch('setDiagnosisApproved', false);"
                    @check-selection.once="symptomsDirty = true"
                />
                <myriad-check
                    id="insomnia"
                    v-model="insomnia"
                    name="insomnia"
                    label="Insomnia or hypersomnia"
                    class="section-checkbox"
                    :model-property="insomnia"
                    @check-selection.exact="$store.dispatch('setDiagnosisApproved', false);"
                    @check-selection.once="symptomsDirty = true"
                />
                <myriad-check
                    id="psychomotor"
                    v-model="psychomotor"
                    name="psychomotor"
                    label="Psychomotor agitation"
                    class="section-checkbox"
                    :model-property="psychomotor"
                    @check-selection.exact="$store.dispatch('setDiagnosisApproved', false);"
                    @check-selection.once="symptomsDirty = true"
                />
                <myriad-check
                    id="fatigue"
                    v-model="fatigue"
                    name="fatigue"
                    label="Fatigue"
                    class="section-checkbox"
                    :model-property="fatigue"
                    @check-selection.exact="$store.dispatch('setDiagnosisApproved', false);"
                    @check-selection.once="symptomsDirty = true"
                />
                <myriad-check
                    id="feelingWorthless"
                    v-model="feelingWorthless"
                    name="feelingWorthless"
                    label="Feelings of worthlessness of guilt"
                    class="section-checkbox"
                    :model-property="feelingWorthless"
                    @check-selection.exact="$store.dispatch('setDiagnosisApproved', false);"
                    @check-selection.once="symptomsDirty = true"
                />
                <myriad-check
                    id="diminshedConcentration"
                    v-model="dmininshedConcentration"
                    name="diminshedConcentration"
                    label="Diminished concentration"
                    class="section-checkbox"
                    :model-property="dmininshedConcentration"
                    @check-selection.exact="$store.dispatch('setDiagnosisApproved', false);"
                    @check-selection.once="symptomsDirty = true"
                />
                <myriad-check
                    id="thoughtOfDeath"
                    v-model="recurrentThoughtOfDeath"
                    name="thoughtOfDeath"
                    label="Recurrent thought of death"
                    class="section-checkbox"
                    :model-property="recurrentThoughtOfDeath"
                    @check-selection.exact="$store.dispatch('setDiagnosisApproved', false);"
                    @check-selection.once="symptomsDirty = true"
                />
            </error-wrapper>
            <p class="dsm5-title">
                According to the DSM-5, are the patient's depressive symptoms:
            </p>
            <div>
                <error-wrapper
                    :error-message="symptomsSeverityErrorString"
                    :error-exists="symptomsSeverityError"
                    :warning-exists="$store.getters.getMildSeverity && $store.getters.getCurrentOrderIsMedicare"
                    warning-message="The patient must have moderate or severe MDD for this order to meet Medicare coverage"
                    :is-dirty="severityDirty"
                >
                    <myriad-radio
                        id="severity-mild"
                        v-model="mildSeverity"
                        name="symptom-severity"
                        label="Mild"
                        class="section-checkbox"
                        :model-property="mildSeverity"
                        @radio-selection="symptomsSeveritySet"
                    />
                    <p class="symptom-desc">
                        few, if any, symptoms in excess of the five required to make the diagnoses and the symptoms result in only minor functional impairment
                    </p>
                    <myriad-radio
                        id="severity-moderate"
                        v-model="moderateSeverity"
                        name="symptom-severity"
                        label="Moderate"
                        class="section-checkbox"
                        :model-property="moderateSeverity"
                        @radio-selection="symptomsSeveritySet"
                    />
                    <p class="symptom-desc">
                        symptoms or functional impairment are between 'mild' and 'severe'
                    </p>

                    <myriad-radio
                        id="severity-severe"
                        v-model="severeSevertiy"
                        name="symptom-severity"
                        label="Severe"
                        class="section-checkbox"
                        :model-property="severeSevertiy"
                        @radio-selection="symptomsSeveritySet"
                    />
                    <p class="symptom-desc">
                        most symptoms and the symptoms markedly interfere with functioning; can occur with or without psychotic symptoms
                    </p>
                </error-wrapper>
            </div>
            <div v-if="!$store.getters.getCurrentOrderIsMedicare">
                <p class="anxiety-title">
                    Does the patient suffer from anxiety?
                </p>
                <error-wrapper
                    :warning-exists="hasAnxiety && !hasAnxietyDiagnosticCodeSelected"
                    warning-message="It doesn't appear that an ICD-10 code for anxiety was provided. If possible, please enter an anxiety ICD-10 code."
                >
                    <div class="section-check-row major-symptoms-error">
                        <myriad-radio
                            id="anxiety-yes"
                            v-model="hasAnxiety"
                            name="anxiety-check"
                            label="Yes"
                            class="section-radio"
                            :model-property="hasAnxiety"
                            @radio-selection="$store.dispatch('setDiagnosisApproved', false);"
                        />
                        <myriad-radio
                            id="anxiety-no"
                            v-model="hasAnxiety"
                            name="anxiety-check"
                            label="No"
                            class="section-radio"
                            :false-checkbox="true"
                            :model-property="hasAnxiety"
                            @radio-selection="$store.dispatch('setDiagnosisApproved', false);"
                        />
                    </div>
                </error-wrapper>
            </div>
        </div>
    </div>
</template>

<script>
import MyriadRadio from "../../../components/MyriadRadio.vue";
import MyriadCheck from "../../../components/MyriadCheck.vue";
import ErrorWrapper from "../ErrorWrapper.vue";
import Common from "../../../scripts/common.js";
import { ICD10Strings } from "../../../scripts/strings.js";

export default {
    name: "DepressiveSymptoms",
    components: {
        MyriadRadio,
        MyriadCheck,
        ErrorWrapper
    },
    data() {
        return {
            minTotalSymptoms: 5,
            severeMinTotalSymptoms: 6,
            symptomsDirty: false,
            severityDirty: false,
            mildSymptomsErrorString: "<i>At least</i> <b>5</b> symptoms from above are required to select Mild.",
            moderateSymptomsErrorString: "<i>At least</i> <b>5</b> symptoms from above are required to select Moderate.",
            severeSymptomsErrorString: "<i>At least</i> <b>6</b> symptoms from above are required to select Severe."
        };
    },
    computed: {
        totalSymptoms() {
            return this.amountMajorSymptoms + this.amountRegularSymptoms;
        },
        totalSymptomsError() {
            return this.amountMajorSymptoms <= 0 || this.totalSymptoms < this.minTotalSymptoms;
        },
        severeTotalSymptomsError() {
            return this.$store.getters.getSevereSeverity && (this.totalSymptoms < this.severeMinTotalSymptoms);
        },
        mildModerateSympotomsErrror() {
            return (this.$store.getters.getModerateSeverity || this.$store.getters.getMildSeverity) && (this.totalSymptoms < this.minTotalSymptoms);
        },
        mildSympotomsErrror() {
            return this.$store.getters.getMildSeverity && (this.totalSymptoms < this.minTotalSymptoms);
        },
        moderateSympotomsErrror() {
            return this.$store.getters.getModerateSeverity && (this.totalSymptoms < this.minTotalSymptoms);
        },
        anySymptomsError() {
            return this.totalSymptomsError || this.severeTotalSymptomsError || this.mildModerateSympotomsErrror;
        },
        anySymptomsErrorString() {
            if (this.mildSympotomsErrror) {
                return this.mildSymptomsErrorString;
            } else if (this.moderateSympotomsErrror) {
                return this.moderateSymptomsErrorString;
            } else if (this.severeTotalSymptomsError) {
                return this.severeSymptomsErrorString;
            } else {
                return "<i>At least</i> <b>5</b> symptoms must be selected, including at least one from the first two symptoms.";
            }
        },
        symptomsSeverityError() {
            const hasMismatchedDiagnosticCode = Common.arrayContainsObject(this.$store.getters.getDiagCodeSeverityErrorString, this.$store.getters.getDiagnosisErrors);
            return this.severeTotalSymptomsError || this.mildModerateSympotomsErrror || hasMismatchedDiagnosticCode;
        },
        symptomsSeverityErrorString() {
            const hasMismatchedDiagnosticCode = Common.arrayContainsObject(this.$store.getters.getDiagCodeSeverityErrorString, this.$store.getters.getDiagnosisErrors);

            if (this.severeTotalSymptomsError) {
                return this.severeSymptomsErrorString;
            } else if (this.mildSympotomsErrror) {
                return this.mildSymptomsErrorString;
            } else if (this.moderateSympotomsErrror) {
                return this.moderateSymptomsErrorString;
            } else if (hasMismatchedDiagnosticCode) {
                return ICD10Strings.SeverityMismatchError;
            } else {
                return "";
            }
        },
        amountMajorSymptoms() {
            const depressedTotal = this.depressiveMood ? 1 : 0;
            const diminshedTotal = this.diminshedInterestActivities ? 1 : 0;

            return depressedTotal + diminshedTotal;
        },
        amountRegularSymptoms() {
            const weightLossTotal = this.signicantWeightLoss ? 1 : 0;
            const insomniaTotal = this.insomnia ? 1 : 0;
            const psychomotorTotal = this.psychomotor ? 1 : 0;
            const fatigueTotal = this.fatigue ? 1 : 0;
            const feelingWorthlessTotal = this.feelingWorthless ? 1 : 0;
            const dmininshedConcentrationTotal = this.dmininshedConcentration ? 1 : 0;
            const thoughtOfDeathTotal = this.recurrentThoughtOfDeath ? 1 : 0;

            return weightLossTotal + insomniaTotal + psychomotorTotal + fatigueTotal + feelingWorthlessTotal + dmininshedConcentrationTotal + thoughtOfDeathTotal;
        },
        hasAnxietyDiagnosticCodeSelected() {
            let anxietyDiagCodeFound = false;

            const diagCode = Common.checkForCodeInDiagCodePrefillList(this.$store.getters.getDiagCode);
            const secondaryDiagCode1 = Common.checkForCodeInDiagCodePrefillList(this.$store.getters.getSecondaryDiagCode1);
            const secondaryDiagCode2 = Common.checkForCodeInDiagCodePrefillList(this.$store.getters.getSecondaryDiagCode2);
            const secondaryDiagCode3 = Common.checkForCodeInDiagCodePrefillList(this.$store.getters.getSecondaryDiagCode3);

            if (diagCode && diagCode.isAnxiety) {
                anxietyDiagCodeFound = true;
            }

            if (secondaryDiagCode1 && secondaryDiagCode1.isAnxiety) {
                anxietyDiagCodeFound = true;
            }

            if (secondaryDiagCode2 && secondaryDiagCode2.isAnxiety) {
                anxietyDiagCodeFound = true;
            }

            if (secondaryDiagCode3 && secondaryDiagCode3.isAnxiety) {
                anxietyDiagCodeFound = true;
            }

            return anxietyDiagCodeFound;
        },
        sufferFromDsmDepression: {
            get() {
                return this.$store.getters.getDsm5SufferFromDepression;
            },
            set(value) {
                this.$store.dispatch("setDsm5SufferFromDepression", value);
            }
        },
        depressiveMood: {
            get() {
                return this.$store.getters.getDepressiveMood;
            },
            set(value) {
                this.$store.dispatch("setDepressiveMood", value);
            }
        },
        diminshedInterestActivities: {
            get() {
                return this.$store.getters.getDiminshedInterestActivities;
            },
            set(value) {
                this.$store.dispatch("setDiminshedInterestActivities", value);
            }
        },
        signicantWeightLoss: {
            get() {
                return this.$store.getters.getSignficatnWeightLoss;
            },
            set(value) {
                this.$store.dispatch("setSignficatnWeightLoss", value);
            }
        },
        insomnia: {
            get() {
                return this.$store.getters.getInsomnia;
            },
            set(value) {
                this.$store.dispatch("setInsomnia", value);
            }
        },
        psychomotor: {
            get() {
                return this.$store.getters.getPsychomotor;
            },
            set(value) {
                this.$store.dispatch("setPsychomotor", value);
            }
        },
        fatigue: {
            get() {
                return this.$store.getters.getFatigue;
            },
            set(value) {
                this.$store.dispatch("setFatigue", value);
            }
        },
        feelingWorthless: {
            get() {
                return this.$store.getters.getFeelingWorthless;
            },
            set(value) {
                this.$store.dispatch("setFeelingWorthless", value);
            }
        },
        dmininshedConcentration: {
            get() {
                return this.$store.getters.getDimenishedConcentration;
            },
            set(value) {
                this.$store.dispatch("setDimenishedConcentration", value);
            }
        },
        recurrentThoughtOfDeath: {
            get() {
                return this.$store.getters.getRecurrentThoughtOfDeath;
            },
            set(value) {
                this.$store.dispatch("setRecurrentThoughtOfDeath", value);
            }
        },
        mildSeverity: {
            get() {
                return this.$store.getters.getMildSeverity;
            },
            set(value) {
                this.$store.dispatch("setMildSeverity", value);
            }
        },
        moderateSeverity: {
            get() {
                return this.$store.getters.getModerateSeverity;
            },
            set(value) {
                this.$store.dispatch("setModerateSeverity", value);
            }
        },
        severeSevertiy: {
            get() {
                return this.$store.getters.getSevereSeverity;
            },
            set(value) {
                this.$store.dispatch("setSevereSeverity", value);
            }
        },
        hasAnxiety: {
            get() {
                return this.$store.getters.getHasAnxiety;
            },
            set(value) {
                this.$store.dispatch("setHasAnxiety", value);
            }
        }
    },
    watch: {
        anySymptomsError(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    this.$store.dispatch("addDiagnosisError", this.$store.getters.getSymptomsErrorString);
                } else {
                    this.$store.dispatch("removeDiagnosisError", this.$store.getters.getSymptomsErrorString);
                }
            }
        }
    },
    methods: {
        symptomsSeveritySet() {
            this.severityDirty = true;
            this.$store.dispatch("setDiagnosisApproved", false);
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../../../styles/AuthorizeOrders.scss";

    .symptoms-and-split {
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .section-criteria {
        margin-bottom: 10px;
    }

    .dsm5-title {
        margin-top: 45px;
        margin-bottom: 10px;
    }

    .anxiety-title {
        margin-top: 20px;
    }

    .symptom-desc {
        margin-left: 30px;
        font-size: $small-font-size;
    }

    .symptom-desc--disabled {
        color: $border-grey;
    }

    .severity-warning {
        margin-left: 30px;
        font-size: $small-font-size;
        color: $genesight-orange;
    }
</style>
