import CheckGreen from "../assets/check_green_bendy.svg";

export default {
    components: {
        CheckGreen
    },
    props: {
        name: {
            default: "checkbox",
            type: String,
            required: true
        },
        id: {
            default: "myriad-checkbox",
            type: String,
            required: true
        },
        label: {
            default: "",
            type: String,
            required: true
        },
        disabled: {
            default: false,
            type: Boolean,
            required: false
        },
        showUnselected: {
            default: false,
            type: Boolean,
            required: false
        }
    },
    watch: {
        disabled(newValue) {
            if (newValue) {
                this.$emit("input", false);
            }
        }
    },
    computed: {
        myriadCheckboxClass() {
            var className = this.checkboxSelected && !this.showUnselected ? "myriad-checkbox myriad-checkbox--selected" : "myriad-checkbox";
            if (this.disabled) {
                className = className + " myriad-checkbox--disabled";
            }

            return className;
        }
    }
};
