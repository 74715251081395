import { HTTP } from "./http";

const SignatureHttp = {
    updateSignature: (data) => {
        const url = config.url + "/signature";
        return HTTP.post(url, data);
    },
    getFonts: () => {
        const url = config.url + "/GetFonts";
        return HTTP.get(url);
    }
};

export default SignatureHttp;
