export default {
    state: {
        isClinician: false,
        clinicianName: "",
        clinicianNameFirstCommaLast: "",
        clicicianNpi: "",
        signatureFontId: null,
        selectedSignatureFontName: ""
    },
    getters: {
        getIsClinician: state => {
            return state.isClinician;
        },
        getSelectedSignatureFont: state => {
            return state.selectedSignatureFontName;
        },
        getClincianSignaureSet: state => {
            return !(state.selectedSignatureFontName === "" && !state.signatureFontId);
        },
        getClinicianName: state => {
            return state.clinicianName;
        },
        getClinicianNpi: state => {
            return state.clicicianNpi;
        }
    },
    mutations: {
        setClinicianInfo(state, data) {
            state.isClinician = true;
            state.clinicianName = data.clinicianFullName;
            state.clinicianNameFirstCommaLast = data.clinicianNameFirstCommaLast;
            state.clicicianNpi = data.npi;
            state.signatureFontId = data.signatureFontId;
        },
        setNotClinician(state) {
            state.isClinician = false;
        },
        setClincianSignatureFont(state, font) {
            state.selectedSignatureFontName = font.fontName;
        }
    },
    actions: {
        setClinicianInfo(context, data) {
            context.commit("setClinicianInfo", data);
        },
        setNotClinician(context) {
            context.commit("setNotClinician");
        },
        setClincianSignatureFont(context, font) {
            context.commit("setClincianSignatureFont", font);
        }
    }
};
