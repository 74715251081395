import Vue from "vue";
import Vuex from "vuex";
import CreatePersistedState from "vuex-persistedstate";

import SiteStore from "./modules/SiteStore.js";
import UserStore from "./modules/UserStore.js";
import FontsStore from "./modules/SignatureFonts.js";
import ClincianStore from "./modules/ClinicianStore.js";
import AuthorizeOrderStore from "./modules/AuthorizeOrderStore.js";
import Auth2020ValidationStore from "./modules/Auth2020ValidationStore.js";
import MedicationStore from "./modules/MedicationsStore.js";
import AuthorizeOrderValidationStore from "./modules/AuthorizeOrderValidationStore.js";
import DiagCodeStore from "./modules/DiagCodesStore.js";
import ContactStore from "./modules/ContactStore.js";
import NotificationRibbonStore from "./modules/NotificationRibbonStore.js";
import AuthorizePhq9Store from "./modules/AuthorizePhq9Store.js";
import HomeStore from "./modules/HomeStore.js";
import NeuroMultiselectStore from "./modules/NeuroMultiselectStore.js";

Vue.use(Vuex);

const initialState = {
    siteStore: JSON.parse(JSON.stringify(SiteStore.state)),
    userStore: JSON.parse(JSON.stringify(UserStore.state)),
    fontsStore: JSON.parse(JSON.stringify(FontsStore.state)),
    clinicianStore: JSON.parse(JSON.stringify(ClincianStore.state)),
    authorizeOrderStore: JSON.parse(JSON.stringify(AuthorizeOrderStore.state)),
    medicationStore: JSON.parse(JSON.stringify(MedicationStore.state)),
    authorizeOrderValidationStore: JSON.parse(JSON.stringify(AuthorizeOrderValidationStore.state)),
    auth2020ValidationStore: JSON.parse(JSON.stringify(Auth2020ValidationStore.state)),
    diagCodeStore: JSON.parse(JSON.stringify(DiagCodeStore.state)),
    contactStore: JSON.parse(JSON.stringify(ContactStore.state)),
    notificationRibbonStore: JSON.parse(JSON.stringify(NotificationRibbonStore.state)),
    authorizePhq9Store: JSON.parse(JSON.stringify(AuthorizePhq9Store.state)),
    homeStore: JSON.parse(JSON.stringify(HomeStore.state)),
    neuroMultiselectStore: JSON.parse(JSON.stringify(NeuroMultiselectStore.state))
};

export default new Vuex.Store({
    modules: {
        siteStore: SiteStore,
        userStore: UserStore,
        fontsStore: FontsStore,
        clinicianStore: ClincianStore,
        authorizeOrderStore: AuthorizeOrderStore,
        medicationStore: MedicationStore,
        authorizeOrderValidationStore: AuthorizeOrderValidationStore,
        auth2020ValidationStore: Auth2020ValidationStore,
        diagCodeStore: DiagCodeStore,
        contactStore: ContactStore,
        notificationRibbonStore: NotificationRibbonStore,
        authorizePhq9Store: AuthorizePhq9Store,
        homeStore: HomeStore,
        neuroMultiselectStore: NeuroMultiselectStore
    },
    mutations: {
        reset(state) {
            const resetState = initialState;
            Object.keys(state).forEach(key => {
                Object.assign(state[key], resetState[key]);
            });
        }
    },
    actions: {
        reset(context) {
            context.commit("reset");
        }
    },
    plugins: [CreatePersistedState({ storage: window.sessionStorage, paths: ["userStore", "clinicianStore", "contactStore", "authorizeOrderStore"] })]
});
