import { HTTP } from "./http";

const AuthorizationHttp = {
    GetListOfOrdersToAuthorize: () => {
        const url = config.url + "/GetListOfOrdersToAuthorize";
        return HTTP.get(url);
    },
    GetDiagnosticCodeAutocompleteInfo: () => {
        const url = config.url + "/GetDiagnosticCodeAutocompleteInfo";
        return HTTP.get(url);
    },
    GetDataToAuthorizeOrder: (data) => {
        const url = config.url + "/GetDataToAuthorizeOrder";
        return HTTP.post(url, data);
    },
    AuthorizeOrder: (data) => {
        const url = config.url + "/AuthorizeOrder";
        return HTTP.post(url, data);
    },
    SearchDiagnosticCodeAutocomplete: (productId, isMedicare, searchTerm) => {
        const url = config.url + "/SearchDiagnosticCodeAutocomplete?productId=" + productId + "&isMedicare=" + isMedicare + "&term=" + searchTerm;
        return HTTP.get(url);
    },
    GetMedicationLists: () => {
        const url = config.url + "/GetMedicationLists";
        return HTTP.get(url);
    },
    GetTreatmentMedications: () => {
        const url = config.url + "/GetTreatmentMedicationLists";
        return HTTP.get(url);
    }
};

export default AuthorizationHttp;
