<template>
    <button :class="neuroButtonClass" :type="type" @click="inputEvent()">
        <img v-if="imageName" class="neuroButtonImage" :src="require('../assets/'+imageName)">
        {{ text }}
        <div class="loader-container">
            <loader v-if="showLoader" class="button-loader" />
        </div>
    </button>
</template>

<script>
import Loader from "../assets/button-loader.svg";

export default {
    name: "NeuroButton",
    components: {
        Loader
    },
    props: {
        text: {
            type: String,
            required: true,
            default: ""
        },
        filled: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: String,
            required: false,
            default: "blue"
        },
        imageName: {
            type: String,
            required: false,
            default: null
        },
        type: {
            type: String,
            required: false,
            default: "button"
        },
        showLoader: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            requried: false,
            default: false
        }
    },
    data() {
        return {
            buttonDisabled: false
        };
    },
    computed: {
        neuroButtonClass: function() {
            let className = "neuro-button";
            if (this.filled) {
                className += " neuro-button--filled";
            }

            if (this.disabled) {
                className += " neuro-button--disabled";
            } else {
                if (this.color === "green") {
                    className += " neuro-button--green";
                } else if (this.color === "orange") {
                    className += " neuro-button--orange";
                }
            }

            return className;
        }
    },
    methods: {
        inputEvent() {
            if (!this.buttonDisabled && !this.disabled && !this.showLoader) {
                this.buttonDisabled = true;
                this.$emit("neuro-btn-click");
                window.setTimeout(() => {
                    this.buttonDisabled = false;
                }, 1000);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .neuro-button {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 40px;
        color: $genesight-blue;
        font-size: 1em;
        line-height: 19px;
        background: transparent;
        border: 1px solid $genesight-blue;
        border-radius: 5px;
        box-sizing: border-box;
        outline: none;

        &:hover {
            cursor: pointer;
        }
    }

    .neuro-button--green {
        color: $genesight-green;
        border: 1px solid $genesight-green;
    }

    .neuro-button--orange {
        color: $genesight-orange;
        border: 1px solid $genesight-orange;
    }

    .neuro-button--filled {
        color: white;
        background: $genesight-blue;
    }

    .neuro-button--filled.neuro-button--green {
        background: $genesight-green;
    }

    .neuro-button--filled.neuro-button--orange {
        background: $genesight-orange;
    }

    .neuro-button--disabled {
        @include error-btn-coloring();
        border: 1px solid $error-btn-bg;
        pointer-events: none;
    }

    .neuroButtonImage {
        width: 19px;
        height: 19px;
        margin-right: 5px;
    }

    .loader-container {
        position: absolute;
        top: 0;
        right: 20px;
        @include flex-center-vertical();
        height: 100%;
    }

    .button-loader {
        width: auto;
        height: 60%;
    }
</style>
