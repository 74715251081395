<template>
    <div class="patient-information">
        <error-wrapper
            :warning-exists="patientInformationError"
            :warning-message="requiredMessage"
            :warn-at-start="true"
            :is-dirty="false"
        >
            <h6 class="section-header">
                Patient Information
            </h6>
            <p class="section-gray-text name-dob-label">
                Patient Name
            </p>
            <div v-if="hasNameMismatchError">
                <div>
                    <input
                        id="patient-name-mismatch"
                        type="radio"
                        name="correct-name"
                        :value="$store.getters.getCurrentOrderPatientEnteredName"
                        checked="true"
                        @input="setPatientName($event.target.value)"
                    >
                    <label class="section-text" for="patient-name-mismatch">
                        {{ $store.getters.getCurrentOrderPatientEnteredName }}
                    </label>
                    <span class="section-gray-text section-gray-text--small">
                        (patient provided)
                    </span>
                </div>
                <div>
                    <input
                        id="name-mismatch"
                        type="radio"
                        name="correct-name"
                        :value="initialPatientName"
                        @input="setPatientName($event.target.value)"
                    >
                    <label class="section-text" for="name-mismatch">
                        {{ initialPatientName }}
                    </label>
                </div>
            </div>
            <div v-else>
                <p class="patient-info-content">
                    {{ $store.getters.getCurrentOrderPatientName }}
                </p>
            </div>
            <p class="section-gray-text name-dob-label">
                Date of Birth
            </p>
            <div v-if="hasDobMismatchError">
                <div>
                    <input
                        id="patient-dob-mismatch"
                        type="radio"
                        name="correct-dob"
                        :value="$store.getters.getCurrentOrderPatientEnteredDob"
                        checked="true"
                        @input="setPatientDob($event.target.value)"
                    >
                    <label class="section-text" for="patient-dob-mismatch">
                        {{ $store.getters.getCurrentOrderPatientEnteredDob }}
                    </label>
                    <span class="section-gray-text section-gray-text--small">
                        (patient provided)
                    </span>
                </div>
                <div>
                    <input
                        id="dob-mismatch"
                        type="radio"
                        name="correct-dob"
                        :value="initialPatientDob"
                        @input="setPatientDob($event.target.value)"
                    >
                    <label class="section-text" for="dob-mismatch">
                        {{ initialPatientDob }}
                    </label>
                </div>
            </div>
            <div v-else>
                <p class="patient-info-content patient-info-content--nbm">
                    {{ $store.getters.getCurrentOrderPatientDob }}
                </p>
            </div>
        </error-wrapper>
    </div>
</template>

<script>
import ErrorWrapper from "./ErrorWrapper.vue";

export default {
    name: "PatientInformation",
    components: {
        ErrorWrapper
    },
    props: {
        initialPatientName: {
            type: String,
            required: true
        },
        initialPatientDob: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            requiredMessage: "The name and/or date of birth that the patient provided differs from those provided when the order was initiated.<br><br>Please ensure that the correct information is selected and that the <b>Cheek Swab Envelope</b> matches these values"
        };
    },
    computed: {
        patientInformationError() {
            return this.$store.getters.getCurrentOrderIsPatientEntered && this.$store.getters.getCurrentOrderIsNotPatientMatched;
        },
        hasNameMismatchError() {
            return this.patientInformationError && this.$store.getters.getCurrentOrderHasMismatchedName;
        },
        hasDobMismatchError() {
            return this.patientInformationError && this.$store.getters.getCurrentOrderHasMismatchedDob;
        }
    },
    methods: {
        setPatientName(value) {
            this.$store.dispatch("setPatientInformationApproved", false);
            this.$store.dispatch("setCurrentOrderPatientName", value);
        },
        setPatientDob(value) {
            this.$store.dispatch("setPatientInformationApproved", false);
            this.$store.dispatch("setCurrentOrderPatientDob", value);
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../../styles/AuthorizeOrders.scss";

    .patient-information {
        padding: 0 7px 15px 7px;
    }

    .patient-information .error-highlight {
        margin-top: 0;

        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .patient-information .section-header {
        margin-top: 10px;
    }

    .patient-info-content {
        font-size: 1em;
        margin-bottom: 15px;
    }

    .patient-info-content--nbm {
        margin-bottom: 0;
    }

    .name-dob-label {
        font-size: 0.875em;
    }
</style>
