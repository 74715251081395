import Vue from "vue";
import App from "./App.vue";
import router from "./router/router.js";
import store from "./store/store.js";
import MyriadMultiselect from "@myriad-neuroscience/vue-multiselect";
import MyriadDateMask from "@myriad-neuroscience/vue-input-masks";
import Vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";
import AsyncComputed from "vue-async-computed";
import ErrorLogger from "@myriad-neuroscience/common-js";

Vue.use(MyriadMultiselect);
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(MyriadDateMask);
Vue.use(AsyncComputed);

ErrorLogger.startErrorDetectionListeners(Vue, window, config.url + "/ErrorLog", "Mobile");

new Vue({ //eslint-disable-line
    el: "#app",
    store,
    router,
    components: { App },
    template: "<App/>"
});
