<template>
    <div class="all-done-page">
        <div class="all-done-header">
            <img class="all-done-header-img" src="../../assets/circle_check.png">
            <h5 class="all-done-header-text">
                Thank you!
            </h5>
            <p class="no-orders-notice">
                All orders have been authorized.
            </p>
        </div>
        <div class="all-done-cheek-swab">
            <img class="all-done-cheek-swab-img" src="../../assets/cheek_swab_envelope.png">
            <p class="all-done-cheek-swab-text">
                Dont forget to <b>sign and date</b> the Cheek Swab Envelope
            </p>
        </div>
        <neuro-button
            class="go-home-btn"
            text="RETURN HOME"
            :filled="true"
            @neuro-btn-click="$router.push('/')"
        />
    </div>
</template>

<script>
import NeuroButton from "../NeuroButton.vue";

export default {
    name: "AllDone",
    components: {
        NeuroButton
    }
};
</script>

<style lang="scss" scoped>
    $image-height: 75px;
    $step-header-height: 46px;

    .all-done-page {
        padding-left: 10px;
        padding-right: 10px;
    }

    .all-done-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .all-done-header-text {
        margin-top: 12px;
        color: $genesight-green;
        font-size: $header-font-size;
        font-weight: 700;
    }

    .no-orders-notice {
        margin-top: 5px;
        font-size: $regular-copy-size;
        color: $sub-section-gray-text;
    }

    .all-done-cheek-swab {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 35px;
        padding: 14px 10px;
        border: 2px solid #d3d3d3;
        border-radius: 5px;
    }

    .all-done-cheek-swab-img {
        margin-right: 10px;
        max-width: 60px;
        min-width: 60px;
        max-height: 60px;
        min-height: 60px;
    }

    .all-done-cheek-swab-text {
        font-size: 1em;
        line-height: 1.33em;
    }

    .go-home-btn {
        margin: 35px auto;
        min-width: 220px;
    }
</style>
