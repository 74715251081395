<template>
    <div class="log-on">
        <div class="logo-container">
            <GenesightLogo />
        </div>
        <transition name="fade" mode="out-in">
            <div v-if="!showForgotPassword && !showResetLinkSent" class="sign-on-container">
                <form class="sign-on" @submit="submitLogin">
                    <div>
                        <h3 class="sign-on-header">
                            Clinic sign in
                        </h3>
                        <transition name="grow">
                            <div v-if="validationErrors" class="log-on-validation-message">
                                Email Address and password required
                            </div>
                        </transition>
                        <labeled-input
                            ref="emailInput"
                            v-model="$v.email.$model"
                            :class="emailInputClass"
                            class-string="sign-on-input sign-on-email-input"
                            placeholder="Email"
                        />
                        <labeled-input
                            ref="passwordInput"
                            v-model="$v.password.$model"
                            :class="passwordInputClass"
                            class-string="sign-on-input"
                            placeholder="Password"
                            input-type="password"
                        />
                        <div class="under-sign-in-row">
                            <div class="remember-me-row">
                                <input id="sign-on-remember-me" v-model="shouldRememberPassword" type="checkbox" class="sign-on-checkbox">
                                <label for="sign-on-remember-me" class="sign-on-label">
                                    Remember me
                                </label>
                            </div>
                            <a class="mobile-link" @click="showForgotPassword=true">
                                Forgot password?
                            </a>
                        </div>
                        <neuro-button class="sign-on-submit" text="Sign In" :filled="true" type="submit" />
                    </div>
                </form>
            </div>
            <forgot-password
                v-if="showForgotPassword && !shouldResetLoginPage"
                @forgot-password-submit="forgotPasswordSubmit"
            />
            <reset-link-sent
                v-if="showResetLinkSent && !shouldResetLoginPage"
                @sign-in-click="showResetLinkSent=false"
            />
        </transition>
    </div>
</template>

<script>
import GenesightLogo from "../assets/genesight.svg";
import NeuroButton from "../components/NeuroButton.vue";
import LabeledInput from "../components/LabeledInput.vue";
import ForgotPassword from "../components/LogOn/ForgotPassword.vue";
import ResetLinkSent from "../components/LogOn/ResetLinkSent.vue";

import Common from "../scripts/common.js";
import LogonHTTP from "../scripts/http/logon-http.js";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
    name: "LogOn",
    components: {
        GenesightLogo,
        NeuroButton,
        LabeledInput,
        ForgotPassword,
        ResetLinkSent
    },
    data() {
        return {
            email: "",
            password: "",
            shouldRememberPassword: false,
            showForgotPassword: false,
            showResetLinkSent: false
        };
    },
    validations: {
        email: {
            required,
            maxLength: maxLength(100)
        },
        password: {
            required,
            maxLength: maxLength(100)
        }
    },
    computed: {
        validationErrors() {
            const emailValidationErrors = this.$v.email.$error && this.$v.email.$dirty;
            const passwordValidationErrors = this.$v.password.$error && this.$v.password.$dirty;

            return emailValidationErrors || passwordValidationErrors;
        },
        shouldResetLoginPage() {
            return this.$store.getters.getResetLoginPage;
        },
        emailInputClass() {
            return this.$v.email.$invalid && this.$v.email.$dirty ? "email-error" : "";
        },
        passwordInputClass() {
            return this.$v.password.$invalid && this.$v.password.$dirty ? "password-error" : "";
        }
    },
    watch: {
        shouldResetLoginPage(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    this.showForgotPassword = false;
                    this.showResetLinkSent = false;
                }
            }
        }
    },
    mounted() {
        var cookie = Common.getCookie("Username");
        if (!Common.isEmptyOrNull(cookie)) {
            this.email = cookie;
            this.shouldRememberPassword = true;
            this.$nextTick(() => {
                this.$refs.emailInput.updateValue();
            });
        }
        this.$store.dispatch("setShowContact", true);
        this.$store.dispatch("setShowHome", false);
    },
    methods: {
        submitLogin(event) {
            event.preventDefault();

            if (!this.$v.$invalid) {
                const userData = { Email: this.email, Password: this.password, RememberMe: this.shouldRememberPassword };
                this.$store.dispatch("showFullScreenLoader");
                LogonHTTP.logon(userData).then((data) => {
                    this.$store.dispatch("setLoggedIn");
                    this.$store.dispatch("setAgreementIds", data.data.agreementIdsToShow);
                    this.$store.dispatch("setPermissions", data.data.permissions);

                    if (!Common.isEmptyOrNull(data.data.clinician)) {
                        this.$store.dispatch("setClinicianInfo", data.data.clinician);
                    } else {
                        this.$store.dispatch("setNotClinician");
                    }

                    this.$router.push("/");
                }).catch(() => {
                    this.$store.dispatch("showNotificationRibbon", "Sign in was unsuccessful");
                }).finally(() => {
                    this.$store.dispatch("hideFullScreenLoader");
                });
            } else {
                this.$v.$touch();
            }
        },
        forgotPasswordSubmit(fgEmail) {
            const userData = { Email: fgEmail };

            LogonHTTP.recoverPassword(userData).finally(() => {
                this.showForgotPassword = false;
                this.showResetLinkSent = true;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .log-on {
        width: 100%;
        min-height: 390px;
        padding-top: 40px;
    }

    .logo-container {
        margin: 0 auto 20px;
        width: $sign-on-box-width;
    }

    .sign-on-container {
        width: 100%;
    }

    .sign-on {
        width: $sign-on-box-width;
        margin: 0 auto;
        padding: 25px 15px;
        box-sizing: border-box;
        border: 1px solid #EBEBEB;
        border-radius: 5px;
    }

    .sign-on-email-input {
        margin-top: 20px;
    }

    .sign-on-header {
        margin-bottom: 20px;
        text-align: center;
        color: #19735C;
        font-weight: 700;
    }

    ::v-deep .email-error .sign-on-input,
    ::v-deep .password-error .sign-on-input {
        border-color: $genesight-red;
    }

    ::v-deep .log-on-validation-message {
        margin-bottom: 20px;
        max-height: 20px;
        color: $error-red;
        font-size: $regular-copy-size;
        text-align: center;
    }

    .under-sign-in-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .remember-me-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .sign-on-checkbox {
        margin-left: 0;
    }

    ::v-deep .sign-on-submit {
        width: 100%;
        height: 40px;
    }
</style>
