<template>
    <div class="search-results" :style="'min-height:' + componentMinHeight + 'px' ">
        <div v-if="searchResultsData.length <= 0" class="no-results">
            <p class="no-results-text">
                <b>No matches were found.</b>
            </p>
            <p class="no-results-text">
                You can search by entering the patient's name, date of birth, or order number.
            </p>
            <p class="no-results-text">
                If this seems incorrect, please contact GeneSight Customer Service at
                <a class="mobile-link" :href="'tel:' + $store.getters.getCsPhone">
                    {{ $store.getters.getCsPhone }}
                </a>
                or
                <!-- eslint-disable-next-line vue/singleline-html-element-content-newline-->
                <a class="mobile-link" :href="'mailto:'+$store.getters.getCsEmail">{{ $store.getters.getCsEmail }}</a>.
            </p>
        </div>
        <div v-else class="search-results-list">
            <div
                v-for="(item, index) in searchResultsData"
                :key="index"
                class="result-item" @click="goToViewReport(item.cell[7], item.id)"
            >
                <div class="status-bubble">
                    <div :class="getStatusDotClass(item.cell[1])" />
                </div>
                <div class="patient-info-column">
                    <p :class="getResultNameClass(item.cell[1])">
                        {{ getDecodedName(item.cell[2]) }}
                    </p>
                    <p class="dob-text">
                        DOB: {{ item.cell[3] }}
                    </p>
                </div>
                <a :class="getStatusClass(item.cell[7], item.cell[2])">
                    <p class="status-link">
                        {{ item.cell[7] }}
                    </p>
                    <div v-if="!shouldHideRightChev(item.cell[7])" class="right-chev" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import ContactHttp from "../scripts/http/contact-http.js";
import Common from "../scripts/common.js";

export default {
    name: "SearchResults",
    props: {
        searchResultsData: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            componentMinHeight: 40
        };
    },
    created() {
        if (Common.isEmptyOrNull(this.$store.getters.getCsPhone)) {
            ContactHttp.getContactInfo().then((data) => {
                this.$store.dispatch("setContactInfo", data);
            }).catch(() => {
                this.$store.dispatch("showDefaultNotificationRibbon");
            });
        }
    },
    mounted() {
        this.calculcateMinHeight();
    },
    methods: {
        calculcateMinHeight() {
            if (this.searchResultsData.length > 0) {
                const searchBar = document.querySelector(".search-bar");
                const space = window.innerHeight - searchBar.offsetTop - searchBar.offsetHeight;
                this.componentMinHeight = space;
            } else {
                this.componentMinHeight = 0;
            }
        },
        goToViewReport(statusValue, orderId) {
            if ((statusValue === "View Report" || statusValue === "View Dashboard") && this.$store.getters.getCanViewReports) {
                this.$router.push({ name: "Reports", params: { orderId: orderId } });
            }
        },
        getStatusDotClass(value) {
            let className = "status-bubble-dot ";

            if (value === "Needs Work") {
                className = className + "status-bubble-red-dot";
            } else if (value === "New") {
                className = className + "status-bubble-blue-dot";
            }

            return className;
        },
        getStatusClass(value) {
            let className = "report-status-link";

            if (value === "On Hold") {
                className = className + " report-status-link-hold";
            } else if ((value === "View Report" || value === "View Dashboard") && this.$store.getters.getCanViewReports) {
                className = className + " report-status-link-view";
            }

            return className;
        },
        getResultNameClass(value) {
            let className = "result-name";

            if (value === "New") {
                className = className + " result-name-bold";
            }

            return className;
        },
        shouldHideRightChev(value) {
            return value.includes("Pending") || value.includes("Incomplete") || value.includes("Cancelled") || !this.$store.getters.getCanViewReports;
        },
        getDecodedName(stringValue) {
            return Common.decodeEntities(stringValue);
        }
    }
};
</script>

<style lang="scss" scoped>
    .search-results {
        position: absolute;
        left: 0;
        width: 100%;
        height: auto;
        padding-top: 10px;
        background: white;
        z-index: 10;
        overflow: hidden;
    }

    .result-item {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 50px;
        border-top: 1px solid $border-grey;
    }

    .result-item:last-of-type {
        border-bottom: 1px solid $border-grey;
    }

    .status-bubble {
        @include flex-center-vertical();
        width: 50px;
        height: 100%;
    }

    .status-bubble-dot {
        width: 10px;
        height: 10px;
        border-radius: 10px;
    }

    .status-bubble-red-dot {
        background: $genesight-red;
    }

    .status-bubble-blue-dot {
        background: $genesight-blue;
    }

    .result-name {
        font-size: $regular-copy-size;
        white-space: nowrap;
    }

    .dob-text {
        color: $light-gray-text;
        font-size: $small-font-size;
    }

    .report-status-link {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        padding-right: 15px;
        text-decoration: none;
    }

    .report-status-link-view {
        color: $genesight-blue;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .report-status-link-view .status-link {
        color: $genesight-blue;
    }

    .report-status-link-hold .status-link {
        color: $genesight-red;
    }

    .status-link {
        margin-right: 10px;
        font-size: $small-font-size;
    }

    .right-chev {
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #aaa;
    }

    .patient-info-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .result-name-bold {
        font-weight: bold;
    }

    .no-results {
        padding: 30px 15px;
    }

    .no-results-text {
        line-height: 24px;
        margin-bottom: 20px;
    }
</style>
