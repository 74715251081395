import { HTTP } from "./http";

const ReportListHttp = {
    getReports: (orderId) => {
        const url = config.url + "/Reports?orderId=" + orderId;
        return HTTP.get(url);
    },
    viewReport: (orderLineItemId) => {
        const url = config.url + "/ViewReport?orderLineItemId=" + orderLineItemId;
        return HTTP.get(url, { responseType: "arraybuffer" });
    }
};

export default ReportListHttp;
