const DepressionSeverity = {
    MILD: 1,
    MODERATE: 2,
    SEVERE: 4,
    ALL: 7
};

const DepressionSeverityStrings = {
    MILD: "Mild",
    MODERATE: "Moderate",
    SEVERE: "Severe",
    ALL: "All"
};

const ClinicianAuth = {
    PATIENT_INFORMATION: 0,
    DIAGNOSIS: 1,
    MEDICALNECESSITY: 2
};

const Phq9Map = {
    NotAtAll: {
        id: 0,
        value: "Not at all"
    },
    SeveralDays: {
        id: 1,
        value: "Several days"
    },
    MoreThanHalf: {
        id: 2,
        value: "More than half the days"
    },
    NearlyEvery: {
        id: 3,
        value: "Nearly every day"
    }
};

const BlackGrayWarningsEnum = {
    NONE: 1,
    WARNING: 2,
    ERROR: 3
};

const TestTypes = {
    MULTI_GENE: 1,
    SINGLE_GENE: 2,
    ZERO_GENE: 3
};

module.exports = {
    DepressionSeverity,
    DepressionSeverityStrings,
    ClinicianAuth,
    Phq9Map,
    BlackGrayWarningsEnum,
    TestTypes
};
