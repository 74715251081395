import { HTTP } from "./http";

const LogonHttp = {
    logon: (data) => {
        const url = config.url + "/LogIn";
        return HTTP.post(url, data);
    },
    recoverPassword: (data) => {
        const url = config.url + "/RecoverPassword";
        return HTTP.post(url, data);
    },
    logout: () => {
        const url = config.url + "/LogOut";
        return HTTP.post(url);
    }
};

export default LogonHttp;
