export default {
    state: {
        phq9Date: "",
        phq9Score: "",
        littleInterest: "",
        feelingDepressed: "",
        troubleSleeping: "",
        littleEnergy: "",
        poorAppetite: "",
        feelLikeAFailure: "",
        troubleConcentrating: "",
        lethargicOrRestless: "",
        suicidalThoughts: ""
    },
    getters: {
        getPhq9Date: state => {
            return state.phq9Date;
        },
        getPhq9Score: state => {
            return state.phq9Score;
        },
        getLittleInterest: state => {
            return state.littleInterest;
        },
        getFeelingDepressed: state => {
            return state.feelingDepressed;
        },
        getTroubleSleeping: state => {
            return state.troubleSleeping;
        },
        getLittleEnergy: state => {
            return state.littleEnergy;
        },
        getPoorAppetite: state => {
            return state.poorAppetite;
        },
        getFeelLikeAFailure: state => {
            return state.feelLikeAFailure;
        },
        getTroubleConcentrating: state => {
            return state.troubleConcentrating;
        },
        getLethargicOrRestless: state => {
            return state.lethargicOrRestless;
        },
        getSuicidalThoughts: state => {
            return state.suicidalThoughts;
        }
    },
    mutations: {
        setPhq9Data(state, payload) {
            state.phq9Date = payload.phQ9Date;
            state.phq9Score = payload.phQ9Score;
            state.littleInterest = payload.phQ9.littleInterest;
            state.feelingDepressed = payload.phQ9.feelingDepressed;
            state.troubleSleeping = payload.phQ9.troubleSleeping;
            state.littleEnergy = payload.phQ9.littleEnergy;
            state.poorAppetite = payload.phQ9.poorAppetite;
            state.feelLikeAFailure = payload.phQ9.feelLikeAFailure;
            state.troubleConcentrating = payload.phQ9.troubleConcentrating;
            state.lethargicOrRestless = payload.phQ9.lethargicOrRestless;
            state.suicidalThoughts = payload.phQ9.suicidalThoughts;
        }
    },
    actions: {
        setPhq9Data(context, payload) {
            context.commit("setPhq9Data", payload);
        }
    }
};
