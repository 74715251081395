<template>
    <div class="error-bg">
        <div class="error-page">
            <h2 class="error-header">
                Oops!
            </h2>
            <h6 class="error-sub-header">
                We're sorry. This was certainly unexpected.
            </h6>
            <div>
                <p class="error-notice">
                    An error has occurred and we're doing our best to resolve the problem as quickly as possible.
                </p>
                <p class="error-text">
                    In the meantime, here is what we recommend:
                </p>
                <div class="error-row">
                    <img class="error-img" src="../../assets/refresh.png">
                    <p class="error-text">
                        <b>Refresh the page</b> (sometimes this helps)
                    </p>
                </div>
                <div class="error-row">
                    <img class="error-img" src="../../assets/clock.png">
                    <p class="error-text">
                        <b>Try again later</b> (30 minutes or so)
                    </p>
                </div>
                <div class="error-row">
                    <img class="error-img" src="../../assets/envelope.png">
                    <p class="error-text">
                        <b>Contact Customer Service</b> at <a class="no-underline" href="mailto:support@genesight.com">
                            support@genesight.com
                        </a> or <a class="no-underline" href="tel:888.757.9204">
                            888.757.9204
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErrorPage",
    computed: {
    }
};
</script>

<style lang="scss" scoped>
    .error-bg {
        min-height: 700px;
    }

    .error-page {
        padding: 20px 30px 65px 30px;
        max-width: 900px;
    }

    .error-header {
        margin-bottom: 20px;
        font-size: 2em;
    }

    .error-sub-header {
        margin-bottom: 25px;
        font-size: 1.0em;
    }

    .error-notice {
        margin-bottom: 25px;
        font-size: 0.75em;
    }

    .error-text {
        margin-bottom: 23px;
        font-size: 0.75em;
        line-height: 1.25em;
    }

    .error-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 23px;
    }

    .error-row .error-text {
        margin-bottom: 0;
    }

    .error-img {
        min-width: 15px;
        margin-right: 8px;
    }

    .no-underline {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
</style>
