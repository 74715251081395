 <template>
    <div class="footer">
        <div class="footer-nav" :class="{'footer-nav--full': showFullFooterNav}">
            <router-link v-if="$store.getters.getShowHome || showFullFooterNav" to="/" class="footer-nav-link">
                Home
            </router-link>
            <router-link v-if="showFullFooterNav && $store.getters.getCanOrderTests" class="footer-nav-link" to="/NewOrder">
                New Order
            </router-link>
            <router-link v-if="showFullFooterNav && $store.getters.getIsClinician" class="footer-nav-link" to="/AuthorizeOrders">
                Authorize Orders{{ numOrdersToAuth }}
            </router-link>
            <router-link v-if="$store.getters.getShowContact || showFullFooterNav" class="footer-nav-link" to="/Contact">
                Contact
            </router-link>
            <router-link v-if="showFullFooterNav" class="footer-nav-link" to="/LogOn">
                Sign Out
            </router-link>
        </div>
        <img class="gradient-line" src="../assets/horizontal-gradient-line.png">
        <div  class="footer-content">
            <router-link v-if="showFullFooterNav" :to="{ name: 'Privacy', params: { noticeId: 'privacy_notice' } }" class="footer-privacy-link">
                Privacy Policy
            </router-link>
            <router-link v-if="showFullFooterNav" :to="{ name: 'Privacy', params: { noticeId: 'npp' } }" class="footer-privacy-link">
                Notice of Privacy Practices
            </router-link>
            <a v-if="showFullFooterNav" :href="spanishPrivacyLinkLocation" target="_blank" class="footer-privacy-link">
                Notice of Privacy Practices (Spanish)
            </a>
            <router-link v-if="showFullFooterNav" :to="{ name: 'Privacy', params: { noticeId: 'mygenesight_terms' } }" class="footer-privacy-link">
                Terms of Use
            </router-link>
            <p class="footer-notice">
                &#169;{{ getCurrentYear }} Myriad Genetics, Inc./Assurex Health, Inc. (doing business as Myriad Neuroscience).<br /> Myriad Genetics, GeneSight, and associated logos are registered trademarks of Myriad Genetics, Inc. and its subsidiaries. All Rights Reserved.
            </p>
        </div>
    </div>
</template>

<script>
import PrivacyHttp from "../scripts/http/privacy-http.js";
import Common from "../scripts/common.js";

export default {
    name: "Footer",
    data() {
        return {
            spanishPrivacyLinkLocation: ""
        };
    },
    computed: {
        showFullFooterNav() {
            return Common.showNavBar();
        },
        numOrdersToAuth() {
            return Common.countAsStringInParentheses(this.$store.getters.getNumberOfOrdersToAuthorize);
        },
        getCurrentYear() {
            return new Date().getFullYear();
        }
    },
    created() {
        this.getSpanishNoticeOfPrivacyPractices();
    },
    methods: {
        getSpanishNoticeOfPrivacyPractices() {
            PrivacyHttp.GetNotice("npp").then((response) => {
                this.$data.spanishPrivacyLinkLocation = response.data.spanish_download_link;
            }).catch(() => {
                this.$store.dispatch("showDefaultNotificationRibbon");
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.footer {
    margin-top: 50px;
}

.footer-nav {
    @include flex-center-vertical();
    margin: 0 15px 20px 15px;
    padding-top: 5px;
    border-top: $border-grey 1px solid;
    height: calc(100vh - 610px);
    justify-content: flex-start;
    min-height: 100px;
}

.footer-nav--full {
    min-height: 230px;
}

.footer-nav-link {
    padding-top: 15px;
    padding-bottom: 15px;
    color: #00A8E9;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.pattern-bg {
    width: 100%;
    height: 102px;
    background-image: url("../assets/bg_patern.png");
    background-repeat: repeat-x;
}

.footer-content {
    @include flex-center-vertical();
    width: 100%;
    padding: 20px 0;
    background: white;
}

.footer-privacy-link {
    padding: 9px 0;
    color: #757575;
    text-decoration: none;
    font-family: Arial;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.footer-notice {
    width: 86%;
    padding: 9px 0;
    color: #757575;
    text-decoration: none;
    font-family: Arial;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
}

.gradient-line {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
</style>
