<template>
    <div :class="wrapperClass">
        <slot />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-if="showErrorMessage" :class="messageClass" v-html="currentErrorMessageContents" />
    </div>
</template>

<script>
export default {
    name: "ErrorWrapperAuth",
    props: {
        errorExists: {
            required: false,
            type: Boolean,
            default: false
        },
        warningExists: {
            required: false,
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            required: false,
            default: ""
        },
        warningMessage: {
            type: String,
            required: false,
            default: ""
        },
        noBackground: {
            type: Boolean,
            required: false,
            default: false
        },
        isDirty: {
            type: Boolean,
            requried: false,
            default: true
        },
        warnAtStart: {
            type: Boolean,
            required: false,
            default: false
        },
        showWarnOverError: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        currentErrorMessageContents() {
            if ((this.isDirty || this.warnAtStart) && this.errorExists) {
                return this.errorMessage;
            } else if (this.warningExists) {
                return this.warningMessage;
            } else {
                return "";
            }
        },
        showErrorMessage() {
            const shouldShowError = this.errorExists && (this.isDirty || this.warnAtStart);
            const shouldShowWarning = this.warningExists;
            return shouldShowError || shouldShowWarning;
        },
        wrapperClass() {
            let className = "error-wrapper-auth";

            if (this.isDirty && this.errorExists && this.warningExists && this.showWarnOverError) {
                className = className + " error-highlight";
                className = className + " error-highlight--orange";
            } else if ((this.isDirty || (this.warnAtStart && !this.warningExists)) && this.errorExists && !this.noBackground) {
                className = className + " error-highlight";

                if (this.warnAtStart && !this.isDirty) {
                    className = className + " error-highlight--yellow";
                } else {
                    className = className + " error-highlight--red";
                }
            } else if (this.warningExists & !this.noBackground) {
                className = className + " error-highlight";
                className = className + " error-highlight--orange";
            }

            return className;
        },
        messageClass() {
            let className = "error-message";

            if (this.warnAtStart && !this.isDirty && !this.warningExists) {
                className = className + " error-message--yellow";
            } else if (this.isDirty && this.errorExists && this.warningExists && this.showWarnOverError) {
                className = className + " error-message--orange";
            } else if (this.isDirty && this.errorExists) {
                className = className + " error-message--red";
            } else if (this.warningExists) {
                className = className + " error-message--orange";
            }

            if (this.noBackground) {
                className = className + " error-message--noPad";
            }

            return className;
        }
    }
};
</script>

<style lang="scss" scoped>
    .error-highlight {
        margin-top: 10px;
        padding-left: $auth-section-side-padding;
        padding-right: $auth-section-side-padding;
        padding-top: $auth-section-side-padding;
    }

    .error-highlight--red {
        background: $bg-error-red;
    }

    .error-highlight--orange {
        background: $bg-warning-orange;
    }

    .error-highlight--yellow {
        background: $bg-warning-yellow;
    }

    .error-message {
        margin-top: 17px;
        margin-left: -$auth-section-side-padding;
        margin-right: -$auth-section-side-padding;
        padding-left: $auth-section-side-padding;
        padding-right: $auth-section-side-padding;
        padding-top: $auth-section-side-padding;
        padding-bottom: $auth-section-side-padding;
        text-align: center;
        color: white;
        font-size: $regular-copy-size;
    }

    .error-message-extra {
        margin-top: 0;
        padding: $auth-section-side-padding;
        text-align: center;
        color: white;
        font-size: $small-font-size;
        font-weight: 700;
    }

    .error-message--red {
        background: $error-red;
    }

    .error-message--orange {
        background: $genesight-orange;
    }

    .error-message--yellow {
        background: $genesight-yellow;
        color: $text-color;
    }

    .error-message--noPad {
        margin: 1px 0 0 0;
    }
</style>
