import { HTTP } from "./http";

const ResetPwHttp = {
    validateToken: (token) => {
        const url = config.url + "/SetPassword" + "?token=" + token;
        return HTTP.get(url);
    },
    updatePassword: (data) => {
        const url = config.url + "/SetPassword";
        return HTTP.post(url, data);
    }
};

export default ResetPwHttp;
