import { HTTP } from "./http";

const HomeHttp = {
    getSearchResults: (data) => {
        const url = config.url + "/OrderSearch";
        return HTTP.post(url, data);
    }
};

export default HomeHttp;
