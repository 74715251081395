<template>
    <div class="">
        <h1 class="">
            Oops an error occurred
        </h1>
    </div>
</template>

<script>

export default {
    name: "Error",
    components: {
    },
    data() {
        return {
        };
    }
};
</script>

<style lang="scss" scoped>

</style>
