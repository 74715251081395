<template>
    <div>
        <h6 class="section-header">
            Psychotropic Medical Necessity
        </h6>
        <p class="section-text">
            Has the patient failed or are they currently failing at least one neuropsychiatric medication?
        </p>
        <error-wrapper
            :warning-exists="$store.getters.getCurrentOrderIsMedicare && !patientFailedMeds"
            warning-message="The patient must have failed or be currently failing at least one neuropsychiatric medication for this order to meet Medicare coverage."
        >
            <div class="section-check-row section-check-row--failingMeds">
                <myriad-radio
                    id="current-fail-yes"
                    v-model="patientFailedMeds"
                    name="isCurrentFailingMed"
                    label="Yes"
                    class="section-radio"
                    :model-property="patientFailedMeds"
                    @radio-selection="currentFailedMedsSelected"
                />
                <myriad-radio
                    id="current-fail-no"
                    v-model="patientFailedMeds"
                    name="isCurrentFailingMed"
                    label="No"
                    class="section-radio"
                    :false-checkbox="true"
                    :model-property="patientFailedMeds"
                    @radio-selection="$store.dispatch('setMedicalNecessityApproved', false);"
                />
            </div>
        </error-wrapper>
        <div v-if="patientFailedMeds" class="meds-section">
            <p class="section-gray-text">
                Failed Medications
            </p>
            <error-wrapper
                error-message="Please provide at least one specific medication name."
                :error-exists="hasFailedMedsError || hasBlackListMedsError"
                :no-background="true"
                :is-dirty="failedMedsDirty"
                :warning-exists="hasGreyListMedsWarning"
                :show-warn-over-error="true"
                warning-message="If possible, please provide at least one specific medication name"
            >
                <myriad-multiselect
                    :items="$store.getters.getAllMeds"
                    :preselected-items="$store.getters.getFailedMeds"
                    :disabled="false"
                    :class="multiselectClassName"
                    items-name="medications"
                    placeholder="Type or select a medication"
                    @selected-values-change="saveFailedMeds"
                />
            </error-wrapper>
        </div>
        <p class="section-text contemplating-question-label">
            Are you contemplating an alteration in a neuropsychiatric medication treatment for this patient?
        </p>
        <error-wrapper
            error-message="Please provide a response."
            :error-exists="contemplatingChangeUnselected"
            :is-dirty="false"
            :warn-at-start="true"
            :warning-exists="!contemplatingChange && !contemplatingChangeUnselected && $store.getters.getCurrentOrderIsMedicare"
            warning-message="An alteration in treatment must be selected for this order to meet Medicare coverage."
        >
            <div class="section-check-row">
                <myriad-radio
                    id="alteration-yes"
                    v-model="contemplatingChange"
                    name="contemplatingAlteration"
                    label="Yes"
                    class="section-radio"
                    :show-unselected="contemplatingChangeUnselected"
                    :model-property="contemplatingChange"
                    @item-selected="alterationUnselected = false"
                    @radio-selection="$store.dispatch('setMedicalNecessityApproved', false);"
                />
                <myriad-radio
                    id="alteration-no"
                    v-model="contemplatingChange"
                    name="contemplatingAlteration"
                    label="No"
                    class="section-radio"
                    :false-checkbox="true"
                    :show-unselected="contemplatingChangeUnselected"
                    :model-property="contemplatingChange"
                    @item-selected="alterationUnselected = false"
                    @radio-selection="$store.dispatch('setMedicalNecessityApproved', false);"
                />
            </div>
        </error-wrapper>
        <p class="section-text top-spacer">
            {{ additionalInformationLabel }}
        </p>
        <error-wrapper
            :error-exists="hasAdditionalInformationError"
            error-message="Please provide a reason for ordering."
            :is-dirty="true"
        >
            <textarea
                v-model="additionalInformation"
                class="necessity-textarea"
                :placeholder="hasAdditionalInformationError ? '': '(optional)'"
                @change="$store.dispatch('setMedicalNecessityApproved', false);"
            />
        </error-wrapper>
    </div>
</template>

<script>
import MyriadRadio from "../../../components/MyriadRadio.vue";
import ErrorWrapper from "../ErrorWrapper.vue";
import Common from "../../../scripts/common.js";

export default {
    name: "MedicalNecessity",
    components: {
        MyriadRadio,
        ErrorWrapper
    },
    data() {
        return {
            alterationRequiredMessage: "An alteration in treatment must be selected for this order to meet Medicare coverage.",
            failedMedsMedicareRequiredMessage: "The patient must have failed or be currently failing at least one neuropsychiatric medication for this order to meet Medicare coverage.",
            failedMedsDirty: false,
            alterationUnselected: false,
            hasBlackListMedsError: false,
            hasGreyListMedsWarning: false
        };
    },
    computed: {
        hasFailedMedsError() {
            return this.patientFailedMeds && this.$store.getters.getFailedMeds.length <= 0;
        },
        hasAdditionalInformationError() {
            return !this.contemplatingChangeUnselected && !this.contemplatingChange && !this.patientFailedMeds && Common.isEmptyOrNull(this.additionalInformation);
        },
        multiselectClassName() {
            let className = "medication-select";

            if (this.hasGreyListMedsWarning && this.hasBlackListMedsError) {
                className = className + " medication-select--warning";
            } else if (this.hasBlackListMedsError || this.hasFailedMedsError) {
                className = className + " medication-select--error";
            } else if (this.hasGreyListMedsWarning) {
                className = className + " medication-select--warning";
            }

            return className;
        },
        additionalInformationLabel() {
            return !this.contemplatingChangeUnselected && !this.contemplatingChange && !this.patientFailedMeds ? "Reason for Ordering" : "Additional Information";
        },
        contemplatingChangeUnselected() {
            return this.alterationUnselected && this.$store.getters.getContemplatingChange.unselected;
        },
        patientFailedMeds: {
            get() {
                return this.$store.getters.getHasPatientFailedMed;
            },
            set(value) {
                this.$store.dispatch("setHasPatientFailedMed", value);
            }
        },
        contemplatingChange: {
            get() {
                return this.$store.getters.getContemplatingChange.value;
            },
            set(value) {
                this.$store.dispatch("setContemplatingChange", value);
            }
        },
        additionalInformation: {
            get() {
                return this.$store.getters.getAdditionalInformation;
            },
            set(value) {
                this.$store.dispatch("setAdditionalInformation", value);
            }
        }
    },
    watch: {
        hasFailedMedsError(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.toggleMedicalNecessityError(newValue, this.$store.getters.getMedAlterationMedicareErrorString);
            }
        },
        hasAdditionalInformationError(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.toggleMedicalNecessityError(newValue, this.$store.getters.getMedAlterationMedicareErrorString);
            }
        },
        contemplatingChangeUnselected(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.toggleMedicalNecessityError(newValue, this.$store.getters.getContemplatingChangeErrorString);
            }
        }
    },
    created() {
        if (this.$store.getters.getContemplatingChange.unselected) {
            this.alterationUnselected = true;
            this.$store.dispatch("addMedicalNecessityError", this.$store.getters.getContemplatingChangeErrorString);
        }
    },
    methods: {
        saveFailedMeds(meds) {
            this.$store.dispatch("setMedicalNecessityApproved", false);
            this.failedMedsDirty = true;

            const filteredMeds = this.createFilteredMedArray(meds);
            this.$store.dispatch("setFailedMeds", filteredMeds);

            this.checkForGreyBlackMedsValidationError();
        },
        checkForGreyBlackMedsValidationError() {
            const numGreyMeds = this.getNumMedBlackGrayList(this.$store.getters.getFailedMeds, this.$store.getters.getGrayList, false);
            const numBlackMeds = this.getNumMedBlackGrayList(this.$store.getters.getFailedMeds, this.$store.getters.getBlackList, true);

            const totalGreyBlackMeds = numGreyMeds + numBlackMeds;

            const blackListError = (this.$store.getters.getFailedMeds.length === totalGreyBlackMeds) && numBlackMeds > 0;
            const greyListWarning = (this.$store.getters.getFailedMeds.length === totalGreyBlackMeds) && numGreyMeds > 0;

            if (blackListError && !greyListWarning) {
                this.toggleMedicalNecessityError(true, this.$store.getters.getFailedMedsBlackListErrorString);
            } else {
                this.toggleMedicalNecessityError(false, this.$store.getters.getFailedMedsBlackListErrorString);
            }

            this.hasBlackListMedsError = !!blackListError;
            this.hasGreyListMedsWarning = !!greyListWarning;
        },
        createFilteredMedArray(medArray) {
            let filteredItems = "";
            medArray.forEach((item, index) => {
                if (index === medArray.length - 1) {
                    filteredItems = filteredItems + item.name;
                } else {
                    filteredItems = filteredItems + item.name + "|";
                }
            });

            return filteredItems;
        },
        toggleMedicalNecessityError(boolValue, stringValue) {
            if (boolValue) {
                this.$store.dispatch("addMedicalNecessityError", stringValue);
            } else {
                this.$store.dispatch("removeMedicalNecessityError", stringValue);
            }
        },
        getNumMedBlackGrayList(allFailedMeds, listToCheck, checkBlankOrPunc) {
            let numMatches = 0;

            for (let i = 0; i < allFailedMeds.length; i++) {
                const failedMed = allFailedMeds[i].replace(/[^A-Za-z0-9 ]/g, "").toLowerCase();

                for (let j = 0; j < listToCheck.length; j++) {
                    if (failedMed === listToCheck[j].toLowerCase() || (checkBlankOrPunc && failedMed === "")) {
                        numMatches++;
                        break;
                    }
                }
            }

            return numMatches;
        },
        currentFailedMedsSelected() {
            this.failedMedsDirty = true;
            this.$store.dispatch("setMedicalNecessityApproved", false);
        }
    }
};
</script>

<style lang="scss" scoped>
     @import "../../../styles/AuthorizeOrders.scss";

     .top-spacer {
         margin-top: 40px;
     }

     .necessity-textarea {
         width: 100%;
         height: 25px;
         padding: 0;
         font-weight: normal;
         font-style: normal;
         font-size: 1em;
         line-height: 25px;
     }

     .necessity-textarea::placeholder {
         font-weight: normal;
         font-style: normal;
         font-size: .8em;
     }

     .contemplating-question-label {
         margin-top: 45px;
     }

     .meds-section {
         .section-gray-text {
             margin-top: 0;
         }
     }

     .section-check-row--failingMeds {
         margin-bottom: 20px;
     }

     .error-highlight .section-check-row--failingMeds {
         margin-bottom: 0;
     }

     ::v-deep .medication-select .multiselect {
         border: 1px solid #7a7a7a;
     }

     ::v-deep .medication-select--warning .multiselect {
         border: 2px solid $genesight-orange;
     }

     ::v-deep .medication-select--error .multiselect {
         border: 2px solid $genesight-red;
     }
</style>
