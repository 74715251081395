<template>
    <div class="mobile-agreement-container">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="mobile-agreement" v-html="agreementHtml" />
        <div class="mobile-agreement-hr" />
        <neuro-button class="agreement-button" text="" :filled="true" @neuro-btn-click="agreementButtonClicked" v-text="agreementButtonType" />
    </div>
</template>

<script>

import NeuroButton from "../components/NeuroButton.vue";
import AgreementHTTP from "../scripts/http/agreement-http.js";

export default {
    name: "Agreement",
    components: {
        NeuroButton
    },
    data() {
        return {
            agreementHtml: "",
            agreementButtonType: ""
        };
    },
    created() {
        this.loadAgreementHtml();
    },
    methods: {
        getAgreementHtmlToShow() {
            var postData = { AgreementId: this.$store.getters.getAgreementIds[0] };
            return AgreementHTTP.GetAgreementData(postData);
        },
        loadAgreementHtml() {
            this.getAgreementHtmlToShow().then(data => {
                this.agreementHtml = this.replaceAgreementHtmlWidth(data.data.agreementHtml);
                this.agreementButtonType = this.getAgreementButtonTypeText(data.data.buttonType);
            }).catch(() => {
                this.$store.dispatch("showDefaultNotificationRibbon");
            }).finally(() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                this.$store.dispatch("hideFullScreenLoader");
            });
        },
        getAgreementButtonTypeText(buttonType) {
            if (buttonType === "GotIt") {
                return "Got it";
            }

            return buttonType;
        },
        getAgreementButtonTypeForPost(buttonType) {
            if (buttonType === "Got it") {
                return "GotIt";
            }

            return buttonType;
        },
        agreementButtonClicked() {
            var postData = { AgreementId: this.$store.getters.getAgreementIds[0], ButtonClicked: this.getAgreementButtonTypeForPost(this.agreementButtonType) };
            this.$store.dispatch("showFullScreenLoader");
            return AgreementHTTP.PostAgreementData(postData).then(data => {
                if (data.data.accepted === false) {
                    this.$router.push("/LogOn");
                } else {
                    this.$store.dispatch("setAgreementIds", data.data.agreementIdsToShow);
                    if (data.data.agreementIdsToShow.length > 0) {
                        this.loadAgreementHtml();
                    } else {
                        this.$router.push("/");
                    }
                }
            }).catch(() => {
                this.$store.dispatch("showDefaultNotificationRibbon");
                this.$store.dispatch("hideFullScreenLoader");
            });
        },
        replaceAgreementHtmlWidth(htmlString) {
            const width850Exists = htmlString.indexOf("width: 850px") !== -1;
            const width800Exists = htmlString.indexOf("width: 800px") !== -1;
            const width910Exists = htmlString.indexOf("width: 910px") !== -1;

            if (width850Exists) {
                htmlString = htmlString.replace("width: 850px", "width: 100%");
            } else if (width800Exists) {
                htmlString = htmlString.replace("width: 800px", "width: 100%");
            } else if (width910Exists) {
                htmlString = htmlString.replace("width: 910px", "width: 100%");
            }

            return htmlString;
        }
    }
};
</script>

<style lang="scss" scoped>
.mobile-agreement-container {
    margin: 0 20px;
}
.mobile-agreement {
    margin-bottom: 30px;
}
.agreement-button
{
    margin: 0 auto;
    width: 100%;
    margin-bottom: 30px;
}
.mobile-agreement-hr
{
    margin: 20px auto 40px auto;
    border-top: $border-gray 1px solid;
    width: 100px;
}
</style>
