import Vue from "vue";
import Router from "vue-router";
import Store from "../store/store";

import Home from "../pages/Home.vue";
import LogOn from "../pages/LogOn.vue";
import Signature from "../pages/Signature.vue";
import ViewReport from "../pages/ViewReport.vue";
import ReportList from "../pages/ReportList.vue";
import AuthorizeOrders from "../pages/AuthorizeOrder.vue";
import ErrorPage from "../pages/Error.vue";
import ContactPage from "../pages/Contact.vue";
import NewOrderPage from "../pages/NewOrder.vue";
import OrderSubmitted from "../pages/OrderSubmitted.vue";
import Agreement from "../pages/Agreement.vue";
import PrivacyPage from "../pages/PrivacyPage.vue";
import NewPassword from "../pages/NewPassword.vue";
import Common from "../scripts/common.js";

Vue.use(Router);

const router = new Router({
    routes: [{
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/LogOn",
        name: "LogOn",
        component: LogOn
    },
    {
        path: "/Error",
        name: "Error",
        component: ErrorPage
    },
    {
        path: "/Agreement",
        name: "Agreement",
        component: Agreement
    },
    {
        path: "/Signature",
        name: "Signature",
        component: Signature
    },
    {
        path: "/Reports/:orderId",
        name: "Reports",
        component: ReportList,
        meta: {
            transitionType: "slide",
            props: true
        }
    },
    {
        path: "/ViewReport",
        name: "ViewReport",
        component: ViewReport,
        meta: {
            transitionType: "slide"
        }
    },
    {
        path: "/AuthorizeOrders",
        name: "AuthorizeOrders",
        component: AuthorizeOrders
    },
    {
        path: "/Contact",
        name: "Contact",
        component: ContactPage
    },
    {
        path: "/NewOrder",
        name: "NewOrder",
        component: NewOrderPage
    },
    {
        path: "/OrderSubmitted",
        name: "OrderSubmitted",
        component: OrderSubmitted
    },
    {
        path: "/Privacy/:noticeId",
        name: "Privacy",
        component: PrivacyPage,
        props: true
    },
    {
        path: "/Account/RecoverPassword/:token",
        name: "NewPassword",
        component: NewPassword,
        props: true
    }],
    scrollBehavior () {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    Store.dispatch("setHideNotificationRibbon");

    if (!Store.getters.getLoggedIn) {
        if (to.path === "/LogOn" || to.path === "/Contact" || to.path.includes("/Privacy") || to.path.toLowerCase().includes("/account/recoverpassword")) {
            next();
        } else {
            Store.dispatch("setResetLoginPage");
            next("/LogOn");
        }
    } else if (!Store.getters.getClincianSignaureSet && Store.getters.getIsClinician) {
        if (to.path === "/Signature" || to.path === "/Contact") {
            next();
        } else {
            next("/Signature");
        }
    } else if (Store.getters.getAgreementIds.length > 0) {
        if (to.path === "/Agreement" || to.path === "/LogOn" || to.path === "/Contact") {
            next();
        } else {
            next("/Agreement");
        }
    } else {
        if (to.path === "/LogOn") {
            Common.logOff(false);
        }

        next();
    }
});

export default router;
