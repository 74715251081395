<template>
    <div class="new-order-page">
        <div class="new-order">
            <p v-if="this.$v.$invalid && this.$v.$dirty" class="error-message">
                Please supply information for all fields
            </p>
            <h1 class="new-order-header">
                New Order
            </h1>
            <h3 class="new-order-greeen-header">
                Clinic Information
            </h3>
            <labeled-select :options="clinics" label="Clinic" automation-value="new-order-clinic-select" @selection-made="clinicSelected" />
            <p v-if="showClinicsMissingFeatureMessage" class="clinics-missing-feature-msg">
                Only clinics with the Patient Information Entry feature enabled are shown.  Contact your Sales Representative to enable additional clinics.
            </p>
            <labeled-select
                ref="clinicianSelect"
                :class="selectedClinician === '' && $v.$dirty ? 'new-order-input-error' : ''"
                :options="clinicians"
                automation-value="new-order-clinician-select"
                label="Clinician"
                :start-empty="true"
                @selection-made="clincianSelected"
            />
            <h3 class="new-order-greeen-header">
                Patient Information
            </h3>
            <labeled-input
                v-model="$v.patientFirstName.$model"
                :class="$v.patientFirstName.$invalid && $v.patientFirstName.$dirty ? 'new-order-input-error' : ''"
                automation-value="new-order-input-first-name"
                class-string=""
                placeholder="Patient First Name"
            />
            <labeled-input
                v-model="$v.patientLastName.$model"
                :class="$v.patientLastName.$invalid && $v.patientLastName.$dirty ? 'new-order-input-error' : ''"
                automation-value="new-order-input-last-name"
                class-string=""
                placeholder="Patient Last Name"
            />
            <div class="order-dob-row">
                <labeled-input
                    v-model="$v.patientDob.$model"
                    :class="$v.patientDob.$invalid && $v.patientDob.$dirty ? 'order-dob new-order-input-error' : 'order-dob'"
                    class-string=""
                    placeholder="Date of Birth"
                    automation-value="new-order-input-dob"
                    mask-type="##/##/####"
                    @input="dobInputEvent"
                    @blur="checkIfPatientIsAMinor"
                />
                <div ref="datePreview" class="date-preview">
                    mm/dd/yyyy
                </div>
            </div>
            <transition name="grow">
                <p v-if="showMinorError" class="new-order-minor-error">
                    Sorry, we're unable to contact minors directly to collect order information at this time. Sign in to mygenesight.com from a desktop/laptop computer to place an order for this patient.
                </p>
            </transition>
            <labeled-input
                v-model="$v.patientPhoneNumber.$model"
                :class="$v.patientPhoneNumber.$invalid && $v.patientPhoneNumber.$dirty ? 'order-phone new-order-input-error' : 'order-phone'"
                class-string=""
                automation-value="new-order-input-first-phone"
                placeholder="Mobile Number"
                mask-type="(###) ###-####"
            />
            <p class="no-mobile-phone">
                No mobile number?
            </p>
            <a class="mobile-link new-order-email-link" data-neuro-auto="new-order-link-enter-email" @click="showEmailInput = true">
                enter an email address
            </a>
            <transition name="grow">
                <labeled-input
                    v-if="showEmailInput"
                    v-model="$v.patientEmail.$model"
                    :class="$v.patientEmail.$invalid && $v.patientEmail.$dirty ? 'new-order-input-error' : ''"
                    automation-value="new-order-input-email"
                    class-string=""
                    placeholder="Email Address"
                />
            </transition>
            <neuro-button
                class="new-order-submit-btn"
                text="Send Link to Patient"
                data-neuro-auto="new-order-btn-send-to-patient"
                :show-loader="showButtonLoader"
                :filled="true"
                :disabled="showMinorError"
                color="green"
                @neuro-btn-click="submitNewOrder"
            />
        </div>
    </div>
</template>

<script>
import LabeledInput from "../components/LabeledInput.vue";
import LabeledSelect from "../components/LabeledSelect.vue";
import NeuroButton from "../components/NeuroButton.vue";

import { required, maxLength, email, minLength } from "vuelidate/lib/validators";
import Validators from "../scripts/validators.js";
import NewOrderHttp from "../scripts/http/new-order-http.js";
import moment from "moment";

export default {
    name: "NewOrder",
    components: {
        LabeledInput,
        LabeledSelect,
        NeuroButton
    },
    validations() {
        const validations = {
            patientFirstName: {
                required,
                maxLength: maxLength(100)
            },
            patientLastName: {
                required,
                maxLength: maxLength(100)
            },
            patientDob: {
                required,
                maxLength: maxLength(10),
                minLength: minLength(8)
            },
            patientPhoneNumber: {
                required,
                phoneNumber: Validators.phoneNumber
            },
            selectedClinic: {
                required
            },
            selectedClinician: {
                required
            }
        };

        if (this.showEmailInput) {
            validations.patientEmail = {
                required,
                email
            };

            validations.patientPhoneNumber = {
                phoneNumber: Validators.phoneNumber
            };
        }

        return validations;
    },
    data() {
        return {
            showEmailInput: false,
            patientFirstName: "",
            patientLastName: "",
            patientDob: "",
            patientPhoneNumber: "",
            patientEmail: "",
            selectedClinic: "",
            selectedClinician: "",
            clinicians: [],
            clinics: [],
            showButtonLoader: false,
            showClinicsMissingFeatureMessage: false,
            showMinorError: false
        };
    },
    created() {
        this.getClinics();
    },
    mounted() {
        if (this.shouldDefaultClinician()) {
            this.$refs.clinicianSelect.$refs.selectBox.value = this.$store.getters.clinicianName;
        }
    },
    methods: {
        getClinics() {
            NewOrderHttp.GetListOfClinicsAndClinicians().then(responseData => {
                this.showClinicsMissingFeatureMessage = responseData.data.hasClinicsMissingFeature;
                this.clinics = responseData.data.clinicsAndClinicians;
                this.clinicians = responseData.data.clinicsAndClinicians[0].clinicians;

                // Set default clinic information
                this.selectedClinic = responseData.data.clinicsAndClinicians[0];

                if (this.shouldDefaultClinician()) {
                    this.selectedClinician = responseData.data.clinicsAndClinicians[0].clinicians[0];
                }
            });
        },
        clinicSelected(clinic) {
            this.selectedClinic = clinic;
            this.clinicians = clinic.clinicians;
            this.selectedClinician = "";
            this.$refs.clinicianSelect.$refs.selectBox.value = "";
        },
        clincianSelected(clincian) {
            this.selectedClinician = clincian;
        },
        submitNewOrder() {
            if (!this.$v.$invalid) {
                this.showButtonLoader = true;

                const orderData = {
                    ClinicId: this.selectedClinic.clinicId,
                    ClinicianId: this.selectedClinician.clinicianId,
                    Patient: {
                        FirstName: this.patientFirstName,
                        LastName: this.patientLastName,
                        DateOfBirth: this.patientDob,
                        Phone: this.patientPhoneNumber,
                        Email: this.patientEmail
                    }
                };

                NewOrderHttp.SubmitNewOrder(orderData).then(() => {
                    this.$router.push("/OrderSubmitted");
                }).catch(() => {
                    this.$store.dispatch("showDefaultNotificationRibbon");
                }).finally(() => {
                    this.showButtonLoader = false;
                });
            } else {
                this.$v.$touch();
                window.scrollTo(0, 0);
            }
        },
        dobInputEvent() {
            const date = moment(this.patientDob);
            const meetsMinLength = this.patientDob.length >= 8;

            if (date.isValid() && meetsMinLength) {
                this.$refs.datePreview.innerHTML = date.format("MMMM DD, YYYY");
            } else {
                this.$refs.datePreview.innerHTML = "mm/dd/yyyy";
            }
        },
        checkIfPatientIsAMinor() {
            const dateOfBirth = moment(this.patientDob);

            if (dateOfBirth.isValid()) {
                const youngErrorDate = moment().subtract(18, "years").format("MM/DD/YYYY");
                if (dateOfBirth.isAfter(youngErrorDate)) {
                    this.showMinorError = true;
                } else {
                    this.showMinorError = false;
                }
            }
        },
        shouldDefaultClinician() {
            return this.$store.getters.getIsClinician && this.$refs.clinicianSelect && this.$refs.clinicianSelect.$refs.selectBox;
        }
    }
};
</script>

<style lang="scss" scoped>
.new-order-page {
    width: 100%;
}

.new-order {
    padding: 0 10px 0 10px;
}

.new-order-header {
    margin: 25px 0 25px 0;
    font-size: $large-header-font-size;
}

.new-order-greeen-header {
    margin-bottom: 20px;
    padding-bottom: 10px;
    font-size: $header-font-size;
    color: $genesight-green;
    border-bottom: 1px solid $genesight-green;
}

.no-mobile-phone {
    margin-top: -12px;
    margin-bottom: 10px;
    font-size: $regular-copy-size;
    color: $light-gray-text;
}

.new-order-email-link {
    display: block;
    margin-bottom: 10px;
    font-size: $regular-copy-size;
}

.new-order-submit-btn {
    width: 100%;
    margin-top: 35px;
}

.error-message {
    margin-top: 30px;
    color: $genesight-red;
}

.clinics-missing-feature-msg {
    color: $light-gray-text;
    font-size: $regular-copy-size;
    margin-top: -7px;
    margin-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.order-dob-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

::v-deep .order-dob-row .labeled-input {
    margin-bottom: 0;
}

::v-deep .new-order-input-error .labeled-input,
::v-deep .new-order-input-error .labeled-select-input {
    border-color: $genesight-red;
}

.order-dob {
    width: 60%;
    max-width: 150px;
}

.order-phone {
    max-width: 150px;
}

.date-preview {
    width: 40%;
    margin-left: 15px;
    color: $light-gray-text;
}

.new-order-minor-error {
    margin-top: -11px;
    margin-bottom: 13px;
    color: $genesight-red;
}
</style>
