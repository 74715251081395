import { HTTP } from "./http";

const PrivacyHttp = {
    GetNotice: (noticeId) => {
        const url = config.myriadNoticesUrl + noticeId;
        return HTTP.get(url);
    }
};

export default PrivacyHttp;
