<template>
    <div class="clinician-auth-container">
        <div :class="slotItemsClass">
            <slot />
        </div>
        <auth-button
            v-if="isPatientEnteredAnd2019Questions"
            top-text="Patient-Information"
            :button-enabled="authEnabled"
            :button-approve-state="value"
            @input="authorizeSection"
        />
    </div>
</template>

<script>
import AuthButton from "../AuthButton.vue";

export default {
    name: "ClinicianAuthContainer",
    components: {
        AuthButton
    },
    props: {
        authEnabled: {
            default: false,
            required: true,
            type: Boolean
        },
        value: {
            default: false,
            required: true,
            type: Boolean
        }
    },
    computed: {
        slotItemsClass() {
            let className = "slot-items";

            if (!this.isPatientEnteredAnd2019Questions) {
                className = className + " slot-items-no-auth";
            } else if (this.value) {
                className = className + " slot-items-complete";
            } else if (this.$store.getters.getCurrentOrderIsPatientEntered) {
                className = className + " slot-items-auth";
            }

            return className;
        },
        isPatientEnteredAnd2019Questions() {
            return this.$store.getters.getCurrentOrderIsPatientEntered && !this.$store.getters.getIs2020Questions;
        }
    },
    watch: {
        authEnabled(newValue) {
            if (!newValue) {
                this.$emit("input", false);
            }
        }
    },
    methods: {
        authorizeSection() {
            this.$emit("input", true);
        }
    }
};
</script>

<style lang="scss" scoped>
.slot-items {
    padding-bottom: 40px;
    border-radius: 7px;
}

.slot-items-auth {
    border: dashed 2px $genesight-blue;
}

.slot-items-no-auth {
    @include auth-section-border();
}

.slot-items-complete {
    border: 2px solid $genesight-green;
}
</style>
