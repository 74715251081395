<template>
    <div class="diagnosis-2020">
        <h4 class="sub-section-header">
            Diagnosis
        </h4>
        <label class="sub-section-label">
            List ICD-10 code(s) for this patient’s <b>psychiatric</b> diagnosis
            <span class="sub-section-gray-text">
                (maximum of 4)
            </span>
        </label>
        <error-wrapper
            :error-exists="!$store.getters.atLeastOneDiagCodeSupplied"
            error-message="Please provide a response."
        >
            <div class="diagnosis-multiselect-container">
                <div class="diagnosis-multiselect" />
            </div>
        </error-wrapper>
    </div>
</template>

<script>
import { NeuroMultiselect } from "@myriad-neuroscience/myriad-neuro-multiselect/neuro-multiselect.js";
import ErrorWrapper from "../../ErrorWrapper.vue";
import AuthorizationHttp from "../../../../scripts/http/authorization-http.js";

export default {
    name: "Diagnosis2020",
    components: {
        ErrorWrapper
    },
    data() {
        return {
            diagnosisMultiselect: null
        };
    },
    computed: {
        defaultDiagCodesAll() {
            return this.$store.getters.getDefaultDiagnosticCodesAll;
        },
        diagCode1: {
            get() {
                return this.$store.getters.getDiagCode;
            },
            set(value) {
                this.$store.dispatch("setDiagCode", value);
            }
        },
        secondaryDiagCode1: {
            get() {
                return this.$store.getters.getSecondaryDiagCode1;
            },
            set(value) {
                this.$store.dispatch("setSecondaryDiagCode1", value);
            }
        },
        secondaryDiagCode2: {
            get() {
                return this.$store.getters.getSecondaryDiagCode2;
            },
            set(value) {
                this.$store.dispatch("setSecondaryDiagCode2", value);
            }
        },
        secondaryDiagCode3: {
            get() {
                return this.$store.getters.getSecondaryDiagCode3;
            },
            set(value) {
                this.$store.dispatch("setSecondaryDiagCode3", value);
            }
        }
    },
    watch: {
        defaultDiagCodesAll(newValues) {
            this.createMultiselect(newValues);
        }
    },
    mounted() {
        this.createMultiselect();
    },
    methods: {
        createMultiselect() {
            const domElement = document.querySelector(".diagnosis-multiselect");
            domElement.innerHTML = "";

            const preselectedValues = this.getPreselectedDiagCodes();
            const that = this;

            var options = [];
            if (this.$store.getters.getCurrentOrderIsUHC) {
                options = [
                    { Depression: this.$store.getters.getUhcDiagnosticCodesDepression },
                    { Anxiety: this.$store.getters.getUhcDiagnosticCodesAnxiety },
                    { Bipolar: this.$store.getters.getUhcDiagnosticCodesBipolar },
                    { OCD: this.$store.getters.getUhcDiagnosticCodesOCD },
                    { PTSD: this.$store.getters.getUhcDiagnosticCodesPTSD },
                    { Schizophrenia: this.$store.getters.getUhcDiagnosticCodesSchizophrenia }
                ];
            } else {
                options = [
                    { Depression: this.$store.getters.getDefaultDiagnosticCodesDepression },
                    { Anxiety: this.$store.getters.getDefaultDiagnosticCodesAnxiety },
                    { ADHD: this.$store.getters.getDefaultDiagnosticCodesADHD },
                    { Bipolar: this.$store.getters.getDefaultDiagnosticCodesBipolar },
                    { OCD: this.$store.getters.getDefaultDiagnosticCodesOCD },
                    { PTSD: this.$store.getters.getDefaultDiagnosticCodesPTSD },
                    { Schizophrenia: this.$store.getters.getDefaultDiagnosticCodesSchizophrenia }
                ];
            }

            this.diagnosisMultiselect = new NeuroMultiselect();
            this.diagnosisMultiselect.init(
                document.querySelector(".diagnosis-multiselect"),
                options,
                preselectedValues,
                {
                    allowCustomInputs: false,
                    footerString: "If you do not see an appropriate code in this list, type to search all codes",
                    placeholderString: "Type to see matching ICD-10 codes.",
                    maxAllowed: 4
                }
            );
            this.diagnosisMultiselect.setupApiCallback(function(searchTerm, returnCallback) {
                AuthorizationHttp.SearchDiagnosticCodeAutocomplete(
                    that.$store.getters.getPsychProductId,
                    that.$store.getters.getCurrentOrderIsMedicare,
                    searchTerm
                ).then((response) => {
                    returnCallback(response);
                }).catch(() => {
                    that.$store.dispatch("showDefaultNotificationRibbon");
                });
            });

            this.diagnosisMultiselect.setupCallbackReturn(function(data) {
                for (let i = 0; i < 4; i++) {
                    that.setDiagCode(data[i], i);
                }
            });
        },
        getPreselectedDiagCodes() {
            const preselectedDiagCodes = [];

            if (this.diagCode1.diagnosticCodeValue) {
                this.diagCode1.value = this.diagCode1.diagnosticCodeValue;
                preselectedDiagCodes.push(this.diagCode1);
            }

            if (this.secondaryDiagCode1.diagnosticCodeValue) {
                this.secondaryDiagCode1.value = this.secondaryDiagCode1.diagnosticCodeValue;
                preselectedDiagCodes.push(this.secondaryDiagCode1);
            }

            if (this.secondaryDiagCode2.diagnosticCodeValue) {
                this.secondaryDiagCode2.value = this.secondaryDiagCode2.diagnosticCodeValue;
                preselectedDiagCodes.push(this.secondaryDiagCode2);
            }

            if (this.secondaryDiagCode3.diagnosticCodeValue) {
                this.secondaryDiagCode3.value = this.secondaryDiagCode3.diagnosticCodeValue;
                preselectedDiagCodes.push(this.secondaryDiagCode3);
            }

            return preselectedDiagCodes;
        },
        setDiagCode(diagCode, diagCodeIndex) {
            if (diagCodeIndex === 0) {
                this.diagCode1 = diagCode || {};
            } else if (diagCodeIndex === 1) {
                this.secondaryDiagCode1 = diagCode || {};
            } else if (diagCodeIndex === 2) {
                this.secondaryDiagCode2 = diagCode || {};
            } else if (diagCodeIndex === 3) {
                this.secondaryDiagCode3 = diagCode || {};
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~@myriad-neuroscience/myriad-neuro-multiselect/neuro-multiselect.css";
@import "../../../../styles/AuthorizeOrders.scss";

.diagnosis-multiselect-container {
    margin-top: 5px;
}

</style>
