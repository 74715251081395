<template>
  <div v-show="$store.getters.getIsConsideringAugmentingWithNewMedication" class="augment-container">
    <label class="sub-section-label">
      List the GeneSight medications that you are considering for <b>augmentation</b> or <b>starting/switching to</b>
    </label>
    <error-wrapper
      :error-exists="$store.getters.augmentMedicationsError"
      error-message="Please provide a response."
    >
      <div class="augmentation-multiselect-container">
        <div class="augmentation-multiselect" :class="[isSingleGeneTest && isMedicare ? 'multiselect-single-gene-warning': '', (isSingleGeneTest && $store.getters.getAugmentationHighlight) ? 'multiselect-add-medication' : '']" />
      </div>
    </error-wrapper>
  </div>
</template>

<script>
import { NeuroMultiselect } from "@myriad-neuroscience/myriad-neuro-multiselect/neuro-multiselect.js";
import ErrorWrapper from "../../ErrorWrapper.vue";
import Common from "../../../../scripts/common.js";
import Enums from "../../../../scripts/enums.js";

export default {
    name: "AugmentingMedication",
    components: {
        ErrorWrapper
    },
    data() {
        return {
            nonGeneticFactors: false,
            nonGeneticFactorsDirty: false,
            augmentHeaderString: "You selected that this patient has Medicare. Medicare coverage for PGx tests is limited to when providers are considering use of one or more medications with a gene-drug interaction that is clinically actionable and designated by the FDA or CPIC (category A or B).",
            singleGeneName: "",
            augmentationObjectList: [],
            doseageObjectList: []
        };
    },
    computed: {
        allMeds() {
            return this.$store.getters.getAllMeds;
        },
        isMedicare() {
            return this.$store.getters.getCurrentOrderIsMedicare;
        },
        augmentationGene: {
            get() {
                return this.$store.getters.getAugmentationGene;
            },
            set(value) {
                this.$store.dispatch("setAugmentationGene", value);
            }
        },
        doseageGene: {
            get() {
                return this.$store.getters.getDoseageGene;
            },
            set(value) {
                this.$store.dispatch("setDoseageGene", value);
            }
        },
        augmentationMedications: {
            get() {
                return this.$store.getters.getAugmentationMedications;
            },
            set(value) {
                this.$store.dispatch("setAugmentationMedications", value);
            }
        },
        doseageMedications: {
            get() {
                return this.$store.getters.getDoseageMedications;
            }
        },
        isSingleGeneTest() {
            const { doseageMedsValid, augMedsValid } = this.getMedInputsValid();
            return doseageMedsValid && augMedsValid && Common.getTestType() === Enums.TestTypes.SINGLE_GENE;
        },
        isZeroGeneTest() {
            const { doseageMedsValid, augMedsValid } = this.getMedInputsValid();
            return doseageMedsValid && augMedsValid && Common.getTestType() === Enums.TestTypes.ZERO_GENE;
        },
        isMultiGeneTest() {
            const { doseageMedsValid, augMedsValid } = this.getMedInputsValid();
            return doseageMedsValid && augMedsValid && Common.getTestType() === Enums.TestTypes.MULTI_GENE;
        },
        combinedMeds() {
            return this.$store.getters.getCombinedMeds;
        },
        otherMeds() {
            return this.$store.getters.getOtherMeds;
        },
        cpicMeds() {
            return this.$store.getters.getCpicMeds;
        },
        cpicOtherMeds() {
            return [
                { "FDA & CPIC A/B Meds": this.cpicMeds },
                { "Other Meds": this.otherMeds }
            ];
        },
        singleGene() {
            if (this.$store.getters.getIsConsideringAugmentingWithNewMedication) {
                return this.augmentationGene;
            }
            if (this.$store.getters.getIsConsideringDoseageAdjustment && !this.$store.getters.getDisableDoseageAdjustment) {
                return this.doseageGene;
            }

            return "";
        },
        augmentationList: {
            get() {
                return this.$store.getters.getSelectedAugmentationMeds;
            },
            set(value) {
                this.$store.dispatch("setSelectedAugmentationMeds", value);
            }
        }
    },
    watch: {
        combinedMeds(newValues) {
            this.$nextTick(() => {
            });
        },
        cpicOtherMeds(newValues) {
            this.$nextTick(() => {
                this.createAugmentationMultiselect(newValues);
            });
        },
        isSingleGeneTest(newValue) {
            if (!newValue) {
                this.disableHighlights();
            }
            this.setSingleGeneStoreInfo(newValue);
        },
        isZeroGeneTest(newValue) {
            this.$store.dispatch("setIsZeroGeneTest", newValue);
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.cpicMeds.length > 0 && this.otherMeds.length > 0) {
                this.createAugmentationMultiselect(this.cpicOtherMeds);
            }
        });
        this.setSingleGeneStoreInfo(this.isSingleGeneTest);
    },
    methods: {
        createAugmentationMultiselect(medValues) {
            const domElement = document.querySelector(".augmentation-multiselect");

            if (domElement === null) {
                return;
            }

            domElement.innerHTML = "";

            let previousAugmentMultiselect = this.$store.getters.getAugmentationMultiselect;
            const augmentMultiselect = new NeuroMultiselect();
            if (previousAugmentMultiselect === null || previousAugmentMultiselect === undefined) {
                previousAugmentMultiselect = augmentMultiselect;
            }
            this.setPreviousGeneState(augmentMultiselect, previousAugmentMultiselect.state.previousGeneState);

            if (this.isMedicare) {
                augmentMultiselect.init(
                    domElement,
                    medValues,
                    this.initAugmentationList(medValues),
                    {
                        allowCustomInputs: false,
                        headerString: this.augmentHeaderString,
                        placeholderString: "Type or select a medication.",
                        footerString: "The same medication cannot be selected for augmentation and dosage adjustment.",
                        showSubTextOnSelectedItem: true
                    }
                );
            } else {
                augmentMultiselect.init(
                    domElement,
                    this.allMeds,
                    this.augmentationMedications,
                    {
                        allowCustomInputs: false,
                        placeholderString: "Type or select a medication.",
                        footerString: "The same medication cannot be selected for augmentation and dosage adjustment."
                    }
                );
            }

            this.getAugmentationSingleGene(medValues, this.augmentationMedications);

            const that = this;
            augmentMultiselect.setupCallbackReturn(function(selectedValues) {
                that.$store.dispatch("setAugmentationHighlight", false);
                that.$store.dispatch("setDoseageHighlight", false);
                var stringArray = [];
                var augmentationList = [];
                that.augmentationGene = '';
                selectedValues.forEach((value) => {
                    stringArray.push(value.value);
                    if (value.subText) {
                        that.augmentationGene = value.subText; // this value won't be used unless single gene
                        augmentationList.push(value);
                    }
                });

                that.augmentationMedications = stringArray;
                that.augmentationList = augmentationList;

                const doseageMultiselect = that.$store.getters.getDoseageMultiselect;
                const copyValues = JSON.parse(JSON.stringify(selectedValues));
                doseageMultiselect.markValuesAsDisabled(copyValues);
            });

            augmentMultiselect.setupBlurCallback(function() {
                Common.trackUserBehaviorInResponseToGeneWarnings();
            });

            this.$store.dispatch("setAugmentationMultiselect", augmentMultiselect);
        },
        setPreviousGeneState(multiselect, previousGeneState) {
            if (previousGeneState != null || previousGeneState !== undefined) {
                multiselect.state.previousGeneState = previousGeneState;
            } else {
                if (this.isMultiGeneTest) {
                    multiselect.state.previousGeneState = Enums.TestTypes.MULTI_GENE;
                } else if (this.isSingleGeneTest) {
                    multiselect.state.previousGeneState = Enums.TestTypes.SINGLE_GENE;
                } else if (this.isZeroGeneTest) {
                    multiselect.state.previousGeneState = Enums.TestTypes.ZERO_GENE;
                }
            }
        },
        getMedInputsValid() {
            const doseageMedsSelected = this.$store.getters.getIsConsideringDoseageAdjustment;
            const doseageMedsAnswered = this.doseageMedications.length > 0;

            const augMedsSelected = this.$store.getters.getIsConsideringAugmentingWithNewMedication;
            const augMedsAnswered = this.augmentationMedications.length > 0;

            const doseageMedsValid = doseageMedsSelected ? doseageMedsAnswered : true;
            const augMedsValid = augMedsSelected ? augMedsAnswered : true;

            return {
                doseageMedsValid: doseageMedsValid,
                augMedsValid: augMedsValid
            };
        },
        setSingleGeneStoreInfo(newValue) {
            // we only show single gene if medicare
            if (newValue && this.isMedicare) {
                const testTitle = this.singleGeneName + " Single Gene Report";
                this.$store.dispatch("setTestTitle", testTitle);
            } else {
                this.$store.dispatch("resetTestTitle");
            }
            this.$store.dispatch("setIsSingleGeneTest", newValue && this.isMedicare);
        },
        getAugmentationSingleGene(allMeds, medList) {
            if ((allMeds || []).length === 0 || (medList || []).length === 0) {
                return "";
            }
            var meds = [];
            allMeds.forEach((med) => {
                var keyNames = Object.keys(med);
                if (keyNames.find((prop) => { return prop === 'FDA & CPIC A/B Meds'; })) {
                    meds = med['FDA & CPIC A/B Meds'];
                }
            });

            meds.forEach((med) => {
                if (med.value === medList[0]) {
                    this.augmentationGene = med.subText; // this value won't be used unless single gene
                }
            });
        },
        initAugmentationList(medValues) {
            var allMeds = [];
            var augmentationList = [];
            this.augmentationMedications.forEach((m1) => {
                medValues.forEach((medArr) => {
                    if (medArr['FDA & CPIC A/B Meds']) {
                        medArr['FDA & CPIC A/B Meds'].forEach((m2) => {
                            if (m1 === m2.value) {
                                allMeds.push(m2);
                                if (m2.subText) {
                                    augmentationList.push(m2);
                                }
                            }
                        });
                    } else if (medArr['Other Meds']) {
                        medArr['Other Meds'].forEach((m2) => {
                            if (m1 === m2) {
                                allMeds.push(m2);
                            }
                        });
                    }
                });
            });
            this.augmentationList = augmentationList;
            return allMeds;
        },
        disableHighlights(show) {
            this.$store.dispatch("setAugmentationHighlight", false);
            this.$store.dispatch("setDoseageHighlight", false);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~@myriad-neuroscience/myriad-neuro-multiselect/neuro-multiselect.css";
@import "../../../../styles/AuthorizeOrders.scss";

.augment-container
{
  margin-bottom: 35px;
  margin-left: 28px;
  margin-top: 6px;
}

.non-genetic-warning {
  margin-top: 30px;
}

.multiselect-single-gene-warning >>> .myriad-neuro-selected-input-container {
  border: 2px solid #ff6b0b !important;
}

.multiselect-add-medication >>> .myriad-neuro-selected-input-container,
.multiselect-add-medication >>> input[type=text].myriad-neuro-multiselect-input {
  background-color: #FFF0E7 !important;
}
</style>
