import { render, staticRenderFns } from "./ResetLinkSent.vue?vue&type=template&id=204b38e8&scoped=true&"
import script from "./ResetLinkSent.vue?vue&type=script&lang=js&"
export * from "./ResetLinkSent.vue?vue&type=script&lang=js&"
import style0 from "./ResetLinkSent.vue?vue&type=style&index=0&id=204b38e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.registry.npmjs.org/vue-loader/15.9.7_css-loader@3.6.0+webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204b38e8",
  null
  
)

export default component.exports