export default {
    state: {
        message: "",
        showRibbon: false
    },
    getters: {
        getNotificationMessage: state => {
            return state.message;
        },
        getShowNotificationRibbon: state => {
            return state.showRibbon;
        }
    },
    mutations: {
        setNotificationMessage(state, message) {
            state.message = message;
        },
        setShowNotificationRibbon(state) {
            state.showRibbon = true;
        },
        setHideNotificationRibbon(state) {
            state.showRibbon = false;
            state.message = "";
        }
    },
    actions: {
        showNotificationRibbon(context, message) {
            context.commit("setNotificationMessage", message);
            context.commit("setShowNotificationRibbon");
        },
        showDefaultNotificationRibbon(context) {
            context.commit("setNotificationMessage", "");
            context.commit("setShowNotificationRibbon");
        },
        setHideNotificationRibbon(context) {
            context.commit("setHideNotificationRibbon");
        }
    }
};
