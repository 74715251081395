<template>
    <div id="app">
        <transition name="fade">
            <div v-if="$store.getters.getShowFullScreenLoader" class="full-loader-container">
                <full-loader />
            </div>
        </transition>
        <nav-bar v-if="showNavBar" />
        <transition name="notification" mode="out-in">
            <notification-ribbon v-if="$store.getters.getShowNotificationRibbon" />
        </transition>
        <transition :name="transitionType" mode="out-in" @before-leave="beforeLeave" @after-enter="afterEnter">
            <router-view />
        </transition>
        <mygn-footer v-show="!hideFooter" />
    </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import MygnFooter from "./components/NeuroFooter.vue";
import NotificationRibbon from "./components/NotificationRibbon.vue";
import FullLoader from "./assets/loading.svg";
import Common from "./scripts/common.js";

export default {
    name: "App",
    components: {
        NavBar,
        MygnFooter,
        NotificationRibbon,
        FullLoader
    },
    data() {
        return {
            transitionType: "fade",
            hideFooter: false
        };
    },
    computed: {
        showNavBar() {
            return Common.showNavBar();
        }
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            if (to.meta.transitionType) {
                this.transitionType = to.meta.transitionType;
            } else {
                this.transitionType = "fade";
            }

            next();
        });
    },
    methods: {
        beforeLeave() {
            this.hideFooter = true;
        },
        afterEnter() {
            this.hideFooter = false;
        }
    }
};
</script>

<style lang="scss">
    @import "~@myriad-neuroscience/pulse-ui/scss/reset.scss";
    @import "./styles/_common.scss";

    .router-container {
        min-height: 100vh;
    }

    .full-loader-container {
        @include flex-center-vertical();
        position: fixed;
        top: 0;
        left: 0;
        min-height: 100vh;
        min-width: 100%;
        background: rgba(255, 255, 255, .9);
        z-index: 100;
    }

    .fade-enter-active, .fade-leave-active {
        position: absolute;
        transition: opacity .4s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .grow-enter-active, .grow-leave-active {
        transition: all .2s;
    }

    .grow-enter, .grow-leave-to {
        opacity: 0;
        max-height: 0;
    }

    .slide-enter-active, .slide-leave-active {
        transition-duration: 0.5s;
        transition-property: height, opacity, transform;
        transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
        overflow: hidden;
    }

    .slide-enter {
        opacity: 0;
        transform: translate(2em, 0);
    }

    .slide-leave-to {
        opacity: 0;
        transform: translate(-2em, 0);
    }

    .notification-enter-active, .notification-leave-active {
        transition: all 0.5s ease-in-out;
    }

    .notification-enter, .notification-leave-to {
        opacity: 0;
        max-height: 0px;
    }

    .notification-enter-to, .notification-leave {
        opacity: 1;
        max-height: 40px;
    }

</style>
