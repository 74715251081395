
import { HTTP } from "./http";

const RecordUserActionHttp = {
    CreateUserAction: (data) => {
        const url = config.url + "/RecordUserAction";
        return HTTP.post(url, data);
    }
};

export default RecordUserActionHttp;
