export default {
    state: {
        loggedIn: false,
        resetLoginPage: false,
        hasSignature: false,
        agreementIds: null,
        canOrderSupplies: false,
        canOrderTests: false,
        canViewReports: false
    },
    getters: {
        getLoggedIn: state => {
            return state.loggedIn;
        },
        getResetLoginPage: state => {
            return state.resetLoginPage;
        },
        getAgreementIds: state => {
            return state.agreementIds;
        },
        getCanOrderSupplies: state => {
            return state.canOrderSupplies;
        },
        getCanOrderTests: state => {
            return state.canOrderTests;
        },
        getCanViewReports: state => {
            return state.canViewReports;
        }
    },
    mutations: {
        setLoggedIn(state, value) {
            state.loggedIn = value;
        },
        setResetLoginPage(state) {
            state.resetLoginPage = true;

            window.setTimeout(() => {
                state.resetLoginPage = false;
            }, 1000);
        },
        setAgreementIds(state, value) {
            state.agreementIds = value;
        },
        setPermissions(state, permissionsData) {
            state.canOrderSupplies = permissionsData.canOrderSupplies;
            state.canOrderTests = permissionsData.canOrderTests;
            state.canViewReports = permissionsData.canViewReports;
        }
    },
    actions: {
        setLoggedIn(context) {
            context.commit("setLoggedIn", true);
        },
        setLoggedOff(context) {
            context.commit("setLoggedIn", false);
        },
        setAgreementIds(context, agreementIds) {
            context.commit("setAgreementIds", agreementIds);
        },
        setSignatureAdded(context) {
            context.commit("setHasSignature", true);
        },
        setPermissions(context, permissionsData) {
            context.commit("setPermissions", permissionsData);
        },
        setResetLoginPage(context) {
            context.commit("setResetLoginPage");
        }
    }
};
