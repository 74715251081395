import Axios from "axios";
import Common from "../common.js";

Axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        var hasUnauthHeader = error.response.headers["myriad-unauthorized"];

        if (error.response.status === 401 || hasUnauthHeader) {
            Common.logOff();
        }

        return Promise.reject(error);
    }
);

export const HTTP = {
    timeOut: 1200000,
    timeOutTimer: null,
    params: {
        withCredentials: true
    },

    put: (uri, data) => {
        return HTTP.GetAntiForgery().then(response => {
            HTTP.ResetTimeOut();
            HTTP.SetAntiForgery(response);
            return Axios.put(uri, data, HTTP.params);
        });
    },
    get: (uri, data) => {
        if (uri.indexOf(config.url) >= 0) {
            HTTP.ResetTimeOut();
            Axios.defaults.withCredentials = true;
            return Axios.get(uri, data);
        } else {
            const instance = Axios.create();
            instance.defaults.headers.common = {};
            instance.defaults.headers.common.accept = "application/json";
            instance.defaults.withCredentials = false;
            return instance.get(uri, data);
        }
    },
    post: (uri, data) => {
        return HTTP.GetAntiForgery().then(response => {
            HTTP.ResetTimeOut();
            HTTP.SetAntiForgery(response);
            return Axios.post(uri, data, HTTP.params);
        });
    },
    GetAntiForgery: () => {
        const uri = config.url + "/AntiForgery";
        return Axios.get(uri, HTTP.params);
    },
    SetAntiForgery: (response) => {
        Axios.defaults.headers.common[response.data.formFieldName] = response.data.requestToken; // for all requests
    },
    ResetTimeOut: () => {
        window.clearTimeout(HTTP.timeOutTimer);
        HTTP.timeOutTimer = window.setTimeout(() => {
            Common.logOff();
        }, HTTP.timeOut);
    }
};
