<template>
    <div class="Failed-Medications-2020">
        <h4 class="sub-section-header">
            Failed Medications
        </h4>
        <label class="sub-section-label">
            List one or more psychiatric medications that have <b>failed to work</b> for this patient
            <span class="sub-section-gray-text">
                (previously or currently prescribed)
            </span>
        </label>
        <error-wrapper
            :error-exists="!$store.getters.failedMedsSupplied || isFailedMedsError"
            error-message="Please provide a response."
            :warning-exists="isFailedMedsWarning"
            warning-message="If possible, please provide at least one specific medication name"
        >
            <div class="failed-meds-multiselect-container">
                <div class="failed-meds-multiselect" />
            </div>
        </error-wrapper>
    </div>
</template>

<script>
import { NeuroMultiselect } from "@myriad-neuroscience/myriad-neuro-multiselect/neuro-multiselect.js";
import ErrorWrapper from "../../ErrorWrapper.vue";
import { BlackGrayWarningsEnum } from "../../../../scripts/enums.js";
import RecordUserActionHttp from "../../../../scripts/http/record-user-action-http";
import Common from "../../../../scripts/common.js";

export default {
    name: "FailedMedications2020",
    components: {
        ErrorWrapper
    },
    data() {
        return {
            mulitselectCreated: false
        };
    },
    computed: {
        allMeds() {
            return this.$store.getters.getAllMeds;
        },
        isFailedMedsError() {
            return this.$store.getters.failedMedsGrayBlackListError === BlackGrayWarningsEnum.ERROR;
        },
        isFailedMedsWarning() {
            return this.$store.getters.failedMedsGrayBlackListError === BlackGrayWarningsEnum.WARNING;
        }
    },
    watch: {
        allMeds(medValues) {
            this.createMultiselect(medValues);
        }
    },
    mounted() {
        this.createMultiselect(this.allMeds);
    },
    methods: {
        saveFailedMeds(meds) {
            const stringArray = [];
            meds.forEach((med) => {
                stringArray.push(med.value);
            });

            this.$store.dispatch("set2020FailedMeds", stringArray);
        },
        createMultiselect(medValues) {
            const domElement = document.querySelector(".failed-meds-multiselect");
            domElement.innerHTML = "";

            const failedMedsMultiselect = new NeuroMultiselect();
            const failedMeds = this.$store.getters.get2020FailedMeds;
            failedMedsMultiselect.init(
                domElement,
                medValues,
                failedMeds,
                {
                    allowCustomInputs: true,
                    noneString: "None have failed.",
                    placeholderString: "Type or select a medication"
                }
            );

            const that = this;
            failedMedsMultiselect.setupCallbackReturn(function(data) {
                const doseageMultiselect = that.$store.getters.getDoseageMultiselect;
                const augmentMultiselect = that.$store.getters.getAugmentationMultiselect;

                if (Common.isEmptyOrNull(failedMedsMultiselect.state.previousSelectedValues)) {
                    failedMedsMultiselect.state.previousSelectedValues = JSON.parse(JSON.stringify(data));
                }

                that.saveFailedMeds(data);

                that.TrackUserBehaviorAdjustingFailedMeds(data, doseageMultiselect, augmentMultiselect, failedMedsMultiselect);

                if (data && data[0] && data[0].value.toLowerCase() === "none have failed.") {
                    doseageMultiselect.resetValuesToEmpty();
                    that.$store.dispatch("setDisableDoseageAdjustment", true);
                } else {
                    const doseageValues = JSON.parse(JSON.stringify(data));
                    doseageMultiselect.updateSelectableValues(doseageValues);
                    that.$store.dispatch("setDisableDoseageAdjustment", false);
                }
                doseageMultiselect.markValuesAsDisabled(that.$store.getters.getAugmentationMultiselect.state.selectedValues);
            });

            this.mulitselectCreated = true;

            this.$store.dispatch("setFailedMedsMultiselect", failedMedsMultiselect);
        },
        TrackUserBehaviorAdjustingFailedMeds(data, doseageMultiselect, augmentMultiselect, failedMedsMultiselect) {
            const hasActionOccuredInTreatmentBox = doseageMultiselect.state.selectedValues.length > 0 || augmentMultiselect.state.selectedValues.length > 0;
            const hasSelectedValuesChanged = failedMedsMultiselect.state.previousSelectedValues != null &&
                JSON.stringify(data) !== JSON.stringify(failedMedsMultiselect.state.previousSelectedValues);

            if (hasActionOccuredInTreatmentBox && hasSelectedValuesChanged) {
                const data = {
                    id: this.$store.getters.getCurrentOrderId,
                    actionType: "Adjusted failed medications list during auth",
                    actionDetail: "After taking action in treatment plan box"
                };
                RecordUserActionHttp.CreateUserAction(data);
            }

            const selectedValuesDeepCopy = JSON.parse(JSON.stringify(data));
            failedMedsMultiselect.state.previousSelectedValues = selectedValuesDeepCopy;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~@myriad-neuroscience/myriad-neuro-multiselect/neuro-multiselect.css";
@import "../../../../styles/AuthorizeOrders.scss";

.failed-meds-multiselect-container {
    margin-top: 5px;
}
</style>
