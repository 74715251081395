<template>
    <div>
        <p class="section-gray-text">
            ICD-10 Code(s)
        </p>
        <error-wrapper
            ref="diagCodeErrorWrapper"
            class="diag-code-error-wrapper"
            :error-exists="diagnosticCodeErrorExists"
            :error-message="diagCodeErrorString"
            :is-dirty="diagnosticCodesDirty"
            :warn-at-start="true"
            :warning-exists="!hasDiagCodeMedicareEligible && $store.getters.getCurrentOrderIsMedicare && !diagnosticCodeErrorExists"
            warning-message="At least one covered ICD-10 code must be entered for this order to meet Medicare coverage"
        >
            <div v-if="isMthfr" class="diagnostic-code-input-row">
                <autocomplete
                    id="mthfrDiagCode1"
                    key="mthfrDiagCode1"
                    v-model="mthfrDiagCode1"
                    :mthfr="true"
                    @autocomplete-used="diagnosticCodesDirty = true"
                />
                <p class="diagnostic-code-desc">
                    {{ mthfrDiagCode1.description }}
                </p>
            </div>
            <div v-else class="diagnostic-code-input-row">
                <autocomplete
                    id="diagCode1"
                    key="diagCode1"
                    v-model="diagCode1"
                    @autocomplete-used="diagnosticCodesDirty = true"
                />
                <p class="diagnostic-code-desc">
                    {{ diagCode1.description }}
                </p>
            </div>
            <div v-if="isMthfr && showMthfrSecondaryDiagCode1" class="diagnostic-code-input-row">
                <autocomplete
                    id="mthfrSecondaryDiagCode1"
                    key="mthfrSecondaryDiagCode1"
                    v-model="mthfrSecondaryDiagCode1"
                    :optional="true"
                    :mthfr="true"
                    @autocomplete-used="diagnosticCodesDirty = true"
                />
                <p class="diagnostic-code-desc">
                    {{ mthfrSecondaryDiagCode1.description }}
                </p>
            </div>
            <div v-else-if="!isMthfr && showSecondaryDiagCode1" class="diagnostic-code-input-row">
                <autocomplete
                    id="secondaryDiagCode1"
                    key="secondaryDiagCode1"
                    v-model="secondaryDiagCode1"
                    :optional="true"
                    @autocomplete-used="diagnosticCodesDirty = true"
                />
                <p class="diagnostic-code-desc">
                    {{ secondaryDiagCode1.description }}
                </p>
            </div>
            <div v-if="isMthfr && showMthfrSecondaryDiagCode2" class="diagnostic-code-input-row">
                <autocomplete
                    id="mthfrSecondaryDiagCode2"
                    key="mthfrSecondaryDiagCode2"
                    v-model="mthfrSecondaryDiagCode2"
                    :optional="true"
                    :mthfr="true"
                    @autocomplete-used="diagnosticCodesDirty = true"
                />
                <p class="diagnostic-code-desc">
                    {{ mthfrSecondaryDiagCode2.description }}
                </p>
            </div>
            <div v-else-if="!isMthfr && showSecondaryDiagCode2" class="diagnostic-code-input-row">
                <autocomplete
                    id="secondaryDiagCode2"
                    key="secondaryDiagCode2"
                    v-model="secondaryDiagCode2"
                    :optional="true"
                    @autocomplete-used="diagnosticCodesDirty = true"
                />
                <p class="diagnostic-code-desc">
                    {{ secondaryDiagCode2.description }}
                </p>
            </div>
            <div v-if="isMthfr && showMthfrSecondaryDiagCode3" class="diagnostic-code-input-row">
                <autocomplete
                    id="mthfrSecondaryDiagCode3"
                    key="mthfrSecondaryDiagCode3"
                    v-model="mthfrSecondaryDiagCode3"
                    :optional="true"
                    :mthfr="true"
                    @autocomplete-used="diagnosticCodesDirty = true"
                />
                <p class="diagnostic-code-desc">
                    {{ mthfrSecondaryDiagCode3.description }}
                </p>
            </div>
            <div v-else-if="!isMthfr && showSecondaryDiagCode3" class="diagnostic-code-input-row">
                <autocomplete
                    id="secondaryDiagCode3"
                    key="secondaryDiagCode3"
                    v-model="secondaryDiagCode3"
                    :optional="true"
                    @autocomplete-used="diagnosticCodesDirty = true"
                />
                <p class="diagnostic-code-desc">
                    {{ secondaryDiagCode3.description }}
                </p>
            </div>
        </error-wrapper>
    </div>
</template>

<script>
import Autocomplete from "./DiagnosticCodeAutocomplete.vue";
import ErrorWrapper from "../ErrorWrapper.vue";
import Common from "../../../scripts/common.js";
import { DepressionSeverity, DepressionSeverityStrings } from "../../../scripts/enums.js";
import { ICD10Strings } from "../../../scripts/strings.js";

export default {
    name: "DiagnosticCodes",
    components: {
        Autocomplete,
        ErrorWrapper
    },
    props: {
        isMthfr: {
            default: false,
            required: false,
            type: Boolean
        }
    },
    data() {
        return {
            showSecondaryDiagCode1: false,
            showSecondaryDiagCode2: false,
            showSecondaryDiagCode3: false,
            showMthfrSecondaryDiagCode1: false,
            showMthfrSecondaryDiagCode2: false,
            showMthfrSecondaryDiagCode3: false,
            diagnosticCodesDirty: false
        };
    },
    computed: {
        diagCodeError() {
            const diagCodeValue = this.isMthfr ? this.mthfrDiagCode1.diagnosticCodeValue : this.diagCode1.diagnosticCodeValue;
            return Common.isEmptyOrNull(diagCodeValue);
        },
        diagCodeSeverityError() {
            return this.isMthfr ? false : !this.diagCodeError && this.$store.getters.getDsm5SufferFromDepression && !this.hasOneDiagCodeMatchingSeverity();
        },
        diagCodeErrorString() {
            if (Common.isEmptyOrNull(this.isMthfr ? this.mthfrDiagCode1.diagnosticCodeValue : this.diagCode1.diagnosticCodeValue)) {
                if (this.$refs.diagCodeErrorWrapper && !this.$refs.diagCodeErrorWrapper.isDirty && !this.isMthfr) {
                    return ICD10Strings.ICDMissingAtStart;
                } else {
                    return ICD10Strings.ICDMissing;
                }
            } else {
                return ICD10Strings.SeverityMismatchError;
            }
        },
        hasDiagCodeMedicareEligible() {
            if (this.isMthfr) {
                return true;
            }

            const diagCode1Compliant = this.checkIfDiagCodeCompliant(this.diagCode1);
            const secondaryDiagCode1Compliant = this.checkIfDiagCodeCompliant(this.secondaryDiagCode1);
            const secondaryDiagCode2Compliant = this.checkIfDiagCodeCompliant(this.secondaryDiagCode2);
            const secondaryDiagCode3Compliant = this.checkIfDiagCodeCompliant(this.secondaryDiagCode3);

            return diagCode1Compliant || secondaryDiagCode1Compliant || secondaryDiagCode2Compliant || secondaryDiagCode3Compliant;
        },
        diagCode1: {
            get() {
                return this.$store.getters.getDiagCode;
            },
            set(value) {
                this.$store.dispatch("setDiagCode", value);
            }
        },
        secondaryDiagCode1: {
            get() {
                return this.$store.getters.getSecondaryDiagCode1;
            },
            set(value) {
                this.$store.dispatch("setSecondaryDiagCode1", value);
            }
        },
        secondaryDiagCode2: {
            get() {
                return this.$store.getters.getSecondaryDiagCode2;
            },
            set(value) {
                this.$store.dispatch("setSecondaryDiagCode2", value);
            }
        },
        secondaryDiagCode3: {
            get() {
                return this.$store.getters.getSecondaryDiagCode3;
            },
            set(value) {
                this.$store.dispatch("setSecondaryDiagCode3", value);
            }
        },
        mthfrDiagCode1: {
            get() {
                return this.$store.getters.getMthfrDiagCode;
            },
            set(value) {
                this.$store.dispatch("setMthfrDiagCode", value);
            }
        },
        mthfrSecondaryDiagCode1: {
            get() {
                return this.$store.getters.getMthfrSecondaryDiagCode1;
            },
            set(value) {
                this.$store.dispatch("setMthfrSecondaryDiagCode1", value);
            }
        },
        mthfrSecondaryDiagCode2: {
            get() {
                return this.$store.getters.getMthfrSecondaryDiagCode2;
            },
            set(value) {
                this.$store.dispatch("setMthfrSecondaryDiagCode2", value);
            }
        },
        mthfrSecondaryDiagCode3: {
            get() {
                return this.$store.getters.getMthfrSecondaryDiagCode3;
            },
            set(value) {
                this.$store.dispatch("setMthfrSecondaryDiagCode3", value);
            }
        },
        diagnosticCodeErrorExists() {
            return this.diagCodeError || this.diagCodeSeverityError;
        }
    },
    watch: {
        diagCode1(newValue) {
            if (!Common.isEmptyOrNull(newValue.diagnosticCodeValue)) {
                this.showSecondaryDiagCode1 = true;
            }
        },
        secondaryDiagCode1(newValue) {
            if (!Common.isEmptyOrNull(newValue.diagnosticCodeValue)) {
                this.showSecondaryDiagCode2 = true;
            }
        },
        secondaryDiagCode2(newValue) {
            if (!Common.isEmptyOrNull(newValue.diagnosticCodeValue)) {
                this.showSecondaryDiagCode3 = true;
            }
        },
        mthfrDiagCode1(newValue) {
            if (!Common.isEmptyOrNull(newValue.diagnosticCodeValue)) {
                this.showMthfrSecondaryDiagCode1 = true;
            }
        },
        mthfrSecondaryDiagCode1(newValue) {
            if (!Common.isEmptyOrNull(newValue.diagnosticCodeValue)) {
                this.showMthfrSecondaryDiagCode2 = true;
            }
        },
        mthfrSecondaryDiagCode2(newValue) {
            if (!Common.isEmptyOrNull(newValue.diagnosticCodeValue)) {
                this.showMthfrSecondaryDiagCode3 = true;
            }
        },
        diagCodeError(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.checkForDiagCodeError(newValue);
            }
        },
        diagCodeSeverityError(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.checkForSeverDiagCodeError(newValue);
            }
        }
    },
    mounted() {
        if (this.isMthfr) {
            this.setMthfrShowCodes();
        }

        this.checkForDiagCodeError(this.diagCodeError);
        this.checkForSeverDiagCodeError(this.diagCodeSeverityError);
    },
    methods: {
        hasOneDiagCodeMatchingSeverity() {
            let foundAMatch = false;

            const currentSeverity = this.$store.getters.getCurrentDepressionSeverity;
            if (Common.isEmptyOrNull(currentSeverity)) {
                return true;
            }

            const diagCode = this.isMthfr ? Common.checkForCodeInDiagCodePrefillList(this.mthfrDiagCode1) : Common.checkForCodeInDiagCodePrefillList(this.diagCode1);
            const secondaryDiagCode1 = this.isMthfr ? Common.checkForCodeInDiagCodePrefillList(this.mthfrSecondaryDiagCode1) : Common.checkForCodeInDiagCodePrefillList(this.secondaryDiagCode1);
            const secondaryDiagCode2 = this.isMthfr ? Common.checkForCodeInDiagCodePrefillList(this.mthfrSecondaryDiagCode2) : Common.checkForCodeInDiagCodePrefillList(this.secondaryDiagCode2);
            const secondaryDiagCode3 = this.isMthfr ? Common.checkForCodeInDiagCodePrefillList(this.mthfrSecondaryDiagCode3) : Common.checkForCodeInDiagCodePrefillList(this.secondaryDiagCode3);

            if (diagCode && diagCode.depressionSeverity && this.checkIfDepressionSeverityMatches(diagCode.depressionSeverity, currentSeverity)) {
                foundAMatch = true;
            }

            if (secondaryDiagCode1 && secondaryDiagCode1.depressionSeverity && this.checkIfDepressionSeverityMatches(secondaryDiagCode1.depressionSeverity, currentSeverity)) {
                foundAMatch = true;
            }

            if (secondaryDiagCode2 && secondaryDiagCode2.depressionSeverity && this.checkIfDepressionSeverityMatches(secondaryDiagCode2.depressionSeverity, currentSeverity)) {
                foundAMatch = true;
            }

            if (secondaryDiagCode3 && secondaryDiagCode3.depressionSeverity && this.checkIfDepressionSeverityMatches(secondaryDiagCode3.depressionSeverity, currentSeverity)) {
                foundAMatch = true;
            }

            return foundAMatch;
        },
        checkIfDepressionSeverityMatches(severityStringToCheck, currentSeverity) {
            const severityValue = this.getDepressionSeverityEnumValue(severityStringToCheck);
            return severityValue === currentSeverity || severityValue === DepressionSeverity.ALL;
        },
        getDepressionSeverityEnumValue(stringValue) {
            switch (stringValue) {
            case DepressionSeverityStrings.MILD:
                return DepressionSeverity.MILD;
            case DepressionSeverityStrings.MODERATE:
                return DepressionSeverity.MODERATE;
            case DepressionSeverityStrings.SEVERE:
                return DepressionSeverity.SEVERE;
            default:
                return DepressionSeverity.ALL;
            }
        },
        setMthfrShowCodes() {
            if (!Common.isEmptyOrNull(this.mthfrDiagCode1.diagnosticCodeValue)) {
                this.showMthfrSecondaryDiagCode1 = true;
            }
            if (!Common.isEmptyOrNull(this.mthfrSecondaryDiagCode1.diagnosticCodeValue)) {
                this.showMthfrSecondaryDiagCode1 = true;
                this.showMthfrSecondaryDiagCode2 = true;
            }
            if (!Common.isEmptyOrNull(this.mthfrSecondaryDiagCode2.diagnosticCodeValue)) {
                this.showMthfrSecondaryDiagCode2 = true;
                this.showMthfrSecondaryDiagCode3 = true;
            }
            if (!Common.isEmptyOrNull(this.mthfrSecondaryDiagCode3.diagnosticCodeValue)) {
                this.showMthfrSecondaryDiagCode3 = true;
            }
        },
        checkIfDiagCodeCompliant(diagCodeObj) {
            let isCodeCompliant;

            if (diagCodeObj.isCodeCompliant) {
                isCodeCompliant = diagCodeObj.isCodeCompliant;
            } else {
                isCodeCompliant = diagCodeObj.depressionSeverity && diagCodeObj.depressionSeverity !== "Mild";
            }

            return isCodeCompliant;
        },
        checkForDiagCodeError(newValue) {
            if (newValue) {
                this.$store.dispatch("addDiagnosisError", this.$store.getters.getDiagnosticCodeErrorString);
            } else {
                this.$store.dispatch("removeDiagnosisError", this.$store.getters.getDiagnosticCodeErrorString);
            }
        },
        checkForSeverDiagCodeError(newValue) {
            if (newValue) {
                this.$store.dispatch("addDiagnosisError", this.$store.getters.getDiagCodeSeverityErrorString);
            } else {
                this.$store.dispatch("removeDiagnosisError", this.$store.getters.getDiagCodeSeverityErrorString);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../../../styles/AuthorizeOrders.scss";

    .diag-code-error-wrapper {
        padding-top: 12px;
    }

    .diagnostic-code-input-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 5px;
    }

    .diagnostic-code-desc {
        margin-left: 10px;
        font-size: $regular-copy-size;
    }

    .diagnostic-code-warn-bg {
        position: relative;
        top: -37px;
        background: $yellow-bg;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .diagnostic-code-warn-header {
        position: relative;
        width: 95%;
        margin: 0 auto;
        background: $yellow-warn-bg;
        border-radius: 8px;
        padding: 20px 15px;
        text-align: center;
        z-index: 10;
    }
</style>
