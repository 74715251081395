<template>
    <div class="labeled-input-container">
        <input
            v-if="maskType == ''"
            ref="input"
            :type="inputType"
            :class="classString + ' labeled-input'"
            :data-neuro-auto="automationValue"
            :value="value"
            @input="updateValue()"
            @blur="$emit('blur')"
        >
        <input
            v-else
            ref="input"
            v-mask="maskType"
            :type="inputType"
            :class="classString + ' labeled-input'"
            :data-neuro-auto="automationValue"
            :value="value"
            @input="updateValue()"
            @blur="$emit('blur')"
        >
        <label ref="labeledInputLabel" :class="labelClass">
            {{ placeholder }}
        </label>
    </div>
</template>

<script>
import Common from "../scripts/common.js";

export default {
    name: "LabeledInput",
    props: {
        placeholder: {
            type: String,
            required: true
        },
        classString: {
            type: String,
            required: false,
            default: ""
        },
        value: {
            type: String,
            required: true
        },
        inputType: {
            type: String,
            required: false,
            default: "text"
        },
        maskType: {
            type: String,
            required: false,
            default: ""
        },
        automationValue: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            pinLabel: false
        };
    },
    computed: {
        labelClass() {
            return this.pinLabel ? "anim-label anim-label--pinned" : "anim-label";
        }
    },
    mounted() {
        if (!Common.isEmptyOrNull(this.value)) {
            this.updatePinLabel();
        }
    },
    methods: {
        updateValue() {
            this.updatePinLabel();
            this.$emit("input", this.$refs.input.value);
        },
        updatePinLabel() {
            this.pinLabel = !Common.isEmptyOrNull(this.$refs.input.value);
        }
    }
};
</script>

<style lang="scss" scoped>
.labeled-input-container {
    position: relative;
}

.labeled-input {
    width: 100%;
    height: 35px;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid $border-grey;
    border-radius: 5px;
    font-size: 1em;
    z-index: 10;

    &::placeholder {
        color: $light-gray-text;
    }
}

.labeled-input:-webkit-autofill ~ .anim-label {
    @include  anim-label-pinned();
}

.anim-label {
    position: absolute;
    top: 8px;
    left: 7px;
    padding-left: 2px;
    padding-right: 5px;
    color: $light-gray-text;
    font-size: 1em;
    background: white;
    transition: all .2s ease-in-out;
    z-index: 0;
    pointer-events: none;
}

.anim-label--pinned {
    @include  anim-label-pinned();
}

.labeled-input:focus + label {
    font-size: 0.7em;
    top: -7px;
    color: $text-color;
}
</style>
