<template>
    <div class="treatment-plan">
        <h4 id="treatment-plan-header" class="sub-section-header">
            Treatment Plan
        </h4>
        <label class="sub-section-label">
            How does pharmacogenomic testing fit into your treatment plan for this patient?
            <span class="sub-section-gray-text">
                (select all that apply)
            </span>
        </label>
        <error-wrapper
            :error-exists="!$store.getters.isAugmentOrDoseageTreatmentPlanSelected"
            error-message="Please provide a response."
        >
            <myriad-check
                id="augmentingTherapy"
                v-model="isConsideringAugmenting"
                name="augmentingTherapy"
                label="I'm considering <b>augmenting</b> therapy with a new medication or <b>starting/switching to</b> a new medication"
                class="augment-therapry-checkbox"
                :model-property="isConsideringAugmenting"
                @check-selection="trackUserBehavior"
            />
            <AugmentingMedication />
            <myriad-check
                id="doseageAdjustment"
                v-model="isConsideringDoseageChange"
                name="doseageAdjustment"
                label="I'm considering a <b>dosage adjustment</b> to currently prescribed medication(s)"
                class="doseage-adjustment-checkbox"
                :model-property="isConsideringDoseageChange"
                :disabled="$store.getters.getDisableDoseageAdjustment"
                @check-selection="trackUserBehavior"
            />
            <p v-if="$store.getters.getDisableDoseageAdjustment" class="treatment-plan-doseage-diable-notice">
                This option is only available if at least one GeneSight medication is entered in the Failed Medications field above.
            </p>
            <Doseage />
        </error-wrapper>
    </div>
</template>

<script>
import MyriadCheck from "../../../../components/MyriadCheck.vue";
import ErrorWrapper from "../../ErrorWrapper.vue";
import Common from "../../../../scripts/common.js";
import AugmentingMedication from "./AugmentingMedication.vue";
import Doseage from './Doseage.vue';

export default {
    name: "TreatmentPlan",
    components: {
        MyriadCheck,
        ErrorWrapper,
        AugmentingMedication,
        Doseage
    },
    data() {
        return {
            test2: false
        };
    },
    computed: {
        isConsideringAugmenting: {
            get() {
                return this.$store.getters.getIsConsideringAugmentingWithNewMedication;
            },
            set(value) {
                this.$store.dispatch("setIsConsideringAugmentingWithNewMedication", value);
            }
        },
        isConsideringDoseageChange: {
            get() {
                return this.$store.getters.getIsConsideringDoseageAdjustment;
            },
            set(value) {
                this.$store.dispatch("setIsConsideringDoseageAdjustment", value);
            }
        }
    },
    watch: {
        isConsideringAugmenting(newValue) {
            if (!newValue) {
                const doseageMultiselect = this.$store.getters.getDoseageMultiselect;
                doseageMultiselect.markValuesAsDisabled([]);
            }
        }
    },
    methods: {
        trackUserBehavior() {
            Common.trackUserBehaviorInResponseToGeneWarnings();
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/AuthorizeOrders.scss";

.augment-therapry-checkbox,
.doseage-adjustment-checkbox {
    margin-top: 5px;
    align-items: flex-start;
}

.doseage-adjustment-checkbox {
    margin-top: 15px;
}

::v-deep .augment-therapry-checkbox .myriad-checkbox-label,
::v-deep .doseage-adjustment-checkbox .myriad-checkbox-label {
    font-size: $sub-section-label-size;
}

.treatment-plan-doseage-diable-notice {
    padding-left: 28px;
    color: $border-grey;;
    font-size: $small-font-size;
}
</style>
