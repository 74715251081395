<template>
    <div class="privacy-page">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="notice-contents" v-html="privacyHtml" />
    </div>
</template>

<script>
import PrivacyHttp from "../scripts/http/privacy-http.js";

export default {
    name: "Privacy",
    data() {
        return {
            privacyHtml: ""
        };
    },
    mounted() {
        this.getNotice(this.$route.params.noticeId);
    },
    beforeRouteUpdate(to, from, next) {
        this.getNotice(to.params.noticeId);
        next();
    },
    methods: {
        getNotice(noticeId) {
            PrivacyHttp.GetNotice(noticeId).then((response) => {
                this.$data.privacyHtml = response.data.content;
            }).catch(() => {
                this.$store.dispatch("showDefaultNotificationRibbon");
            });
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .notice-contents {
    margin: 0 auto;
    width: 95%;

    .mygn_pn {
        font-family: 'Lato', sans-serif;
        max-width: 1300px;
        line-height: 28px;
        color: #666;
    }

    .mygn_pn h2,
    .mygn_pn h3,
    .mygn_pn h4,
    .mygn_pn h5,
    .mygn_pn h6 {
        margin: 30px 0 10px 0;
        font-weight: bold;
    }

    .mygn_pn h2 {
        font-family: 'Raleway', sans-serif;
        color: #7cbe4b;
    }

    .mygn_pn h3 {
        color: #333;
    }

    .mygn_pn h4 {
        color: #333;
    }

    .mygn_pn h5 {
        color: #333;
    }

    .mygn_pn h6 {
        color: #333;
    }

    .mygn_pn .mygn_notice_table tr td {
        line-height: 28px;
    }

    .mygn_pn .mygn_notice_table tr:nth-child(odd) td {
        padding: 4px;
        line-height: 28px;
    }

    a, a:active, a:visited {
        color: #00a9e0;
        text-decoration: none;
    }

    a:hover, a:visited:hover {
        text-decoration: underline;
    }

    ol, ul {
        list-style: inherit;
        padding-left: 40px;
    }

    p {
        margin-top: 16px;
        margin-bottom: 16px;
    }
}
</style>
