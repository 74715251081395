<template>
    <div class="gene-alert">
        <div class="gene-alert-header-section">
            <h4 v-if="showZeroGeneMessage" class="gene-alert-header sub-section-header">
                {{ headerText }}
            </h4>
            <div v-else class="single-gene-alert-header">
                <exclaim-icon class="authorization-sg-header-icon" />
                This Medicare order will result in a Single Gene Report, not the Genesight Psychotropic Report.
            </div>
        </div>
        <div v-if="showZeroGeneMessage">
            <p v-if="isAbnWarning" class="gene-alert-text sub-section-label">
                Medicare has stipulated in
                <a class="mobile-link" :href="lcdLink" target="_blank">
                    LCD L38394
                </a>
                that providers need to first consider non-genetic factors prior to seeking PGx tests. Providers must affirmatively attest that they have first selected potential medications based on non-genetic factors.
            </p>
            <p v-else class="gene-alert-text sub-section-label">
                Medicare has stipulated in
                <a class="mobile-link" :href="lcdLink" target="_blank">
                    LCD L38394
                </a>
                that PGx tests are covered when providers are considering medication(s) with a potential gene-drug interaction as defined by CPIC or the FDA. The selected medication(s) do not have potential gene-drug interactions recognized by CPIC or the FDA.
            </p>
            <hr class="gene-horizontal-rule">
            <p class="sub-section-header gene-alert-text">
                Options for proceeding with this order:
            </p>
            <ul class="gene-alert-list">
                <li class="gene-alert-list-item">
                    <p v-if="isAbnWarning" class="gene-alert-text sub-section-label">
                        <b>
                            If you have considered other factors for this patient based on non-genetic factors prior to seeking PGx testing, please update your answer above to proceed with this order.
                        </b>
                    </p>
                    <p v-else class="gene-alert-text sub-section-label">
                        <b>
                            If you are considering other medication(s) for this patient, you may add them in the box above.
                        </b>
                        Selecting medications with potential gene-drug interactions in the drop down above will enable you to receive a test report without securing an Advance Beneficiary Notice (ABN).
                    </p>
                </li>
            </ul>
            <div class="gene-alert-or-section">
                <hr class="gene-horizontal-rule">
                <div class="gene-alert-or">
                    or
                </div>
            </div>
            <ul class="gene-alert-list">
                <li class="gene-alert-list-item">
                    <p class="gene-alert-text sub-section-label">
                        <b>
                            If you do not take additional action, an ABN will need to be signed by the patient
                        </b>
                        to complete this order and receive a report for this non-covered test.
                        <span v-if="isZeroGeneTest && !hasConsideredNonGeneticFactors">
                            To submit an ABN, please follow the steps for submission in the alert above.
                        </span>
                    </p>
                </li>
            </ul>
            <p v-if="(isZeroGeneTest && hasConsideredNonGeneticFactors) || (!isZeroGeneTest && !hasConsideredNonGeneticFactors)" class="mobile-specific-message gene-alert-text sub-section-label">
                To upload a patient-signed ABN, access myGeneSight.com via a desktop or laptop computer and click the "Incomplete" link for this order on the home page and navigate to the Test page.
            </p>
            <p v-if="!isZeroGeneTest && !hasConsideredNonGeneticFactors && !isMultiGeneTest" class="mobile-specific-message gene-alert-text sub-section-label">
                <i>Note: An order with a patient-signed ABN will result in a GeneSight Psychtropic test.</i>
            </p>
        </div>
        <div v-else>
            <p class="gene-alert-text sub-section-label">
                Selecting medications that interact with <b>single genes*</b> will result in a <b>Single Gene Report.</b>
            </p>
            <p class="gene-alert-text sub-section-label">
                Selecting medications that interact with <b>multiple genes*</b> will result in a <b>Genesight Psychotropic Report.</b>
            </p>
            <button class="add-medication-button" @click="handleAddMedicationsClick">Add Medication(s) Now</button>
            <div v-if="!showSingleGeneWarningDetails">
                <div class="single-gene-warning__toggle" @click="showSingleGeneWarningDetails = true">Show details</div>
            </div>
            <div v-else>
                <div class="single-gene-warning__toggle single-gene-warning__toggle--hide" @click="showSingleGeneWarningDetails = false">Hide details</div>
                <p v-if="medicationList.length > 1" class="gene-alert-text sub-section-label">You selected multiple medications with a single gene interaction*:</p>
                <p v-else class="gene-alert-text sub-section-label">You selected one medication with a single gene interaction*:</p>
                <ul>
                    <li v-for="medication in medicationList" :key="medication.value" class="single-gene-warning__list">
                        {{ medication.value }} {{ medication.subText }}
                    </li>
                </ul>
                <p class="gene-alert-text sub-section-label">Based on current medication selection for this Medicare order, a {{ singleGeneSubtext.replace(' only', '') }} Single Gene Report will be delivered.</p>
                <a class="mobile-link lcd-link" :href="lcdLink" target="_blank">
                    View Medicare LCD L38394
                </a>
            </div>
            <div v-if="!showSingleGeneWarningComparison">
                <div class="single-gene-warning__toggle" @click="showSingleGeneWarningComparison = true">Show report comparison</div>
            </div>
            <div v-else>
                <div class="single-gene-warning__toggle single-gene-warning__toggle--hide" @click="showSingleGeneWarningComparison = false">Hide report comparison</div>
                <div class="report-box">
                    <div class="report-box__header">
                        <div class="report-box__header-copy">Single Gene Report</div>
                        <img class="error-img" src="../../../../assets/small-myriad-genetics-logo-transparent.png">
                    </div>
                    <div class="report-box__signs">
                        <div class="report-box__alert report-box__alert--blue"></div>
                    </div>
                    <div class="report-box__detail">
                        <div class="report-box__detail-header">Contents of Report:</div>
                        <ul>
                            <li>genotype and phenotype of {{ singleGeneSubtext.replace(' only', '') }} gene only</li>
                        </ul>
                    </div>
                </div>
                <div class="report-box">
                    <div class="report-box__header">
                        <div class="report-box__header-copy">GeneSight Psychotrophic Report</div>
                        <img class="error-img" src="../../../../assets/genesightlogo-transparent.png">
                    </div>
                    <div class="report-box__signs">
                        <div class="report-box__alert report-box__alert--green"></div>
                        <div class="report-box__alert report-box__alert--yellow"></div>
                        <div class="report-box__alert report-box__alert--red"></div>
                    </div>
                    <div class="report-box__detail">
                        <div class="report-box__detail-header">Contents of Report:</div>
                        <ul>
                            <li>genotype and phenotype of all genes in panel</li>
                            <li>clinical consideration</li>
                            <li>categorized medications</li>
                            <li>GDI charts</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="single-gene-warning__disclaimer">* As recognized by CPIC and/or the FDA</div>
            <div class="single-gene-warning__hr"></div>
            <p class="gene-alert-text sub-section-label">
                If you have any questions, please contact your Genesight representative, or call Genesight Customer Support at 866.757.9204.
            </p>
        </div>
    </div>
</template>

<script>
import ExclaimIcon from "../../../../assets/circle-exclamation.svg";

export default {
    name: "GeneWarning",
    components: {
        ExclaimIcon
    },
    props: {
        reportLink: {
            type: String,
            required: true
        },
        singleGeneName: {
            type: String,
            required: false,
            default: ""
        },
        showZeroGeneMessage: {
            type: Boolean,
            required: true
        },
        isZeroGeneTest: {
            type: Boolean,
            required: false,
            default: false
        },
        hasConsideredNonGeneticFactors: {
            type: Boolean,
            required: false,
            default: true
        },
        isMultiGeneTest: {
            type: Boolean,
            required: false,
            default: false
        },
        isAbnWarning: {
            type: Boolean,
            required: false,
            default: false
        },
        medicationList: {
            type: Array,
            required: false,
            default: () => {}
        },
        singleGeneSubtext: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            lcdLink: "https://www.cms.gov/medicare-coverage-database/view/lcd.aspx?lcdid=38394",
            showProceedWithABN: false,
            showSingleGeneWarningDetails: false,
            showSingleGeneWarningComparison: false
        };
    },
    computed: {
        headerText() {
            if (this.showZeroGeneMessage) {
                return "Based on your selections, Medicare / Medicare Advantage will not cover this test";
            } else {
                return `Based on your selections, you will receive a ${this.singleGeneName} Single Gene Pharmacogenetic Report`;
            }
        },
        abnLinkArrowClass() {
            let className = "";
            if (this.showProceedWithABN) {
                className += " reverse-arrow";
            }
            return className;
        }
    },
    methods: {
        scrollToMedicareRequiredHeader() {
            document.getElementById("treatment-plan-header").scrollIntoView({ behavior: "smooth", block: "start" });
        },
        handleAddMedicationsClick() {
            this.scrollToMedicareRequiredHeader();
            this.$store.dispatch("setAugmentationHighlight", true);
            this.$store.dispatch("setDoseageHighlight", true);
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../../../../styles/AuthorizeOrders.scss";

    $gene-side-padding: 10px;
    $gene-tb-padding: 5px;

    .gene-alert {
        margin-bottom: 35px;
        border: 2px solid $genesight-orange;
        border-radius: 5px;
    }

    .gene-alert-header {
        background: $genesight-orange;
        padding: $gene-tb-padding $gene-side-padding;
        color: white;
    }

    .single-gene-alert-header {
        padding: 20px;
        background: #FFF0E7;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        border-bottom: 2px solid #FF6B0B;
        font-size: 1.125em;
        line-height: 1.5em;
    }

    .single-gene-alert-header .authorization-sg-header-icon {
        min-width: 18px;
        max-width: 18px;
        min-height: 18px;
        max-height: 18px;
        margin-right: 3px;
        transform: translateY(2px);
    }

    .add-medication-button {
        background-color: white;
        color: #00a8e9;
        text-align: center;
        text-decoration: none !important;
        border-color: #00a8e9;
        border-style: solid;
        border-width: 1px;
        border-radius: 5px;
        cursor: pointer;
        text-transform: uppercase;
        padding: 12px 20px;
        display: block;
        margin: 10px auto 20px;
    }

    .gene-alert-text {
        padding: $gene-side-padding;
    }

    .gene-horizontal-rule {
        height: 1px;
        margin: $gene-side-padding;
        background: #93949F;
        border: none;
    }

    .gene-report-img-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .gene-alert-list {
        padding-left: 25px;
        list-style: disc;
    }

    .gene-alert-list-item {
        color: $genesight-blue;

        .gene-alert-text {
            color: black;
        }
    }

    .abn-link {
        display: block;
        padding-left: 36px;

        &:active {
            outline: none;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            .reverse-arrow {
                text-decoration: overline;
            }
        }
    }

    .gene-alert-or-section {
        height: 30px;
        position: relative;
        padding: 0 $gene-side-padding;

        .gene-horizontal-rule {
            position: absolute;
            top: 14px;
            width: calc(100% - #{$gene-side-padding * 2});
            margin: 0;
        }
    }

    .gene-alert-or {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        left: calc(50% - 15px);
        border: 1px solid #93949F;
        border-radius: 30px;
        background: white;
    }

    .reverse-arrow {
        display: inline-block;
        transform: rotate(180deg) translate(0, 1px);
        transform-origin: center center;
    }

    .mobile-specific-message {
        margin-left: 25px;
    }

    .single-gene-warning__toggle {
        color: #00a8e9;
        cursor: pointer;
        line-height: 20px;
        font-size: .875em;
        padding: 10px;
    }

    .single-gene-warning__toggle:after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        background: transparent;
        border-top: 2px solid #00a8e9;
        border-left: 2px solid #00a8e9;
        text-decoration: none;
        transform: rotate(-135deg) translate(-5px, 8px);
    }

    .single-gene-warning__toggle.single-gene-warning__toggle--hide:after {
        transform: rotate(45deg) translate(8px, -5px);
    }

    .single-gene-warning__list {
        list-style-type: disc;
        line-height: 20px;
        font-size: .875em;
        margin-left: 27px;
    }

    .lcd-link {
        color: #00a8e9;
        cursor: pointer;
        line-height: 20px;
        font-size: .875em;
        padding-left: 10px;
    }

    .report-box {
        border: 2px solid #dedede;
        padding: 15px;
        margin-top: 40px;
        font-size: .8em;
        width: calc(100% - 20px);
        margin-left: 10px;
        margin-bottom: 20px;
    }

    .report-box__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .report-box__header img {
        height: 25px;
    }

    .report-box__header-copy {
        font-size: 13px;
        font-weight: 400;
    }

    .report-box__signs {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 13px 0 18px;
    }

    .report-box__alert {
        display: flex;
        width: 28%;
        height: 15px;
        background-color: #b1b1b1;
        border-radius: 6px;
    }

    .report-box__alert--blue {
        background-color: #005C7F;
        width: 100%;
    }

    .report-box__alert--green {
        background-color: #5eb73c;
    }

    .report-box__alert--yellow {
        background-color: #ffd24c;
    }

    .report-box__alert--red {
        background-color: #c52317;
    }

    .report-box__detail-header {
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .report-box__detail ul {
        list-style-type: disc;
        line-height: 28px;
        font-size: 13px;
        margin-left: 16px;
    }

    .single-gene-warning__disclaimer {
        line-height: 20px;
        font-size: .875em;
        padding: 10px;
        padding-top: 30px;
        display: flex;
        justify-content: flex-end;
    }

    .single-gene-warning__hr {
        height: 1px;
        width: calc(100% - 20px);
        margin: 8px 10px 15px;
        background: #d3d3d3;
    }
</style>
