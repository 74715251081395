<template>
    <div class="forgot-password-container">
        <div class="forgot-password">
            <p v-if="validationErrors" class="log-on-validation-message">
                Please include valid email address
            </p>
            <h3 class="forgot-pw-header">
                Forgot Password
            </h3>

            <p class="forgot-pw-content">
                Enter your email address below to have a link to reset your password e-mailed to you.
            </p>
            <labeled-input
                v-model="$v.fgEmail.$model"
                :class="validationErrors ? 'email-error' : ''"
                class-string="forgot-pw-input"
                placeholder="Email Address"
            />
            <neuro-button
                class="sign-on-submit"
                text="Reset Password"
                :filled="true"
                :show-loader="showLoader"
                @neuro-btn-click="submitForgotPassword"
            />
        </div>
    </div>
</template>

<script>
import LabeledInput from "../LabeledInput.vue";
import NeuroButton from "../NeuroButton.vue";

import { required, maxLength, email } from "vuelidate/lib/validators";

export default {
    name: "ForgotPasswordComponent",
    components: {
        LabeledInput,
        NeuroButton
    },
    data() {
        return {
            fgEmail: "",
            validationErrors: false,
            showLoader: false
        };
    },
    validations: {
        fgEmail: {
            required,
            email,
            maxLength: maxLength(100)
        }
    },
    methods: {
        submitForgotPassword() {
            if (this.$v.$invalid) {
                this.validationErrors = true;
            } else {
                this.showLoader = true;
                this.validationErrors = false;
                this.$emit("forgot-password-submit", this.fgEmail);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.forgot-password-container {
    width: 100%;
    padding-top: 25px;
}

.forgot-password {
    margin: 0 auto 20px;
    width: $sign-on-box-width
}

.forgot-pw-header {
    text-align: left;
}

.forgot-pw-content {
    margin-bottom: 30px;
    font-size: $regular-copy-size;
    line-height: 1.66667em;
}

::v-deep .email-error .forgot-pw-input {
    border-color: $genesight-red;
}
</style>
