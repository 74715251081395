<template>
    <div class="mthfr-2020-container">
        <h2 class="section-header">
            MTHFR
        </h2>
        <div v-show="$store.getters.getMtfhrSelected">
            <div class="mobile-link mobile-link-center" @click="removeMthfrFromOrder">
                remove from order
            </div>
            <h4 class="sub-section-header">
                Diagnosis
            </h4>
            <label class="sub-section-label">
                List ICD-10 code(s) for this patient’s <b>psychiatric</b> diagnosis
                <span class="sub-section-gray-text">
                    (maximum of 4)
                </span>
            </label>
            <error-wrapper
                :error-exists="!$store.getters.atLeastOneMthfrDiagCodeSupplied"
                error-message="Please provide a response."
            >
                <div class="mthfr-diagnosis-multiselect-container">
                    <div class="mthfr-diagnosis-multiselect" />
                </div>
            </error-wrapper>
            <div v-show="!$store.getters.atLeastOneMthfrDiagCodeSupplied" class="mobile-link copy-psych-mobile-link" @click="copyPsychDiagCodes()">
                copy codes from {{ testToCopyCodesFrom }}
            </div>
        </div>
        <div v-show="!$store.getters.getMtfhrSelected">
            <button class="add-mthfr-btn" type="button" @click="addMthfrToOrder">
                <img class="other-test-plus" src="../../../assets/white_circle_plus.png">
                Add MTHFR
            </button>
        </div>
    </div>
</template>

<script>
import { NeuroMultiselect } from "@myriad-neuroscience/myriad-neuro-multiselect/neuro-multiselect.js";
import ErrorWrapper from "../ErrorWrapper.vue";
import AuthorizationHttp from "../../../scripts/http/authorization-http.js";

export default {
    name: "Mthfr2020",
    components: {
        ErrorWrapper
    },
    data() {
        return {
            mthfrMultiselect: null
        };
    },
    computed: {
        defaultDiagCodes() {
            return this.$store.getters.getDefaultDiagnosticCodesAll;
        },
        diagCode1: {
            get() {
                return this.$store.getters.getMthfrDiagCode;
            },
            set(value) {
                this.$store.dispatch("setMthfrDiagCode", value);
            }
        },
        secondaryDiagCode1: {
            get() {
                return this.$store.getters.getMthfrSecondaryDiagCode1;
            },
            set(value) {
                this.$store.dispatch("setMthfrSecondaryDiagCode1", value);
            }
        },
        secondaryDiagCode2: {
            get() {
                return this.$store.getters.getMthfrSecondaryDiagCode2;
            },
            set(value) {
                this.$store.dispatch("setMthfrSecondaryDiagCode2", value);
            }
        },
        secondaryDiagCode3: {
            get() {
                return this.$store.getters.getMthfrSecondaryDiagCode3;
            },
            set(value) {
                this.$store.dispatch("setMthfrSecondaryDiagCode3", value);
            }
        },
        testToCopyCodesFrom: {
            get () {
                if (this.$store.getters.getIsSingleGeneTest) {
                    return "Single Gene Test";
                } else {
                    return "Psychotropic";
                }
            }
        }
    },
    watch: {
        defaultDiagCodes(newValues) {
            this.createMultiselect(newValues);
        }
    },
    mounted() {
        this.createMultiselect();
    },
    methods: {
        createMultiselect() {
            const domElement = document.querySelector(".mthfr-diagnosis-multiselect");
            domElement.innerHTML = "";

            const preselectedValues = this.getPreselectedDiagCodes();
            const that = this;

            this.diagnosisMultiselect = new NeuroMultiselect();
            this.diagnosisMultiselect.init(
                domElement,
                [],
                preselectedValues,
                {
                    allowCustomInputs: false,
                    footerString: "If you do not see an appropriate code in this list, type to search all codes",
                    maxAllowed: 4,
                    placeholderString: "Type to see matching ICD-10 codes."
                }
            );
            this.diagnosisMultiselect.setupApiCallback(function(searchTerm, returnCallback) {
                AuthorizationHttp.SearchDiagnosticCodeAutocomplete(
                    that.$store.getters.getPsychProductId,
                    that.$store.getters.getCurrentOrderIsMedicare,
                    searchTerm
                ).then((response) => {
                    returnCallback(response);
                }).catch(() => {
                    that.$store.dispatch("showDefaultNotificationRibbon");
                });
            });

            this.diagnosisMultiselect.setupCallbackReturn(function(data) {
                for (let i = 0; i < 4; i++) {
                    that.setDiagCode(data[i], i);
                }
            });
        },
        getPreselectedDiagCodes() {
            const preselectedDiagCodes = [];

            if (this.diagCode1.diagnosticCodeValue) {
                this.diagCode1.value = this.diagCode1.diagnosticCodeValue;
                preselectedDiagCodes.push(this.diagCode1);
            }

            if (this.secondaryDiagCode1.diagnosticCodeValue) {
                this.secondaryDiagCode1.value = this.secondaryDiagCode1.diagnosticCodeValue;
                preselectedDiagCodes.push(this.secondaryDiagCode1);
            }

            if (this.secondaryDiagCode2.diagnosticCodeValue) {
                this.secondaryDiagCode2.value = this.secondaryDiagCode2.diagnosticCodeValue;
                preselectedDiagCodes.push(this.secondaryDiagCode2);
            }

            if (this.secondaryDiagCode3.diagnosticCodeValue) {
                this.secondaryDiagCode3.value = this.secondaryDiagCode3.diagnosticCodeValue;
                preselectedDiagCodes.push(this.secondaryDiagCode3);
            }

            return preselectedDiagCodes;
        },
        setDiagCode(diagCode, diagCodeIndex) {
            if (diagCodeIndex === 0) {
                this.diagCode1 = diagCode || {};
            } else if (diagCodeIndex === 1) {
                this.secondaryDiagCode1 = diagCode || {};
            } else if (diagCodeIndex === 2) {
                this.secondaryDiagCode2 = diagCode || {};
            } else if (diagCodeIndex === 3) {
                this.secondaryDiagCode3 = diagCode || {};
            }
        },
        copyPsychDiagCodes() {
            this.diagCode1 = this.$store.getters.getDiagCode;
            this.secondaryDiagCode1 = this.$store.getters.getSecondaryDiagCode1;
            this.secondaryDiagCode2 = this.$store.getters.getSecondaryDiagCode2;
            this.secondaryDiagCode3 = this.$store.getters.getSecondaryDiagCode3;

            this.createMultiselect();
        },
        addMthfrToOrder() {
            this.$store.dispatch("setMtfhrSelected", true);
        },
        removeMthfrFromOrder() {
            this.$store.dispatch("setMtfhrSelected", false);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/AuthorizeOrders.scss";

.mthfr-2020-container {
    @include auth-section-border();
    border-radius: 5px;
    padding: 15px 7px;
}

.add-mthfr-btn {
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: 10px 50px 10px 12px;
    border-radius: 5px;
    color: white;
    background: $genesight-blue;

    &:hover {
        cursor: pointer;
    }
}

.mobile-link {
    font-size: $sub-section-label-size;
}

.mobile-link-center {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.copy-psych-mobile-link {
    margin-top: 10px;
}
</style>
