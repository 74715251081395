import { render, staticRenderFns } from "./Auth2020Questions.vue?vue&type=template&id=e82c3f18&scoped=true&"
import script from "./Auth2020Questions.vue?vue&type=script&lang=js&"
export * from "./Auth2020Questions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.registry.npmjs.org/vue-loader/15.9.7_css-loader@3.6.0+webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e82c3f18",
  null
  
)

export default component.exports