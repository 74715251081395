<template>
    <div class="signature">
        <div class="signature-container">
            <h2 class="signature-header green-header">
                Create Your Electronic Signature
            </h2>
            <p class="signature-large-copy">
                All orders for Medicare/Medicare Advantage patients now need to be authorized by the ordering clinician. We are asking all clinicians to create an electronic signature to enable order authorizations, even if you don't treat Medicare patients at this time.
            </p>
            <p class="signature-large-copy">
                By clicking 'Accept and Create E-Signature', I attest that my electronic signature below authorizes the placement of GeneSight test orders.
            </p>
            <p class="signature-name">
                Full Name: {{ $store.getters.getClinicianName }}
            </p>
            <p class="signature-small-copy">
                The above name matches your NPI or PECOS registry. If this is not correct, please email
                <!-- eslint-disable-next-line vue/singleline-html-element-content-newline-->
                <a class="mobile-link" :href="'mailto:' + $store.getters.getCsEmail">{{ $store.getters.getCsEmail }}</a>.
            </p>
            <p class="signature-small-copy signature-preview-copy">
                Preview
            </p>
            <div ref="signatureBox" class="signature-box">
                <p
                    ref="signatureText"
                    class="signature-text"
                    :style="'font-family:' + selectedFont.fontName +'; font-size:' + fontSize"
                >
                    {{ $store.getters.getClinicianName }}
                </p>
            </div>
            <div>
                <neuro-button class="signature-btn" text="Change Style" @neuro-btn-click="styleOptionsOpen = true" />
            </div>
            <div>
                <neuro-button class="signature-btn" text="Accept and Create E-Signature" :filled="true" @neuro-btn-click="submitSignature" />
            </div>
            <div :class="overlayClassName">
                <div :class="styleOptionsShown ? 'signature-style-options signature-style-options--open' : 'signature-style-options'">
                    <ul>
                        <li v-for="(item, index) in $store.getters.getFonts"
                            :key="index"
                            class="signature-option"
                            :style="'font-family:' + item.fontName"
                            @click="selectStyleOption(item)"
                        >
                            {{ $store.getters.getClinicianName }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NeuroButton from "../components/NeuroButton.vue";
import SignatureHttp from "../scripts/http/signature-http.js";
import ContactHttp from "../scripts/http/contact-http.js";
import Common from "../scripts/common.js";
import Axios from "axios";

export default {
    name: "Signature",
    components: {
        NeuroButton
    },
    data() {
        return {
            styleOptionsOpen: false,
            styleOptionsShown: false,
            fontSize: "30px",
            selectedFont: ""
        };
    },
    computed: {
        overlayClassName: function() {
            var className = "signature-options-overlay";

            if (this.styleOptionsOpen) {
                className += " signature-options-overlay--pre-visible";
            }

            if (this.styleOptionsShown) {
                className += " signature-options-overlay--open";
            }

            return className;
        },
        styleOptionsClassName: function() {
            let className = "signature-style-options";

            if (this.styleOptionsShown) {
                className += " signature-style-options--open";
            }

            return className;
        }
    },
    watch: {
        styleOptionsOpen: function(newVal, oldVal) {
            if (newVal !== oldVal) {
                setTimeout(() => {
                    this.styleOptionsShown = !this.styleOptionsShown;
                }, 250);
            }
        }
    },
    created() {
        this.$store.dispatch("showFullScreenLoader");

        Axios.all([
            ContactHttp.getContactInfo(),
            SignatureHttp.getFonts()
        ]).then(
            Axios.spread((contactResponse, fontsResponse) => {
                this.$store.dispatch("setContactInfo", contactResponse);
                this.$store.dispatch("setFonts", fontsResponse.data);
                this.getSelectedSignatureFont();
            })
        ).catch(() => {
            this.$store.dispatch("showDefaultNotificationRibbon");
        }).finally(() => {
            this.$store.dispatch("hideFullScreenLoader");
        });
    },
    methods: {
        getSelectedSignatureFont() {
            if (!Common.isEmptyOrNull(this.$store.getters.getSelectedSignatureFont.signature)) {
                this.selectedFont = this.$store.getters.getSelectedSignatureFont.signature;
            } else {
                this.selectedFont = this.$store.getters.getFirstFont;
            }

            this.$nextTick(() => {
                this.sizeSignatureToFit();
            });
        },
        selectStyleOption(font) {
            this.selectedFont = font;
            this.styleOptionsOpen = false;
            this.$store.dispatch("setClincianSignatureFont", font);

            this.fontSize = "30px";
            this.sizeSignatureToFit();
        },
        sizeSignatureToFit() {
            this.$nextTick(() => {
                const signature = this.$refs.signatureText;
                const signatureBox = this.$refs.signatureBox;

                if (signature.scrollWidth > signatureBox.offsetWidth) {
                    signature.style.opacity = 0;

                    let currentFontSize = window.getComputedStyle(signature, null).getPropertyValue("font-size");
                    currentFontSize = parseInt(currentFontSize);

                    let newFontSize = currentFontSize - 2;
                    newFontSize = newFontSize + "px";

                    this.fontSize = newFontSize;

                    window.setTimeout(function () {
                        this.sizeSignatureToFit();
                    }.bind(this), 50);
                } else {
                    signature.style.opacity = 1;
                }
            });
        },
        submitSignature() {
            if (!this.$store.getters.getSelectedSignatureFont.fontName) {
                this.$store.dispatch("setClincianSignatureFont", this.selectedFont);
            }

            const signatureData = {
                SelectedFontId: this.$store.getters.getSelectedSignatureFont.signatureFontId,
                SelectedFontName: this.$store.getters.getSelectedSignatureFont.fontName,
                ClinicianName: this.$store.getters.getClinicianName,
                Npi: this.$store.getters.getClinicianNpi
            };

            this.$store.dispatch("showFullScreenLoader");
            SignatureHttp.updateSignature(signatureData).then(() => {
                this.$router.push("/");
            }).catch(() => {
                this.$store.dispatch("showDefaultNotificationRibbon");
                this.$store.dispatch("hideFullScreenLoader");
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .signature {
        width: 100%;
    }

    .signature-container {
        margin: 35px auto;
        width: 90%;
    }

    .signature-header {
        margin-bottom: 15px;
    }

    .signature-large-copy {
        margin-bottom: 30px;
        font-size: $signature-large-font-size;
        line-height: 1.125em;
        color: $text-color;
    }

    .signature-name {
        margin-bottom: 5px;
        font-size: $signature-large-font-size;
    }

    .signature-small-copy {
        margin-bottom: 12px;
        font-size: 0.75em;
        line-height: 1.2em;
        color: $text-color;
    }

    .signature-preview-copy {
        margin-bottom: 5px;
    }

    .signature-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 70px;
        margin-bottom: 25px;
        border: 1px solid $border-dark-gray;
        overflow: hidden;
    }

    .signature-text {
        width: 100%;
        height: auto;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
    }

    .signature-btn {
        width: 100%;
        margin-bottom: 15px;
    }

    .signature-options-overlay {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        transition: 0.2s all ease-in-out;
    }

    .signature-options-overlay--pre-visible {
        display: block;
        opacity: 0;
    }

    .signature-options-overlay--open {
        opacity: 1;
    }

    .signature-style-options {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        max-height: 0;
        opacity: 0;
        background: white;
        transition: 0.2s all ease-in-out;
        z-index: 10;
    }

    .signature-style-options--open {
        max-height: 300px;
        opacity: 1;
    }

    .signature-option {
        height: 50px;
        padding-left: 20px;
        font-size: 1.4em;
        line-height: 50px;
        border-top: 1px solid #797979;
        border-bottom: 1px solid #797979;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
            cursor: pointer;
        }
    }
</style>
