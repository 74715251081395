<template>
    <div class="contact-page">
        <div class="logo-container">
            <GenesightLogo class="logo" />
        </div>
        <h3 class="contact-title">
            Contact Us
        </h3>
        <div class="contact-section">
            <h5 class="contact-section-title">
                Customer Service
            </h5>
            <p class="contact-content">
                {{ $store.getters.getCsHours }}
            </p>
            <p class="contact-content">
                <a :href="'tel:' + $store.getters.getCsPhone" class="mobile-link">
                    {{ $store.getters.getCsPhone }}
                </a> (phone)
            </p>
            <p class="contact-content">
                {{ $store.getters.getCsFax }} (fax)
            </p>
            <a :href="'mailto:' + $store.getters.getCsEmail" class="mobile-link contact-content--nmb">
                {{ $store.getters.getCsEmail }}
            </a>
        </div>
        <div class="contact-section">
            <h5 class="contact-section-title">
                Medical Information
            </h5>
            <p class="contact-content">
                {{ $store.getters.getMiHours }}
            </p>
            <p class="contact-content">
                <a :href="'tel:' + $store.getters.getMiPhone" class="mobile-link">
                    {{ $store.getters.getMiPhone }}
                </a> (phone)
            </p>
            <a :href="'mailto:' + $store.getters.getMiEmail" class="mobile-link contact-content--nmb">
                {{ $store.getters.getMiEmail }}
            </a>
        </div>
    </div>
</template>

<script>
import GenesightLogo from "../assets/genesight.svg";
import ContactHttp from "../scripts/http/contact-http.js";
import Common from "../scripts/common.js";

export default {
    name: "Contact",
    components: {
        GenesightLogo
    },
    created() {
        if (Common.isEmptyOrNull(this.$store.getters.getCsPhone)) {
            ContactHttp.getContactInfo().then((data) => {
                this.$store.dispatch("setContactInfo", data);
            }).catch(() => {
                this.$store.dispatch("showDefaultNotificationRibbon");
            });
        }
    },
    mounted() {
        this.$store.dispatch("setShowContact", false);
        this.$store.dispatch("setShowHome", true);
    }
};
</script>

<style lang="scss" scoped>
.contact-page {
    width: 100%;
    padding: 30px 10px 0 10px
}

.logo-container {
    @include flex-center-vertical();
    margin-bottom: 20px;
    width: 100%;
}

.logo {
    width: $sign-on-box-width;
}

.contact-section {
    margin-top: 30px;
}

.contact-title {
    font-size: $header-font-size;
}

.contact-section-title {
    margin-bottom: 10px;
    color: $genesight-green;
    font-size: 1em;
}

.contact-content {
    margin-bottom: 10px;
}

.contact-content--nmb {
    margin-bottom: 0;
}
</style>
