<template>
    <div class="no-orders">
        <h1 class="no-orders-header">
            No orders to authorize
        </h1>
        <p class="no-orders-content">
            Orders pending your authorization will appear here.
        </p>
        <p class="no-orders-content">
            In addition, the number of orders pending authorization is shown next to the "Authorize Orders" button and navigation links within this site when there is at least one order to authorize.
        </p>
        <neuro-button
            class="order-submit-btn"
            text="Home"
            :filled="true"
            @neuro-btn-click="$router.push('/')"
        />
    </div>
</template>

<script>
import NeuroButton from "../NeuroButton.vue";

export default {
    name: "NoOrders",
    components: {
        NeuroButton
    }
};
</script>

<style lang="scss" scoped>
.no-orders {
    padding: 0 15px;
}

.no-orders-header {
    font-size: $large-header-font-size;
}

.no-orders-content {
    margin-top: 35px;
    font-size: $regular-copy-size;
    line-height: 1.5em;
}

.order-submit-btn {
    width: 115px;
    margin: 35px auto;
}
</style>
