<template>
    <div class="new-password-page">
        <div class="logo-container">
            <genesight-svg class="genesight-logo" />
        </div>
        <div v-if="invalidPw" class="error-message">
            The password does not meet the required criteria.
        </div>
        <div v-if="mismatchPw" class="error-message">
            The passwords do not match.
        </div>
        <div v-if="invalidToken" class="invalid-section">
            <h4 class="reset-pw-header">
                Invalid Link
            </h4>
            <p class="invalid-instructions">
                The reset password link has expired or is invalid. Please tap the link below to request a new link.
            </p>
            <router-link class="mobile-link" to="/">
                Forgot password?
            </router-link>
        </div>
        <div v-else-if="resetSuccess" class="success-section">
            <div class="password-set-header">
                <img class="password-header-img" src="../assets/circle_check.png">
                <p>
                    Password set
                </p>
            </div>
            <p class="reset-pw-instructions">
                Your account has been updated with your new password.
            </p>
            <neuro-buton
                class="success-sign-in"
                text="Sign In"
                :show-loader="false"
                :filled="true"
                color="blue"
                @neuro-btn-click="goToSignIn"
            />
        </div>
        <div v-else>
            <div class="reset-form-section">
                <h4 class="reset-pw-header">
                    Reset Password
                </h4>
                <p class="reset-pw-instructions">
                    Please enter a new password for your account below:
                </p>
                <labeled-input
                    v-model="$v.newPassword.$model"
                    :class="newPwClass"
                    input-type="password"
                    placeholder="New Password"
                />
                <div class="criteria-section">
                    <h6 class="criteria-header">
                        Criteria
                    </h6>
                    <ul class="criteria-list">
                        <li class="criteria-row">
                            At least 8 characters in length and contains 3 of the following:
                        </li>
                        <li class="criteria-row">
                            Upper case letter
                        </li>
                        <li class="criteria-row">
                            Lower case letter
                        </li>
                        <li class="criteria-row">
                            Number
                        </li>
                        <li class="criteria-row">
                            Special character
                        </li>
                    </ul>
                </div>
                <labeled-input
                    v-model="$v.confirmPassword.$model"
                    :class="confirmPwClass"
                    input-type="password"
                    placeholder="Confirm New Password"
                />
                <neuro-buton
                    class="reset-pw-btn"
                    text="Reset Password"
                    :show-loader="showLoader"
                    :filled="true"
                    color="blue"
                    @neuro-btn-click="submitNewPassword"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ResetPwHttp from "../scripts/http/reset-pw-http.js";
import GenesightSvg from "../assets/genesight.svg";

import LabeledInput from "../components/LabeledInput.vue";
import NeuroButon from "../components/NeuroButton.vue";

export default {
    name: "NewPassword",
    components: {
        GenesightSvg,
        LabeledInput,
        NeuroButon
    },
    data() {
        return {
            invalidToken: false,
            resetSuccess: false,
            showLoader: false,
            newPassword: "",
            confirmPassword: "",
            token: "",
            minLength: 0,
            userId: "",
            invalidPw: false,
            mismatchPw: false
        };
    },
    validations: {
        newPassword: {
            required
        },
        confirmPassword: {
            required
        }
    },
    computed: {
        confirmPwClass() {
            return this.invalidPw || this.mismatchPw ? "password-input-error confirm-pw-input" : "confirm-pw-input";
        },
        newPwClass() {
            return this.invalidPw || this.mismatchPw ? "password-input-error" : "";
        }
    },
    created() {
        this.$store.dispatch("showFullScreenLoader");
        this.token = this.$route.params.token;
        ResetPwHttp.validateToken(this.token).then((response) => {
            this.minLength = response.data.minLength;
            this.userId = response.data.userId;
        }).catch(() => {
            this.invalidToken = true;
        }).finally(() => {
            this.$store.dispatch("hideFullScreenLoader");
        });
    },
    methods: {
        submitNewPassword() {
            if (this.isValidPassword()) {
                this.showLoader = true;

                const dto = {
                    Token: this.token,
                    Password: this.newPassword
                };

                ResetPwHttp.updatePassword(dto).then(() => {
                    this.resetSuccess = true;
                }).catch(() => {
                    this.$store.dispatch("showDefaultNotificationRibbon");
                }).finally(() => {
                    this.showLoader = false;
                });
            }
        },
        goToSignIn() {
            this.$router.push("/");
        },
        isValidPassword() {
            if (this.newPassword !== this.confirmPassword) {
                this.mismatchPw = true;
                window.scrollTo(0, 0);
                return false;
            } else {
                this.mismatchPw = false;
            }

            if (this.newPassword.length >= this.minLength && this.meetsPwValidationCriteria()) {
                this.invalidPw = false;
                return true;
            } else {
                this.invalidPw = true;
                window.scrollTo(0, 0);
                return false;
            }
        },
        isMatchingPasswords() {
            return this.newPassword !== this.confirmPassword;
        },
        meetsPwValidationCriteria() {
            let points = 0;

            if (this.hasLowerCaseLetter()) {
                points = points + 1;
            }
            if (this.hasUpperCaseLetter()) {
                points = points + 1;
            }
            if (this.hasNumber()) {
                points = points + 1;
            }
            if (this.hasSpecialCharacter()) {
                points = points + 1;
            }

            return points >= 3;
        },
        hasLowerCaseLetter() {
            return (/[a-z]/.test(this.newPassword));
        },
        hasUpperCaseLetter() {
            return (/[A-Z]/.test(this.newPassword));
        },
        hasNumber() {
            return (/[0-9]/.test(this.newPassword));
        },
        hasSpecialCharacter() {
            return (/[!@#$%^&*]/.test(this.newPassword));
        }
    }
};
</script>

<style lang="scss" scoped>
    .error-message {
        width: 100%;
        margin-bottom: -30px;
        padding: 26px 26px 0 26px;
        color: $genesight-red;
        font-size: 1em;
    }

    .invalid-section {
        padding: 28px;

        .reset-pw-header {
            margin-bottom: 30px;
        }

        .invalid-instructions {
            font-size: $small-font-size;
            line-height: 20px;
        }

        .mobile-link {
            display: block;
            margin-top: 40px;
            font-size: $small-font-size;
        }
    }

    .success-section {
        padding: 26px;
    }

    .password-set-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $genesight-green;
    }

    .password-header-img {
        margin-right: 10px;
    }

    .success-sign-in {
        width: 100px;
        margin: 0 auto;
    }

    .logo-container {
        @include flex-center-horizontal();
        width: 100%;
        margin-top: 30px;
    }

    .genesight-logo {
        width: 70%;
        margin: 0 auto;
    }

    .reset-form-section {
        padding: 26px;
    }

    .reset-pw-header {
        margin-top: 30px;
        font-size: $header-font-size;
    }

    .reset-pw-instructions {
        margin-top: 29px;
        margin-bottom: 29px;
        font-size: 1em;
    }

    .criteria-section {
        margin-bottom: 30px;
    }

    .criteria-header {
        margin-bottom: 3px;
        font-weight: bold;
        font-size: $small-font-size;
    }

    .criteria-list {
        list-style-type: disc;
    }

    .criteria-row {
        list-style-position: inside;
        font-size: $small-font-size;
        line-height: 18px;
    }

    .reset-pw-btn {
        width: 100%;
    }

    .confirm-pw-input {
        margin-bottom: 30px;
    }

    ::v-deep .password-input-error .labeled-input {
        border-color: $genesight-red;
    }
</style>
