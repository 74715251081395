import Common from "../../scripts/common.js";

export default {
    state: {
        errors: [],
        diagnosisErrors: [],
        patientInformationErrors: [],
        medicalNecessityErrors: [],
        mthfrErrors: [],

        symptomsErrorString: "symptomsError",
        diagCodeErrorString: "diagnosticCodeError",
        diagCodeSeverityErrorString: "diagnosticCodeSeverityError",

        failedMedsErrorString: "failedMedsError",
        currentMedsErrorString: "currentMedsError",
        alterationQuestionErrorString: "alterationQuestionError",
        monsensoErrorString: "monsensoError",
        nameDobMismatchErrorString: "nameDobError",
        failedMedsBlackListErrorString: "failedMedsBlacklistError",

        hasFailedMedsMedicareErrorString: "medicareHasFailedMedsError",
        symptomSeverityMedicareErrorString: "medicareSeverityError",
        medAlterationMedicareErrorString: "medicareAltertationError",
        contemplatingChangeErrorString: "contemplatingChangeErrorString",

        mthfrMedNecessityErrorString: "mthfrMedNecessityErrorString",
        mthfrOtherErrorString: "mthfrOtherErrorString",

        patientInformationApproved: false,
        diagnosisApproved: false,
        medicalNecessityApproved: false,
        singleGeneAcceptance: false
    },
    getters: {
        getErrors: state => {
            return state.errors;
        },
        getDiagnosisErrors: state => {
            return state.diagnosisErrors;
        },
        getPatientInformationErrors: state => {
            return state.patientInformationErrors;
        },
        getMedicalNecessityErrors: state => {
            return state.medicalNecessityErrors;
        },
        getAnyErrorsExist: state => {
            const errors = state.errors.length > 0;
            const diagErrors = state.diagnosisErrors.length > 0;
            const patientInfoErrors = state.patientInformationErrors.length > 0;
            const medNecessityErrors = state.medicalNecessityErrors.length > 0;
            const mthfrErrors = state.mthfrErrors.length > 0;

            return errors || diagErrors || patientInfoErrors || medNecessityErrors || mthfrErrors;
        },
        getIsAllSectionsClinicianAuthorized: state => {
            return state.patientInformationApproved && state.diagnosisApproved && state.medicalNecessityApproved;
        },
        getSymptomsErrorString: state => {
            return state.symptomsErrorString;
        },
        getDiagnosticCodeErrorString: state => {
            return state.diagCodeErrorString;
        },
        getDiagCodeSeverityErrorString: state => {
            return state.diagCodeSeverityErrorString;
        },
        getFailedMedsBlackListErrorString: state => {
            return state.failedMedsBlackListErrorString;
        },

        getFailedMedsErrorString: state => {
            return state.failedMedsErrorString;
        },
        getCurrentMedsErrorString: state => {
            return state.currentMedsErrorString;
        },
        getAlterationQuestionErrorString: state => {
            return state.alterationQuestionErrorString;
        },
        getContemplatingChangeErrorString: state => {
            return state.contemplatingChangeErrorString;
        },
        getMonsensoErrorString: state => {
            return state.monsensoErrorString;
        },
        getNameDobErrorSting: state => {
            return state.nameDobMismatchErrorString;
        },
        getHasFailedMedsMedicareErrorString: state => {
            return state.hasFailedMedsMedicareErrorString;
        },
        getMedicareSymptonSeverityError: state => {
            return state.symptomSeverityMedicareErrorString;
        },
        getMedAlterationMedicareErrorString: state => {
            return state.medAlterationMedicareErrorString;
        },
        getPatientInformationApproved: state => {
            return state.patientInformationApproved;
        },
        getDiagnosisApproved: state => {
            return state.diagnosisApproved;
        },
        getMedicalNecessityApproved: state => {
            return state.medicalNecessityApproved;
        },
        getMMthfrMedNecessityErrorString: state => {
            return state.mthfrMedNecessityErrorString;
        },
        getmthfrOtherErrorString: state => {
            return state.mthfrOtherErrorString;
        },
        getSingleGeneAcceptance: state => {
            return state.singleGeneAcceptance;
        }
    },
    mutations: {
        addError(state, errorString) {
            if (!Common.arrayContainsObject(errorString, state.errors)) {
                state.errors.push(errorString);
            }
        },
        removeError(state, errorString) {
            if (Common.arrayContainsObject(errorString, state.errors)) {
                state.errors.splice(state.errors.indexOf(errorString), 1);
            }
        },
        addDiagnosisError(state, errorString) {
            if (!Common.arrayContainsObject(errorString, state.diagnosisErrors)) {
                state.diagnosisErrors.push(errorString);
            }
        },
        removeDiagnosisError(state, errorString) {
            if (Common.arrayContainsObject(errorString, state.diagnosisErrors)) {
                state.diagnosisErrors.splice(state.diagnosisErrors.indexOf(errorString), 1);
            }
        },
        addPatientInformationError(state, errorString) {
            if (!Common.arrayContainsObject(errorString, state.patientInformationErrors)) {
                state.patientInformationErrors.push(errorString);
            }
        },
        addMthfrError(state, errorString) {
            if (!Common.arrayContainsObject(errorString, state.mthfrErrors)) {
                state.mthfrErrors.push(errorString);
            }
        },
        removePatientInformationError(state, errorString) {
            if (Common.arrayContainsObject(errorString, state.patientInformationErrors)) {
                state.patientInformationErrors.splice(state.patientInformationErrors.indexOf(errorString), 1);
            }
        },
        addMedicalNecessityError(state, errorString) {
            if (!Common.arrayContainsObject(errorString, state.medicalNecessityErrors)) {
                state.medicalNecessityErrors.push(errorString);
            }
        },
        removeMedicalNecessityError(state, errorString) {
            if (Common.arrayContainsObject(errorString, state.medicalNecessityErrors)) {
                state.medicalNecessityErrors.splice(state.medicalNecessityErrors.indexOf(errorString), 1);
            }
        },
        removeMthfrError(state, errorString) {
            if (Common.arrayContainsObject(errorString, state.mthfrErrors)) {
                state.mthfrErrors.splice(state.mthfrErrors.indexOf(errorString), 1);
            }
        },
        setPatientInformationApproved(state, boolValue) {
            state.patientInformationApproved = boolValue;
        },
        setDiagnosisApproved(state, boolValue) {
            state.diagnosisApproved = boolValue;
        },
        setMedicalNecessityApproved(state, boolValue) {
            state.medicalNecessityApproved = boolValue;
        },
        setSingleGeneAcceptance(state, boolValue) {
            state.singleGeneAcceptance = boolValue;
        },
        resetClinicianApprovedSections(state) {
            state.patientInformationApproved = false;
            state.diagnosisApproved = false;
            state.medicalNecessityApproved = false;
            state.singleGeneAcceptance = false;

            state.errors = [];
            state.diagnosisErrors = [];
            state.patientInformationErrors = [];
            state.medicalNecessityErrors = [];
            state.mthfrErrors = [];
        }
    },
    actions: {
        addError(context, errorString) {
            context.commit("addError", errorString);
        },
        removeError(context, errorString) {
            context.commit("removeError", errorString);
        },
        addDiagnosisError(context, errorString) {
            context.commit("addDiagnosisError", errorString);
        },
        removeDiagnosisError(context, errorString) {
            context.commit("removeDiagnosisError", errorString);
        },
        addPatientInformationError(context, errorString) {
            context.commit("addPatientInformationError", errorString);
        },
        removePatientInformationError(context, errorString) {
            context.commit("removePatientInformationError", errorString);
        },
        addMedicalNecessityError(context, errorString) {
            context.commit("addMedicalNecessityError", errorString);
        },
        removeMedicalNecessityError(context, errorString) {
            context.commit("removeMedicalNecessityError", errorString);
        },
        addMthfrError(context, errorString) {
            context.commit("addMthfrError", errorString);
        },
        removeMthfrError(context, errorString) {
            context.commit("removeMthfrError", errorString);
        },
        setPatientInformationApproved(context, boolValue) {
            context.commit("setPatientInformationApproved", boolValue);
        },
        setDiagnosisApproved(context, boolValue) {
            context.commit("setDiagnosisApproved", boolValue);
        },
        setMedicalNecessityApproved(context, boolValue) {
            context.commit("setMedicalNecessityApproved", boolValue);
        },
        setSingleGeneAcceptance(context, boolValue) {
            context.commit("setSingleGeneAcceptance", boolValue);
        },
        resetClinicianApprovedSections(context) {
            context.commit("resetClinicianApprovedSections");
        }
    }
};
