<template>
    <div @keydown.enter.prevent="listVisible = false">
        <input
            ref="autocompleteInput"
            :value="value.diagnosticCodeValue"
            class="autocomplete-input"
            type="text"
            :placeholder="optional ? '(optional)' : ''"
            @focus="listVisible = true"
            @input="inputEvent($event.target.value)"
            @blur.prevent="blurEvent"
            @keypress.enter="selectionEvent($event.target.value)"
        >
        <transition name="grow">
            <ul v-if="listVisible && codeValues.length > 0"
                class="autocomplete-input-list"
            >
                <li v-for="(item, diagIndex) in codeValues"
                    :key="diagIndex"
                    class="autocomplete-item"
                    @click="selectionEvent(item.diagnosticCodeValue)"
                >
                    {{ item.diagnosticCodeValue }} - {{ item.description }}
                </li>
            </ul>
            <div v-else-if="listVisible" class="autocomplete-input-list">
                <div class="autocomplete-item">
                    Type to see matching ICD-10 codes.
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import AuthorizationHttp from "../../../scripts/http/authorization-http.js";
import Enums from "../../../scripts/enums.js";
import Common from "../../../scripts/common.js";

export default {
    name: "Autocomplete",
    props: {
        value: {
            type: Object,
            required: false,
            default: () => ({})
        },
        optional: {
            type: Boolean,
            required: false,
            default: false
        },
        mthfr: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            listVisible: false
        };
    },
    asyncComputed: {
        codeValues: {
            get() {
                return new Promise(resolve => {
                    if (Common.isEmptyOrNull(this.value.diagnosticCodeValue)) {
                        if (this.$store.getters.getDsm5SufferFromDepression && !this.mthfr && this.$store.getters.getHasAnxiety && !this.$store.getters.getCurrentOrderIsMedicare) {
                            const defaultValues = this.filterBySeverityAndAnxiety(this.$store.getters.getDefaultDiagnosticCodes);
                            resolve(defaultValues);
                        } else if (this.$store.getters.getDsm5SufferFromDepression && !this.mthfr) {
                            const defaultValues = this.filterBySeverity(this.$store.getters.getDefaultDiagnosticCodes);
                            resolve(defaultValues);
                        } else {
                            resolve([]);
                        }
                    } else {
                        AuthorizationHttp.SearchDiagnosticCodeAutocomplete(
                            this.$store.getters.getPsychProductId,
                            this.$store.getters.getCurrentOrderIsMedicare,
                            this.value.diagnosticCodeValue
                        ).then((response) => {
                            resolve(response.data);
                        }).catch(() => {
                            this.$store.dispatch("showDefaultNotificationRibbon");
                        });
                    }
                });
            },
            default() {
                return this.filterBySeverity(this.$store.getters.getDefaultDiagnosticCodesDepression);
            }
        }
    },
    methods: {
        filterBySeverity(codeValues) {
            return codeValues.filter((codeValue) => {
                let severityString = "";

                if (codeValue.autoComplete === true) {
                    switch (this.$store.getters.getCurrentDepressionSeverity) {
                    case Enums.DepressionSeverity.MILD:
                        severityString = "Mild";
                        break;
                    case Enums.DepressionSeverity.MODERATE:
                        severityString = "Moderate";
                        break;
                    case Enums.DepressionSeverity.SEVERE:
                        severityString = "Severe";
                        break;
                    case Enums.DepressionSeverity.ALL:
                        severityString = "All";
                        break;
                    default:
                        severityString = "";
                    }
                }

                return codeValue.depressionSeverity === severityString;
            });
        },
        filterBySeverityAndAnxiety(codeValues) {
            const severityMatchCodes = this.filterBySeverity(codeValues);
            const anxietyCodes = codeValues.filter((codeValue) => {
                return codeValue.isAnxiety && codeValue.autoComplete;
            });

            const allCodes = severityMatchCodes.concat(anxietyCodes);
            return allCodes;
        },
        inputEvent(targetValue) {
            const matchingCodeValue = this.codeValues.find((codeValue) => {
                return codeValue.diagnosticCodeValue.toLowerCase() === targetValue.toLowerCase();
            });

            if (!Common.isEmptyOrNull(matchingCodeValue)) {
                this.$emit("input", matchingCodeValue);
                this.$emit("autocomplete-used");
            } else {
                this.$emit("input", { diagnosticCodeValue: targetValue });
            }
        },
        selectionEvent(targetValue) {
            this.inputEvent(targetValue);
            this.listVisible = false;
        },
        blurEvent() {
            this.listVisible = false;

            // Wait to prevent flash on validation
            window.setTimeout(() => {
                this.$emit("autocomplete-used");
            }, 100);
        }
    }
};
</script>

<style lang="scss" scoped>
    $input-width: 85px;

    .autocomplete-input {
        width: $input-width;
        height: 25px;
        font-size: 1em;
    }

    .autocomplete-input::placeholder {
        font-size: 0.8em;
    }

    .autocomplete-input-list {
        position: absolute;
        width: 90%;
        height: auto;
        padding: 5px;
        background: white;
        border: 1px solid gray;
        z-index: 100;
    }

    .autocomplete-item {
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: $small-font-size;
    }

    .autocomplete-item:hover {
        background: $genesight-light-blue;
        cursor: pointer;
    }
</style>
