<template>
    <div>
        <button :class="buttonClass" @click="buttonClick">
            Sign and Authorize Order
        </button>
    </div>
</template>

<script>
export default {
    name: "SignAuthorizeBtn",
    props: {
        disabled: {
            default: false,
            type: Boolean,
            required: false
        }
    },
    computed: {
        buttonClass() {
            return this.disabled ? "sign-auth-button sign-auth-button--disabled" : "sign-auth-button";
        }
    },
    methods: {
        buttonClick() {
            if (!this.disabled) {
                this.$emit("sign-auth-click");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .sign-auth-button {
        @include flex-center-vertical();
        width: 100%;
        height: 60px;
        font-size: $green-header-font-size;
        text-transform: uppercase;
        outline: none;
        border: none;
        background: #7BC142;
        border-radius: 10px;
        color: white;

        &:hover {
            cursor: pointer;
        }
    }

    .sign-auth-button--disabled {
        @include error-btn-coloring();
        pointer-events: none;
    }
</style>
