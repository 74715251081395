<template>
    <div class="notification-ribbon">
        <div class="notification-ribbon-top-row">
            <div class="notification-ribbon-exclaim">
                !
            </div>
            <h6 v-if="$store.getters.getNotificationMessage !== ''" class="notification-ribbon-header">
                {{ $store.getters.getNotificationMessage }}
            </h6>
            <h6 v-else class="notification-ribbon-header">
                Oops, an error has occurred.
            </h6>
        </div>
        <div class="notification-ribbon-bottom-row">
            <p class="notification-ribbon-text">
                Please try again or <router-link class="notification-ribbon-nav-link" to="/Contact">
                    contact Customer Service
                </router-link>
            </p>
        </div>
        <div class="notification-ribbon-close" @click="$store.dispatch('setHideNotificationRibbon')">
            &#10005;
        </div>
    </div>
</template>

<script>
export default {
    name: "NotificationRibbon"
};
</script>

<style lang="scss" scoped>
    .notification-ribbon {
        position: fixed;
        top: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: $genesight-blue;
        color: white;
        padding: 9px;
        overflow: hidden;
        z-index: 200;
    }

    .notification-ribbon-top-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 3px;
    }

    .notification-ribbon-exclaim {
        @include flex-center-vertical();
        width: 19px;
        height: 19px;
        margin-right: 5px;
        font-size: 13px;
        font-weight: bold;
        border-radius: 50%;
        color: $genesight-blue;
        background: white;
    }

    .notification-ribbon-header {
        font-size: $header-font-size;
        font-weight: bold;
        color: white;
    }

    .notification-ribbon-text {
        font-size: $small-font-size;
        color: white;
    }

    .notification-ribbon-nav-link {
        color: white;
        text-decoration: underline;
    }

    .notification-ribbon-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 7px;
        color: white;
        font-weight: bolder;
        font-size: $header-font-size;

        &:hover {
            cursor: pointer;
        }
    }
</style>
