import { HTTP } from "./http";

const NewOrderHttp = {
    GetListOfClinicsAndClinicians: () => {
        const url = config.url + "/GetClinicsAndClinicians";
        return HTTP.get(url);
    },
    SubmitNewOrder: (data) => {
        const url = config.url + "/NewOrder";
        return HTTP.post(url, data);
    }
};

export default NewOrderHttp;
